import React from 'react';
import './App.css';
// import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { Route, Routes } from "react-router-dom";
import CompleteProfile from './components/customerComponents/authModule/completeProfile';
import Login from './components/customerComponents/authModule/login';
import Signup from './components/customerComponents/authModule/signup';
import OtpVerification from './components/customerComponents/authModule/otpVerification';
// import Verification from './components/customerComponents/authModule/verification';
import Air from './pages/Air/Air';
import Contact from './pages/Contact/Contact';
import Empty from './pages/Empty/Empty';
import Home from './pages/Home/Home';
import Invoice from './pages/Invoice/Invoice';
import Others from './pages/Others/Others';
import Quotes from './pages/Quotes/Quotes';
import RequestQuotes from './pages/RequestQuotes/RequestQuotes';
import RequestQuotesOthers from './pages/RequestQuotesOthers/RequestQuotesOthers';
import RequestQuotesSea from './pages/RequestQuotesSea/RequestQuotesSea';
import Sea from './pages/Sea/Sea';
import Shipment from './pages/Shipment/Shipment';
import TrackParcel from './pages/TrackParcel/TrackParcel';
import MyAccount from './pages/MyAccount/MyAccount';
import Shipment_tab from './pages/Shipment_tab/Shipment_tab';
import Alert from './pages/Alert/Alert';
import QuoteDetails from './pages/QuoteDetails/QuoteDetails';
import RequestQuotes2 from './pages/RequestQuotes2/RequestQuotes2';
import RequestQuotes3 from './pages/RequestQuotes3/RequestQuotes3';
import RequestQuotes4 from './pages/RequestQuotes4/RequestQuotes4';
import RequestQuotesOthers2 from './pages/RequestQuotesOthers2/RequestQuotesOthers2';
import RequestQuotesSea2 from './pages/RequestQuotesSea2/RequestQuotesSea2';
import EditQuotes from './pages/EditQuotes/EditQuotes';
import EditQuotes2 from './pages/EditQuotes2/EditQuotes2';
import SelectAccount from './components/customerComponents/authModule/selectAccount';
import Network from './components/customerComponents/authModule/network';

function App() {
  const getFlow = () => {
    if (!localStorage.getItem("MAisLogin", false)) {
      return (
        <>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/select-account" element={<SelectAccount />}/>
            <Route path='/network' element={<Network  />} />
            <Route path="/alert" element={<Alert />} />
            <Route path="/signup" element={<Signup />} />
            {/* <Route path="/verification" element={<Verification />} /> */}
            <Route path="/complete-profile" element= {<CompleteProfile />} />
            <Route path= "/verifyotp" element={<OtpVerification />} />
            <Route path="*" element={<Empty />} />
          </Routes>
        </>
      )
    }
    else{
      return (
        <>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/alert" element={<Alert />} />
            <Route path="/air" element={<Air />} />
            <Route path="/sea" element={<Sea />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/invoice" element={<Invoice />} />
            <Route path="/track-parcel/:id" element={<TrackParcel />} />
            <Route path="/shipment" element={<Shipment />} />
            <Route path="/shipment-tab/:id" element={<Shipment_tab />} />
            <Route path="/others" element={<Others />} />
            <Route path="/quotes" element={<Quotes />} />
            <Route path="/requestquotes" element={<RequestQuotes />} />
            <Route path="/requestquotes2" element={<RequestQuotes2 />} />
            <Route path="/requestquotes3" element={<RequestQuotes3 />} />
            <Route path="/requestquotes4" element={<RequestQuotes4 />} />
            <Route path="/editquotes/:id" element={<EditQuotes />} />
            <Route path="/editquotes2/:id" element={<EditQuotes2 />} />
            <Route path="/requestquotessea" element={<RequestQuotesSea />} />
            <Route path="/requestquotessea2" element={<RequestQuotesSea2 />} />
            <Route path="/requestquotesothers" element={<RequestQuotesOthers />} />
            <Route path="/requestquotesothers2" element={<RequestQuotesOthers2 />} />
            <Route path="/quotesdetails/:id" element={<QuoteDetails />} />
            <Route path="/account" element={<MyAccount />} />
            <Route path="*" element={<Empty />} />
          </Routes>
        </>
      )
    }
  }

  return (
    <>
      {getFlow()}
    </>
  );
}


export default App;