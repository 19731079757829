import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { logout } from '../../../api/apiHandler';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logOutRedirectCall } from '../../../pages/Common/RedirectPathMange';
import { useTranslation } from "react-i18next";

export default function Sidebar(commonProps) {
    const { t, i18n } = useTranslation();

    function Logout(){
        logout({}).then((response)=>{
            response = JSON.parse(response);
            if(response.code == 1)
            {
                logOutRedirectCall();
            }
            else
            {   
                toast.error(response.message, {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true
                });
            }
        })
    }
    return (
        <>
            <ToastContainer />
            <nav id="sidebar-wrapper">
                <div className="sidebar-nav main_side_navbar">
                    <div className="sidebar_links">
                        <div className="nav_logo d-flex justify-content-between ">
                            <div className="nav_logo_img">
                                <img src={process.env.PUBLIC_URL +'/assets/images/icon/logo.svg'} alt="logo" />
                            </div>
                            <div className="nav_cross_image">
                                <img src={process.env.PUBLIC_URL +"/assets/images/icon/cancel.svg"} alt="cancel" id="menu-toggle" />
                            </div>
                        </div>
                        <div className="digi_clock">
                            <canvas id="canvas" width="18" height="18" className="wall_clock">
                            </canvas>
                            <img src={process.env.PUBLIC_URL +'/assets/images/icon/clock.png'}/>
                            <div id="clock"></div>
                            <div className="bg_blue"></div>
                            <div className="bg_red"></div>
                        </div>
                        <ul className="nav flex-column">
                            <li className={`nav-item ${commonProps.commonProps.title == "home" ? "active" : ""}`}>
                                <Link className="nav-link" to="/">
                                    <div className="links_name  d-flex align-items-center">
                                        {commonProps.commonProps.title == "home" ?
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/dashboard-white.svg"} className="dash_icon_size"
                                                alt="dashboard" />
                                        :
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/dashboard.svg"} className="dash_icon_size"
                                                alt="dashboard" />
                                        } <span>{t('dashboard')}</span>
                                    </div>
                                </Link>
                            </li>
                            <li className={`nav-item ${commonProps.commonProps.title == "quotes" ? "active" : ""}`}>
                                <Link className="nav-link" to="/quotes">
                                    <div className="links_name d-flex align-items-center">
                                        {commonProps.commonProps.title == "quotes" ?
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/quotes-white.svg"} className="dash_icon_size"
                                                alt="quotes" />
                                        :
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/quotes.svg"} className="dash_icon_size"
                                                alt="quotes" />
                                        } <span>{t('quotes')}</span>
                                    </div>
                                </Link>
                            </li>
                            <li className={`nav-item ${commonProps.commonProps.title == "shipment" ? "active" : ""}`}>
                                <Link className="nav-link" to="/shipment">
                                    <div className="links_name d-flex align-items-center">
                                        {commonProps.commonProps.title == "shipment" ?
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/shipment-white.svg"} className="dash_icon_size"
                                                alt="quotes" />
                                        :
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/shipment.svg"} className="dash_icon_size"
                                                alt="quotes" />
                                        } <span>{t('shipment')}</span>
                                    </div>
                                </Link>
                            </li>
                            <li className={`nav-item ${commonProps.commonProps.title == "invoice" ? "active" : ""}`}>
                                <Link className="nav-link" to="/invoice">
                                    <div className="links_name d-flex align-items-center">
                                        {commonProps.commonProps.title == "invoice" ?
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/invoice-white.svg"} className="dash_icon_size"
                                                alt="invoice" />
                                        :
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/invoice.svg"} className="dash_icon_size"
                                                alt="invoice" />
                                        } <span>{t('invoice')}</span>
                                    </div>
                                </Link>
                            </li>
                            <li className={`nav-item ${commonProps.commonProps.title == "contact" ? "active" : ""}`}>
                                <Link className="nav-link" to="/contact">
                                    <div className="links_name d-flex align-items-center">
                                        {commonProps.commonProps.title == "contact" ?
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/contact-white.svg"} className="dash_icon_size"
                                                alt="contact" />
                                        :
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/contact.svg"} className="dash_icon_size"
                                                alt="contact" />
                                        }
                                        <span>{t('contact')}</span>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className="sidebar_content">
                        <ul className="nav flex-column">
                            <li className={`nav-item ${commonProps.commonProps.title == "account" ? "active" : ""}`}>
                                <Link className="nav-link" to="/account">
                                    <div className="links_name d-flex align-items-center">
                                        {commonProps.commonProps.title == "account" ?
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/account-white.svg"} className="dash_icon_size" alt="user" />
                                        :
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icon/account.svg"} className="dash_icon_size" alt="user" />
                                        }
                                        <span>{t('account')}</span>
                                    </div>
                                </Link>
                            </li>
                            <li className="nav-item">   
                                <Link to="/" type='button' className="nav-link" onClick={Logout}>
                                    <div className="links_name d-flex align-items-center">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/logout.svg"} className="dash_icon_size"
                                            alt="logout" />
                                        <span>{t('log_out')}</span>
                                    </div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>

            <Helmet>
                <script>
                    {
                        `
                        function showTime() {
                            let time = new Date();
                            let hour = time.getHours();
                            let min = time.getMinutes();
                            let sec = time.getSeconds();
                            // let am_pm = "AM";
                        
                            // if (hour > 12) {
                            //     hour -= 12;
                            //     am_pm = "PM";
                            // }
                            // if (hour == 0) {
                            //     hr = 12;
                            //     am_pm = "AM";
                            // }
                            // console.log(am_pm)
                        
                            hour = hour < 10 ? "0" + hour : hour;
                            min = min < 10 ? "0" + min : min;
                            sec = sec < 10 ? "0" + sec : sec;
                        
                            let currentTime = hour + ":" + min + ":" + sec;
                        
                            document.getElementById("clock").innerHTML = currentTime;
                        }
                        
                        showTime();
                        `
                    }
                </script>
            </Helmet>
        </>
    )
}
