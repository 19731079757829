import React from 'react'
import { Link } from 'react-router-dom'
import Sidebar from '../../components/customerComponents/Sidebar/Sidebar'
import Topbar from '../../components/customerComponents/Topbar/Topbar'

export default function Empty() {
    const commonProps = {title: "empty"}
    return (
        <>
            <main className="dash_b h-100">
                <div className="container-fluid h-100">
                    <div id="wrapper">
                        <Sidebar commonProps={commonProps}/>

                        <section id="content-wrapper" className="h-100">
                            <Topbar />

                            <div className='d-flex h-100 text-center'>
                                <section className="dashboard_area flex-column d-flex w-100">
                                    <div className="empty_area d-flex justify-content-between align-items-centers h-100">
                                        <div className="folder_image m-auto text-center">
                                            <img src={process.env.PUBLIC_URL +"/assets/images/icon/folder.svg"} alt="folder" />
                                            <p className="folder_grey">There are no record to display</p>
                                            <Link to="/" class="btn btn_quote_new btn_signin">Back</Link>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </section>

                    </div>
                </div>
            </main>
        </>
    )
}
