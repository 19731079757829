import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import Sidebar from '../../components/customerComponents/Sidebar/Sidebar';
import Topbar from '../../components/customerComponents/Topbar/Topbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { contactUs, showProfile } from '../../api/apiHandler';
import { Helmet } from 'react-helmet';
import { useTranslation } from "react-i18next";
import ToastMessage from '../../components/Toast/index';
import PhoneInput,{ isValidPhoneNumber }  from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const Contact = () => {
    const commonProps = {title: "contact"}
    const [num, setNum] = useState('');
    const [profileData, setProfileData] = useState(null);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        showProfile().then((response) => {
            response = JSON.parse(response);
            if(response.code == 1){
                setNum(`+` + `${response.data.country_code}` + `${response.data.phone}`)
                setProfileData(response.data);
            }
            else{
                console.log("null");
            }
        });
    }, [])

    const validationSchema = Yup.object().shape({
        first_name: Yup.string()
            .required('First name is required')
            .min(3, 'First name must be at least 3 characters')
            .matches('^[a-zA-Z]', 'Only alphabets are allowed for this field'),
        last_name : Yup.string()
            .required('Last name is required')
            .matches('^[a-zA-Z]', 'Only alphabets are allowed for this field')
            .min(3, 'Last name must be at least 3 characters'),
        email : Yup.string()
            .email('Email address is invalid')
            .required('Email address is required'),
        phone : Yup.number()
            .typeError('you must specify a number')
            .required('Phone number is required')
            .min(10, 'Phone number must be at least 10 characters'),
        subject: Yup.string()
            .required('Subject is required'),
        message: Yup.string()
            .required('Message is required')
            .min(15, 'Message must be at least 15 characters')
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const handleChange4 = event => {
        setNum(event);
    };

    const onSubmit = (data) => {
        var a = document.getElementById("contact_number_val").value;
        data.country_code = a.split(" ")[0];
        data.phone = a.replace(a.split(" ")[0], "").replace(/ /g, '');
        contactUs(data).then((response) => {
            response = JSON.parse(response)
            if(response.code == 1)
            {
                ToastMessage({type:"success", message: response.message});
            }
            else
            {   
                ToastMessage({type:"error", message: response.message});
            }
        });
        reset();
    };
        
    if(profileData == null)return <></>
    return (
        <React.Fragment>
            <Helmet>
                <title>{t('contact_us')}</title>
            </Helmet>
            <main className="dash_b">
                <div className="container-fluid">
                    <div id="wrapper">
                        <Sidebar commonProps={commonProps}/>

                        <section id="content-wrapper">
                            <Topbar />

                            <section className="dashboard_area">
                                <ToastContainer />
                                <div className="contact_get text-center">
                                    <h1>{t('get_in_touch')}</h1>
                                    <p className="we_grey">{t('contact_line')}</p>
                                </div>
                                <div className="contact_get_content">
                                    <div className="row">
                                        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                            <div className="contact_address">
                                                <div className="contact_location d-flex align-items-center">
                                                    <div className="location_image">
                                                        <img src={process.env.PUBLIC_URL +"/assets/images/icon/location.svg"} alt="location" />
                                                    </div>
                                                    <span>Paris CDG office</span>
                                                </div>
                                                <div className="contact_fulladdress">
                                                    <p className="mb-0">ISL CARGO</p>
                                                    <p className="mb-0">385 rue de la Belle Etoile,</p>
                                                    <p>95700 Roissy-en-France, FRANCE</p>
                                                </div>
                                                <div className="contact_map">
                                                    <iframe
                                                        title='contact_map'
                                                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2618.093032248477!2d2.515497515624021!3d48.9897869793006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e615f70edf43c1%3A0xabe6051a706e7456!2sISL%20Cargo!5e0!3m2!1sen!2sin!4v1661418040681!5m2!1sen!2sin"
                                                        width="100%" height="276" style={{ border:0 }}
                                                        loading="lazy"
                                                        className="map"></iframe>
                                                </div>
                                                <div className="row">
                                                    <div
                                                        className="col-6 col-xl-6 col-md-6 col-sm-6 justify-content-center align-items-center text-center">
                                                        <div className="contact_number">
                                                            <div className="phone_image">
                                                                {/* <img src="assets/images/icon/call.svg" alt="contact" /> */}
                                                                <iconify-icon icon="mdi:phone" style={{fontSize: "25px"}}></iconify-icon>
                                                            </div>
                                                            <span>+33 1 80 98 43 30</span>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="col-6 col-xl-6 col-md-6 col-sm-6 justify-content-center align-items-center text-center">
                                                        <div className="contact_number">
                                                            <div className="phone_image">
                                                                {/* <img src="assets/images/icon/mail.svg" alt="contact" /> */}
                                                                <iconify-icon icon="mdi:email" style={{fontSize: "25px"}}></iconify-icon>
                                                            </div>
                                                            <span>info@islcargo.com</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 mt-4 mt-md-auto">
                                            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                                                <div className="signin_between h-auto">
                                                    <div className="signin_content">
                                                        <div className="row">
                                                            <div className="col-xl-6 col-lg-6">
                                                                <div className="input_box_contact input_box_margin">
                                                                    <input type="text" className="form-control" {...register('first_name')}
                                                                        id="exampleInputEmail1" aria-describedby="emailHelp" autoComplete='off' defaultValue={profileData.first_name}
                                                                        required="required" />
                                                                    <label htmlFor="exampleInputEmail1" className="form-label">{t('first_name')}</label>
                                                                    <div className="invalid-feedback">{errors.first_name?.message}</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6 col-lg-6">
                                                                <div className="input_box_contact input_box_margin">
                                                                    <input type="text" className="form-control" {...register('last_name')}
                                                                        id="exampleInputEmail1" aria-describedby="emailHelp" autoComplete='off' defaultValue={profileData.last_name}
                                                                        required="required" />
                                                                    <label htmlFor="exampleInputEmail1" className="form-label">{t('last_name')}</label>
                                                                    <div className="invalid-feedback">{errors.last_name?.message}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-xl-6 col-lg-6">
                                                                <div className="input_box_contact input_box_margin">
                                                                    <input type="text" className="form-control" {...register('email')} id="exampleInputEmail1" autoComplete='off' defaultValue={profileData.email}
                                                                        aria-describedby="emailHelp" required="required" />
                                                                    <label htmlFor="exampleInputEmail1" className="form-label">{t('email_address')}</label>
                                                                    <div className="invalid-feedback">{errors.email?.message}</div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6 col-lg-6">
                                                                <div className="input_box_contact input_box_margin">
                                                                        <PhoneInput
                                                                            international
                                                                            id="contact_number_val"
                                                                            {...register('phone')}
                                                                            name="phone_number"
                                                                            value={num}
                                                                            onChange={handleChange4}
                                                                            error={num ? (isValidPhoneNumber(num) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                                                        />
                                                                    <label htmlFor="exampleInputEmail1" className="form-label">{t('phone')}</label>
                                                                    <div className="invalid-feedback">{errors.phone?.message}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col-12">
                                                                {/* <div className="dropdown input_box_margin contact_dropdown">
                                                                    <button className="btn btn-outline-secondary dropdown-toggle w-100 d-flex align-items-center justify-content-between" type="button"
                                                                        id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                                        aria-expanded="false"> <span>Select subject</span>
                                                                        <img src="assets/images/icon/down-arrow.svg" alt="down-arrow" />
                                                                    </button>
                                                                    <ul className="dropdown-menu w-100 list_contactinput_ul" aria-labelledby="dropdownMenuButton1">
                                                                        <li><span className="dropdown-item">Current shipment</span></li>
                                                                        <li><span className="dropdown-item">New shipment</span></li>
                                                                        <li><span className="dropdown-item">Our services</span></li>
                                                                        <li className="list_contactinput">
                                                                            <div className="input_box">
                                                                                <input type="text" name="other-subject" className="form-control" placeholder="Other subject type here" {...register('subject')} aria-describedby="emailHelp" />
                                                                                <label htmlFor="exampleInputEmail1" className="form-label"></label>
                                                                            </div>
                                                                        </li>
                                                                    </ul>
                                                                    <div className="invalid-feedback">{errors.subject?.message}</div>
                                                                </div> */}
                                                                <div className="dropdown input_box_margin contact_dropdown">
                                                                    <select  {...register('subject')} className="btn btn-outline-secondary dropdown-toggle w-100 d-flex align-items-center justify-content-between">
                                                                        <option className="dropdown-item" value="Current Shipment">{t('current_shipment')}</option>
                                                                        <option className="dropdown-item" value="New Shipment">{t('new_shipment')}</option>
                                                                        <option className="dropdown-item" value="Our Service">{t('our_service')}</option>
                                                                        <option className="dropdown-item" value="Our Service">{t('other')}</option>
                                                                    </select>
                                                                    <div className="invalid-feedback">{errors.subject?.message}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="input_box_contact form-floating-1">
                                                            <textarea className="form-control" id="message_contact" {...register('message')}
                                                                name="message"></textarea>
                                                            <label htmlFor="exampleInputEmail1" className="form-label">Message</label>
                                                            <div className="invalid-feedback">{errors.message?.message}</div>
                                                        </div>
                                                        <div className="signin_button">
                                                            <button type='submit' className="btn btn_signin w-100">{t('submit')}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </section>
                    </div>
                </div>
            </main>
        </React.Fragment>
    )
}
export default Contact;