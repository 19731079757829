import React, {useState, useEffect} from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { editQuotes, getQuoteDetail } from '../../api/apiHandler';
import Sidebar from '../../components/customerComponents/Sidebar/Sidebar';
import Topbar from '../../components/customerComponents/Topbar/Topbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Switch from '@material-ui/core/Switch';
import { useTranslation } from "react-i18next";

export default function EditQuotes2() {
    const commonProps = {title: "quotes"}
    const { t, i18n } = useTranslation();

    let { id } = useParams();
    const navigate = useNavigate();

    var [data, setData] = useState([]);
    // var [count, setCount] = useState();
    const [isInsurance, setIsInsurance] = useState(true);
    const [isDangerous, setIsDangerous] = useState(true);
    const [isTemperatureControlled, setIsTemperatureControlled] = useState(true);
    const [getIncoterm, setIncoterm] = useState("EXW");
    const [getType, setType] = useState("");
    const [formValues, setFormValues] = useState([{ pieces: "", weight : "", weight_type : "", type: "", goods_value: "", is_insurance: "", is_dangerous: "", is_temperature_controlled: "", volume : "", desc : "", remark : "" }]);
    const [seaFormValues, setSeaFormValues] = useState([{ container: "", qty: "", goods_value: "", is_insurance: "", is_dangerous: "", is_temperature_controlled: "", volume : "", desc : "", remark : "" }]);

    const toggleIsInsurance = () => {
        setIsInsurance(current => !current);
    };
    
    const toggleIsDangerous = () => {
        setIsDangerous(current => !current);
    };

    const toggleIsTemperatureControlled = () => {
        setIsTemperatureControlled(current => !current);
    };

    var keys = [];
    var values = [];
    var result = {};

    // Add Fields
    let addFormFields = () => {
        if(data.transport_mode == getType){
            if(getType == "Sea"){
                if(seaFormValues.length > 0){
                    // for GET Keys from last inserted array 
                    for (let key of Object.keys(seaFormValues.slice(-1)[0])) {
                        keys.push(key)
                    }
        
                    // for GET Values from last inserted array 
                    for (let value of Object.values(seaFormValues.slice(-1)[0])) {
                        values.push(value)
                    }

                    keys.forEach((key, i) => result[key] = values[i]);
                    if(result.container === "" || result.qty === "" ||result.desc === ""){
                        if(result.container === "" || result.container == "--"){
                            toast.error("Please Select value from Container", {
                                position: "top-right",
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                        else if(result.qty == "" || result.qty == "--"){
                            toast.error("Please Select Quntity for Container", {
                                position: "top-right",
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                        else if(result.desc === ""){
                            toast.error("Please add Good Decription about Goods", {
                                position: "top-right",
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                        else{
                            toast.error("Please Fill all Info", {
                                position: "top-right",
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                    }else{
                        setSeaFormValues([...seaFormValues, { container: "", qty: "", goods_value: "", is_insurance: "", is_dangerous: "", is_temperature_controlled: "",  desc : "", remark : "" }])
                    }
                }
            }
            else{
                if(formValues.length > 0){
                     // for GET Keys from last inserted array 
                    for (let key of Object.keys(formValues.slice(-1)[0])) {
                        keys.push(key)
                    }
        
                    // for GET Values from last inserted array 
                    for (let value of Object.values(formValues.slice(-1)[0])) {
                        values.push(value)
                    }
        
                    keys.forEach((key, i) => result[key] = values[i]);
        
                    if(result.pieces === "" || result.weight === "" || result.weight_type === "" || result.volume === "" || result.desc === "" || result.type === ""){
                        if(result.pieces === ""){
                            toast.error("Pieces Field is Required", {
                                position: "top-right",
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                        else if(result.weight === ""){
                            toast.error("Weight Field is Required", {
                                position: "top-right",
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                        else if(result.volume === ""){
                            toast.error("Volume Field is Required", {
                                position: "top-right",
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                        else if(result.desc === ""){
                            toast.error("Please add Good Decription about Goods", {
                                position: "top-right",
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                        else if(result.weight_type === "--" || result.weight_type === ""){
                            toast.error("Please Select Weight Type.", {
                                position: "top-right",
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                        else if(result.type === "--" || result.type === ""){
                            toast.error("Please Select Type for Cargo.", {
                                position: "top-right",
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                        else{
                            toast.error("Please Fill all Info", {
                                position: "top-right",
                                autoClose: 5000,
                                closeOnClick: true,
                                pauseOnHover: true,
                            });
                        }
                    }
                    else{
                        setFormValues([...formValues, { pieces: "", weight : "", weight_type : "", goods_value: "", is_insurance: "", is_dangerous: "", is_temperature_controlled: "", volume : "", desc : "", remark : "" }])
                    }
                }
            }
        }
        else{
            if(getType == "Sea"){
                // for GET Keys from last inserted array 
                for (let key of Object.keys(seaFormValues.slice(-1)[0])) {
                    keys.push(key)
                }
    
                // for GET Values from last inserted array 
                for (let value of Object.values(seaFormValues.slice(-1)[0])) {
                    values.push(value)
                }
    
                keys.forEach((key, i) => result[key] = values[i]);
                if(result.container === "" || result.qty === "" ||result.desc === ""){
                    if(result.container === "" || result.container == "--"){
                        toast.error("Please Select value from Container", {
                            position: "top-right",
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                    else if(result.qty == "" || result.qty == "--"){
                        toast.error("Please Select Quntity for Container", {
                            position: "top-right",
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                    else if(result.desc === ""){
                        toast.error("Please add Good Decription about Goods", {
                            position: "top-right",
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                    else{
                        toast.error("Please Fill all Info", {
                            position: "top-right",
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                }else{
                    setSeaFormValues([...seaFormValues, { container: "", qty: "", goods_value: "", is_insurance: "", is_dangerous: "", is_temperature_controlled: "",  desc : "", remark : "" }])
                }
            }
            else{
                // for GET Keys from last inserted array 
                for (let key of Object.keys(formValues.slice(-1)[0])) {
                    keys.push(key)
                }
    
                // for GET Values from last inserted array 
                for (let value of Object.values(formValues.slice(-1)[0])) {
                    values.push(value)
                }
    
                keys.forEach((key, i) => result[key] = values[i]);
    
                if(result.pieces === "" || result.weight === "" || result.weight_type === "" || result.volume === "" || result.desc === "" || result.type === ""){
                    if(result.pieces === ""){
                        toast.error("Pieces Field is Required", {
                            position: "top-right",
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                    else if(result.weight === ""){
                        toast.error("Weight Field is Required", {
                            position: "top-right",
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                    else if(result.volume === ""){
                        toast.error("Volume Field is Required", {
                            position: "top-right",
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                    else if(result.desc === ""){
                        toast.error("Please add Good Decription about Goods", {
                            position: "top-right",
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                    else if(result.weight_type === "--" || result.weight_type === ""){
                        toast.error("Please Select Weight Type.", {
                            position: "top-right",
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                    else if(result.type === "--" || result.type === ""){
                        toast.error("Please Select Type for Cargo.", {
                            position: "top-right",
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                    else{
                        toast.error("Please Fill all Info", {
                            position: "top-right",
                            autoClose: 5000,
                            closeOnClick: true,
                            pauseOnHover: true,
                        });
                    }
                }else{
                    setFormValues([...formValues, { pieces: "", weight : "", weight_type : "", goods_value: "", is_insurance: "", is_dangerous: "", is_temperature_controlled: "", volume : "", desc : "", remark : "" }])
                }
            }
        }
    }

    // Remove Fields
    let removeFormFields = (i) => {
        if(getType == "Sea"){
            let newFormValues = [...seaFormValues];
            newFormValues.splice(i, 1);
            setSeaFormValues(newFormValues)
        }
        else{
            let newFormValues = [...formValues];
            newFormValues.splice(i, 1);
            setFormValues(newFormValues)
        }
    }

    // HandelChange
    let handleChange = (i, e) => {
        if(getType == "Sea"){
            let newFormValues = [...seaFormValues];
            newFormValues[i][e.target.name] = e.target.value;
            setSeaFormValues(newFormValues);
        }
        else{
            let newFormValues = [...formValues];
            newFormValues[i][e.target.name] = e.target.value;
            setFormValues(newFormValues);
        }
    }

    // Get one Quote data from here 
    useEffect(() => {
        getQuoteDetail({"quote_id": id}).then((response)=>{
            response = JSON.parse(response);
            setType(response.data[0].transport_mode)
            if(response.code == 1){
                setData(response.data[0]);
                // setCount(response.data[0].cargo.length)
                if(response.data[0].transport_mode == "Sea"){
                    setSeaFormValues([...response.data[0].cargo])
                }
                else{
                    setFormValues([...response.data[0].cargo])
                }
            }else{
                console.log("null");
            }
        });
    }, []);

    const onSubmit = (e) => {
        e.preventDefault();

        if(document.getElementById("pickup_address").value === "" || document.getElementById("delivery_address").value === "" || document.getElementById("airport_departure").value === "" || document.getElementById("airport_arrival").value === ""){
            if(document.getElementById("pickup_address").value === ""){
                toast.error("Please add Pickup address", {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
            else if(document.getElementById("delivery_address").value === ""){
                toast.error("Please add Delivery address", {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
            else if(document.getElementById("airport_departure").value === ""){
                toast.error("Please give us proper Airport Departure", {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
            else if(document.getElementById("airport_arrival").value === ""){
                toast.error("Please give us proper Airport Arrival", {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
            else{
                console.log("Not done")
            }
        }
        else{
            if(data.transport_mode == getType){
                // var arr = [];

                // for (let i = 0; i < data.cargo.length; i++) {
                //     if(getType == "Sea"){
                //         var cargoparams= {
                //             container: document.getElementById("container"+`${i}`).value,
                //             qty: document.getElementById("qty"+`${i}`).value,
                //             desc: document.getElementById("desc"+`${i}`).value,
                //             goods_value: document.getElementById("goods_value"+`${i}`).value,
                //             is_insurance: document.getElementById("is_insurance"+`${i}`).value,
                //             is_dangerous: document.getElementById("is_dangerous"+`${i}`).value,
                //             is_temperature_controlled: document.getElementById("is_temperature_controlled"+`${i}`).value,
                //             remark: document.getElementById("remark"+`${i}`).value
                //         }
                //     }
                //     else{
                //         var cargoparams= {
                //             pieces: document.getElementById("pieces"+`${i}`).value,
                //             weight: document.getElementById("weight"+`${i}`).value,
                //             weight_type: document.getElementById("weight_type"+`${i}`).value,
                //             volume: document.getElementById("volume"+`${i}`).value,
                //             type: document.getElementById("type"+`${i}`).value,
                //             desc: document.getElementById("desc"+`${i}`).value,
                //             goods_value: document.getElementById("goods_value"+`${i}`).value,
                //             is_insurance: document.getElementById("is_insurance"+`${i}`).value,
                //             is_dangerous: document.getElementById("is_dangerous"+`${i}`).value,
                //             is_temperature_controlled: document.getElementById("is_temperature_controlled"+`${i}`).value,
                //             remark: document.getElementById("remark"+`${i}`).value
                //         }
                //     }
                //     arr.push(cargoparams);
                // }

                // var params = {
                //     quote_id: id,
                //     your_reference: document.getElementById("your_reference").value,
                //     transport_mode: getType,
                //     date: new Date().toJSON().slice(0, 10),
                //     incoterm: getIncoterm,
                //     pick_address: document.getElementById("pickup_address").value,
                //     delivery_address: document.getElementById("delivery_address").value,
                //     airport_of_departure: document.getElementById("airport_departure").value,
                //     airport_of_arrival: document.getElementById("airport_arrival").value,
                //     cargo: arr
                // }
                var params = {
                    quote_id: id,
                    your_reference: document.getElementById("your_reference").value,
                    transport_mode: getType,
                    date: new Date().toJSON().slice(0, 10),
                    incoterm: getIncoterm,
                    pick_address: document.getElementById("pickup_address").value,
                    delivery_address: document.getElementById("delivery_address").value,
                    airport_of_departure: document.getElementById("airport_departure").value,
                    airport_of_arrival: document.getElementById("airport_arrival").value,
                    cargo: (getType === "Sea") ? seaFormValues : formValues
                }
            }
            else{
                var params = {
                    quote_id: id,
                    your_reference: document.getElementById("your_reference").value,
                    transport_mode: getType,
                    date: new Date().toJSON().slice(0, 10),
                    incoterm: getIncoterm,
                    pick_address: document.getElementById("pickup_address").value,
                    delivery_address: document.getElementById("delivery_address").value,
                    airport_of_departure: document.getElementById("airport_departure").value,
                    airport_of_arrival: document.getElementById("airport_arrival").value,
                    cargo: (getType === "Sea") ? seaFormValues : formValues
                }
            }
            editQuotes(params).then((response) => {
                response = JSON.parse(response);
                if(response.code == 1){
                    toast.success(response.message, {
                        position: "top-right",
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                    setTimeout(() => {
                        navigate("/quotes");
                    }, 1000);
                }
                else{
                    toast.error(response.message, {
                        position: "top-right",
                        autoClose: 5000,
                        closeOnClick: true,
                        pauseOnHover: true,
                    });
                }
            });
        }
    }


    return (
        <>
            <Helmet>
                <title>{t('edit_quotes')}</title>
            </Helmet>
            <main className="main_isl">
                <div className="container-fluid">
                    <div id="wrapper">
                        <Sidebar commonProps={commonProps}/>

                        <section id="content-wrapper">
                            <ToastContainer />
                            <Topbar />
                            <section className="dashboard_area">
                                <form onSubmit={onSubmit} noValidate>
                                    <div className="main_quote_request">
                                        <div className="row">
                                            <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <h5>{t('basic_details')}:</h5>
                                            </div>
                                            <div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12">
                                                <div className="request_label">
                                                    <div className="input_box input_box_margin">
                                                        <input type="text" className="form-control" id="your_reference" name='your_reference' defaultValue={data.your_reference}
                                                            aria-describedby="emailHelp" required="required" />
                                                        <label htmlFor="your_reference" className="form-label">{t('your_reference')} ({t('optional')})</label>
                                                    </div>
                                                </div>

                                                <p className="font_16">{t('transport_mode')}</p>
                                                <div className="row">
                                                    <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                        <div className="quotes_drop_1">
                                                            {getType == "Air" ? 
                                                                <span className="dropdown-item btn btn_quotes_active"><img
                                                                src={process.env.PUBLIC_URL +"/assets/images/icon/air-white.svg"} alt="plane" /> {t('air')} </span>
                                                            :
                                                                <span className="dropdown-item btn btn_quotes" onClick={() => setType("Air")}><img
                                                                src={process.env.PUBLIC_URL +"/assets/images/icon/air-white.svg"} alt="plane" /> {t('air')} </span>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                        <div className="quotes_drop_1">
                                                        {getType == "Sea" ? 
                                                            <span className="dropdown-item btn btn_quotes btn_quotes_active"><img
                                                                    src={process.env.PUBLIC_URL +"/assets/images/icon/sea-blue.svg"} alt="ship" /> {t('sea')} </span>
                                                        :
                                                            <span className="dropdown-item btn btn_quotes" onClick={() => setType("Sea")}><img
                                                                src={process.env.PUBLIC_URL +"/assets/images/icon/sea-blue.svg"} alt="ship" /> {t('sea')} </span>
                                                        }
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <div className="quotes_drop_1">
                                                        {getType == "Other" ? 
                                                            <span className="dropdown-item btn btn_quotes btn_quotes_active"><img
                                                                src={process.env.PUBLIC_URL +"/assets/images/icon/truck-blue.svg"} alt="truck" /> {t('road')} </span>
                                                        :
                                                            <span className="dropdown-item btn btn_quotes" onClick={() => setType("Other")}><img
                                                                    src={process.env.PUBLIC_URL +"/assets/images/icon/truck-blue.svg"} alt="truck" /> {t('road')} </span>
                                                        }
                                                        </div>
                                                    </div> */}
                                                </div>

                                                <p className="font_16">Incoterm <a href="#" data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"><i className="fa-solid fa-circle-info incoterm_icon"></i></a> </p>
                                                <div id='main_incoterm' className="main_incoterm">
                                                    {data.incoterm === "EXW" ?
                                                    <>
                                                        <a className="btn btn_incoterm btn_quotes_incoterm_active 1" onClick={() => setIncoterm("EXW")}>EXW</a>
                                                        <a className="btn btn_incoterm 2" onClick={() => setIncoterm("FOB")}>FOB</a>
                                                        <a className="btn btn_incoterm 3" onClick={() => setIncoterm("CFR")}>CFR</a>
                                                        <a className="btn btn_incoterm 4" onClick={() => setIncoterm("DDP")}>DDP</a>
                                                    </>
                                                    : data.incoterm === "FOB" ?
                                                    <>
                                                        <a className="btn btn_incoterm 1" onClick={() => setIncoterm("EXW")}>EXW</a>
                                                        <a className="btn btn_incoterm btn_quotes_incoterm_active 2" onClick={() => setIncoterm("FOB")}>FOB</a>
                                                        <a className="btn btn_incoterm 3" onClick={() => setIncoterm("CFR")}>CFR</a>
                                                        <a className="btn btn_incoterm 4" onClick={() => setIncoterm("DDP")}>DDP</a>
                                                    </>
                                                    : data.incoterm === "CFR" ?
                                                    <>
                                                        <a className="btn btn_incoterm 1" onClick={() => setIncoterm("EXW")}>EXW</a>
                                                        <a className="btn btn_incoterm 2" onClick={() => setIncoterm("FOB")}>FOB</a>
                                                        <a className="btn btn_incoterm btn_quotes_incoterm_active 3" onClick={() => setIncoterm("CFR")}>CFR</a>
                                                        <a className="btn btn_incoterm 4" onClick={() => setIncoterm("DDP")}>DDP</a>
                                                    </>
                                                    :
                                                    <>
                                                        <a className="btn btn_incoterm 1" onClick={() => setIncoterm("EXW")}>EXW</a>
                                                        <a className="btn btn_incoterm 2" onClick={() => setIncoterm("FOB")}>FOB</a>
                                                        <a className="btn btn_incoterm 3" onClick={() => setIncoterm("CFR")}>CFR</a>
                                                        <a className="btn btn_incoterm btn_quotes_incoterm_active 4" onClick={() => setIncoterm("DDP")}>DDP</a>
                                                    </>
                                                    }
                                                    <div className="dropdown w-100">
                                                        <button className="btn btn_incoterm dropdown-toggle" type="button"
                                                            id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                            aria-expanded="false">
                                                            Others
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li><a className="dropdown-item" href="/#">Action</a></li>
                                                            <li><a className="dropdown-item" href="/#">Another action</a></li>
                                                            <li><a className="dropdown-item" href="/#">Something else here</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="request_hr"></div>
                                        <div className="row">
                                            <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <h5>{t('itinerary')}:</h5>
                                            </div>
                                            <div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12">
                                                <div className="row">
                                                    <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                        <div className="request_label">
                                                            <div className="input_box input_box_margin">
                                                                <input type="text" className="form-control" id="pickup_address" name='pick_address' defaultValue={data.pick_address}
                                                                    aria-describedby="emailHelp" required="required" />
                                                                <label htmlFor="pickup_address" className="form-label">{t('pick_up_address')}</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                        <div className="request_label">
                                                            <div className="input_box input_box_margin">
                                                                <input type="text" className="form-control" id="delivery_address" name='delivery_address' defaultValue={data.delivery_address}
                                                                    aria-describedby="emailHelp" required="required" />
                                                                <label htmlFor="delivery_address" className="form-label">{t('delivery_address')}</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="formValuescol-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                        <div className="request_label">
                                                            <div className="input_box input_box_margin">
                                                                <input type="text" className="form-control" id="airport_departure" name='airport_departure' defaultValue={data.airport_of_departure}
                                                                    aria-describedby="emailHelp" required="required" />
                                                                <label htmlFor="airport_departure" className="form-label">{t('airport_of_departure')}</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                        <div className="request_label">
                                                            <div className="input_box input_box_margin">
                                                                <input type="text" className="form-control" id="airport_arrival" name='airport_arrival' defaultValue={data.airport_of_arrival}
                                                                    aria-describedby="emailHelp" required="required" />
                                                                <label htmlFor="airport_arrival" className="form-label">{t('airport_of_arrival')}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="request_hr"></div>
                                        <div className="cargo_margin">
                                            <div className='row'>
                                                <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                    <h5>Cargo:</h5>
                                                </div>
                                                <div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12">
                                                    <ul className="nav nav-tabs quotes_tabs ship_all_details_tab" id="myTab"
                                                        role="tablist">
                                                        <li className="nav-item ms-auto me-2" role="presentation">
                                                            <button className="nav-link" id="home-tab" data-bs-toggle="tab"
                                                                data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                                                aria-selected="true"><i className="fa-solid fa-cube"></i></button>
                                                            <p className="quotes_total_blue">Total</p>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link" id="profile-tab" data-bs-toggle="tab"
                                                                data-bs-target="#profile" type="button" role="tab"
                                                                aria-controls="profile" aria-selected="false"><i
                                                                    className="fa-solid fa-coins"></i></button>
                                                            <p className="quotes_total_blue">Dimensions</p>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content" id="myTabContent">
                                                        { getType == data.transport_mode ?
                                                            // Fetch data Store in State and render on page
                                                            <>
                                                                {getType == "Sea" ?
                                                                    <div className="tab-pane fade req_first_tab" id="home" role="tabpanel"
                                                                    aria-labelledby="home-tab">
                                                                        {/* {data.cargo && data.cargo.map((cargoData, index) => { */}
                                                                        {seaFormValues.map((element, index) => (
                                                                            <div key={index}>
                                                                                <div className="row mt-5">
                                                                                    <div className="request_hr"></div>
                                                                                    <div className="col-9 col-xl-10 col-lg-10 col-md-10 col-sm-10">
                                                                                        <label className="mb-2 con_lab">{t('container')}</label>
                                                                                        <div className="d-flex align-items-center">
                                                                                            <a href="/#" className="me-2">
                                                                                                <i className="fa-solid fa-ruler-horizontal ftgeneral_icon"></i>
                                                                                            </a>
                                                                                            <select id={`container${index}`} name="container" value={element.container || ""} className="form-select" onChange={e => handleChange(index, e)}>
                                                                                                <option value="--"> -- {t('select_container_type')} -- </option>
                                                                                                <option value="20ft general">{t('20ft_general')}</option>
                                                                                                <option value="20ft high cube">{t('20ft_high_cube')}</option>
                                                                                                <option value="40ft general">{t('40ft_general')}</option>
                                                                                                <option value="40ft high cube">{t('40ft_high_cube')}</option>
                                                                                                <option value="LCL">LCL</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-3 col-xl-2 col-lg-2 col-md-2 col-sm-2">
                                                                                        <label className="mb-2">{t('quantity')}</label>
                                                                                        <div className="d-flex align-items-center">
                                                                                            <select id={`qty${index}`} name="qty" value={element.qty || ""} className="form-select" onChange={e => handleChange(index, e)}>
                                                                                                <option value="--">-- {t('select_qty')} --</option>
                                                                                                <option value="1">1</option>
                                                                                                <option value="2">2</option>
                                                                                                <option value="3">3</option>
                                                                                                <option value="4">4</option>
                                                                                            </select>
                                                                                            <a type='button' className="ms-2" onClick={() => removeFormFields(index)}>
                                                                                                <i className="fa-solid fa-xmark ftgeneral_icon remove"></i>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="row mt-4">
                                                                                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                                        <h5 className='mb-4'>{t('cargo_type')}:</h5>
                                                                                            <div className="request_textarea">
                                                                                                <div className="input_box_contact form-floating-1 input_box_margin">
                                                                                                    <textarea className="form-control" id={`desc${index}`} name="desc" value={element.desc || ""} onChange={e => handleChange(index, e)}></textarea>
                                                                                                    <label htmlFor={`desc${index}`} name="desc" className="form-label">{t('good_description')}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                                            <div className="request_switch_1 form-check form-switch" onClick={toggleIsInsurance}>
                                                                                                <Switch
                                                                                                    name='is_insurance'
                                                                                                    // onValueChange={toggleSwitch}
                                                                                                    id={`is_insurance${index}`}
                                                                                                    value={isInsurance}
                                                                                                    onChange={e => handleChange(index, e)}
                                                                                                />
                                                                                                {/* <input className="form-check-input" type="checkbox" value={"true" || "false"} name='is_insurance' onChange={e => handleChange(index, e)} /> */}
                                                                                                <label className="form-check-label" htmlFor={`is_insurance${index}`}>{t('insurance')}</label>
                                                                                            </div>
                                                                                            <div className="request_label mt-3">
                                                                                                <div className="input_box input_box_margin">
                                                                                                    <input type="text" className="form-control" id={`goods_value${index}`} name="goods_value" value={element.goods_value || ""} onChange={e => handleChange(index, e)}
                                                                                                        aria-describedby="emailHelp" required="required" />
                                                                                                    <label htmlFor={`goods_value${index}`} className="form-label">{t('goods_value')}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                                            <div className="request_switch form-check form-switch" onClick={toggleIsDangerous}>
                                                                                                <Switch
                                                                                                    name='is_dangerous'
                                                                                                    id={`is_dangerous${index}`}
                                                                                                    // onValueChange={toggleSwitch}
                                                                                                    value={isDangerous}
                                                                                                    onChange={e => handleChange(index, e)}
                                                                                                />
                                                                                                {/* <input className="form-check-input" type="checkbox" value={"true" || "false"} name='is_dangerous' onChange={e => handleChange(index, e)} /> */}
                                                                                                <label className="form-check-label" htmlFor={`is_dangerous${index}`}>{t('dangerous')}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                                            <div className="request_switch form-check form-switch" onClick={toggleIsTemperatureControlled}>
                                                                                                <Switch
                                                                                                    name='is_temperature_controlled'
                                                                                                    id={`is_temperature_controlled${index}`}
                                                                                                    // onValueChange={toggleSwitch}
                                                                                                    value={isTemperatureControlled}
                                                                                                    onChange={e => handleChange(index, e)}
                                                                                                />
                                                                                                {/* <input className="form-check-input" type="checkbox" value={"true" || "false"} name='is_temperature_controlled' onChange={e => handleChange(index, e)} /> */}
                                                                                                <label className="form-check-label" htmlFor={`is_temperature_controlled${index}`}>{t('temperature_controlled')}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4">
                                                                                            <div className="request_textarea">
                                                                                                <div className="input_box_contact form-floating-1 input_box_margin">
                                                                                                    <textarea className="form-control" id={`remark${index}`} name="remark" value={element.remark || ""} onChange={e => handleChange(index, e)}
                                                                                                        ></textarea>
                                                                                                    <label htmlFor={`remark${index}`} name="remark" className="form-label">{t('remarks')} ({t('optional')})</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                                            <div className="request_files">
                                                                                                <div className="drop-zone">
                                                                                                    <span className="drop-zone__prompt">Drop file here or click to
                                                                                                        upload</span>
                                                                                                    <input type="file" name="myFile" className="drop-zone__input" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}

                                                                        <div className="req_fad_btn">
                                                                            <button type='button' className="btn btn_req_add" onClick={() => addFormFields()}>{t('add')} <i className="fa-solid fa-plus"></i></button>
                                                                        </div>
                                                                    </div>
                                                                :
                                                                    <div className="tab-pane fade req_first_tab" id="home" role="tabpanel"
                                                                        aria-labelledby="home-tab">
                                                                        {/* {data.cargo && data.cargo.map((cargoData, index) => { */}
                                                                        {formValues.map((element, index) => (
                                                                            <div key={index}>
                                                                                <div style={{ float: "right", margin: "7px" }}>
                                                                                    <span className='btn btn-danger' onClick={() => removeFormFields(index)}> X </span>
                                                                                </div>
                                                                                <div className="fadtab_header">
                                                                                    <div className="input-group mb-3">
                                                                                        <span className="input-group-text"> <i className="fa-solid fa-cube req_icon_size"></i>  </span>
                                                                                        <input type="text" className="form-control" id={`pieces${index}`} name="pieces" value={element.pieces || ""}  placeholder="5" onChange={e => handleChange(index, e)} />
                                                                                        <span className="input-group-text">pc(s)</span>
                                                                                        <label>{t('pieces')}</label>
                                                                                    </div>
                                                                                    <div className="input-group mb-3">
                                                                                        <span className="input-group-text"><i className="fa-solid fa-weight-hanging req_icon_size"></i></span>
                                                                                        <input type="text" className="form-control" id={`weight${index}`} name="weight" value={element.weight || ""} placeholder="1200" onChange={e => handleChange(index, e)} />
                                                                                        <span className="input-group-text">
                                                                                            <div className="dropdown">
                                                                                                <select name="weight_type" id={`weight_type${index}`} value={element.weight_type || ""} className='btn dropdown-toggle grey_span_5' onChange={e => handleChange(index, e)}>
                                                                                                    <option className="dropdown-item" value="--">--</option>
                                                                                                    <option className="dropdown-item" value="kg">kg</option>
                                                                                                    <option className="dropdown-item" value="gm">gm</option>
                                                                                                    <option className="dropdown-item" value="tn">tn</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </span>
                                                                                        <label>{t('weight')}</label>
                                                                                    </div>
                                                                                    <div className="input-group mb-3">
                                                                                        <span className="input-group-text"><i className="fa-solid fa-text-height req_icon_size"></i> </span>
                                                                                        <input type="text" className="form-control" id={`volume${index}`} name="volume" value={element.volume || ""} placeholder="5" onChange={e => handleChange(index, e)} />
                                                                                        <span className="input-group-text">m<sup>3</sup> </span>
                                                                                        <label>Volume</label>
                                                                                    </div>
                                                                                    <div className="input-group mb-3">
                                                                                        <span className="input-group-text">1200</span>
                                                                                        <input type="text" className="form-control" disabled />
                                                                                        <span className="input-group-text">kg</span>
                                                                                        <label>{t('chargable_weight')}</label>
                                                                                    </div>
                                                                                    <div className="input-group mb-3">
                                                                                        <span className="input-group-text">
                                                                                            <div className="dropdown">
                                                                                                <select name="type" id={`type${index}`} value={element.type || ""} className='btn dropdown-toggle grey_span_5' onChange={e => handleChange(index, e)}>
                                                                                                    <option className="dropdown-item" value="--">--</option>
                                                                                                    <option className="dropdown-item" value="pallet">{t('pallet')}</option>
                                                                                                    <option className="dropdown-item" value="carton">Carton</option>
                                                                                                    <option className="dropdown-item" value="crate">{t('crate')}</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </span>
                                                                                        <input type="text" className="form-control" disabled />
                                                                                        {/* <span className="input-group-text">
                                                                                            <img src="assets/images/icon/down-arrow.svg" alt="down-arrow" />
                                                                                        </span> */}
                                                                                        <label>{t('type')}</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="request_hr"></div>
                                                                                <div className="row">
                                                                                    <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                                        <h5 className='mb-4'>{t('cargo_type')}:</h5>
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                                                <div className="request_textarea">
                                                                                                    <div className="input_box_contact form-floating-1 input_box_margin">
                                                                                                        <textarea className="form-control" value={element.desc || ""} id={`desc${index}`} name="desc" onChange={e => handleChange(index, e)}></textarea>
                                                                                                        <label htmlFor="desc" name="desc" className="form-label">{t('good_description')}</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                                                <div className="request_switch_1 form-check form-switch" onClick={toggleIsInsurance}>
                                                                                                    <Switch
                                                                                                        name='is_insurance'
                                                                                                        // onValueChange={toggleSwitch}
                                                                                                        id={`is_insurance${index}`}
                                                                                                        value={isInsurance}
                                                                                                        onChange={e => handleChange(index, e)}
                                                                                                    />
                                                                                                    {/* <input className="form-check-input" type="checkbox" value={"true" || "false"} name='is_insurance' onChange={e => handleChange(index, e)} /> */}
                                                                                                    <label className="form-check-label" htmlFor={`is_insurance${index}`}>{t('insurance')}</label>
                                                                                                </div>
                                                                                                <div className="request_label mt-3">
                                                                                                    <div className="input_box input_box_margin">
                                                                                                        <input type="text" className="form-control" id={`goods_value${index}`} value={element.goods_value || ""} name="goods_value" onChange={e => handleChange(index, e)}
                                                                                                            aria-describedby="emailHelp" required="required" />
                                                                                                        <label htmlFor="goods_value" className="form-label">{t('goods_value')}</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                                                <div className="request_switch form-check form-switch" onClick={toggleIsDangerous}>
                                                                                                    <Switch
                                                                                                        name='is_dangerous'
                                                                                                        id={`is_dangerous${index}`}
                                                                                                        // onValueChange={toggleSwitch}
                                                                                                        value={isDangerous}
                                                                                                        onChange={e => handleChange(index, e)}
                                                                                                    />
                                                                                                    {/* <input className="form-check-input" type="checkbox" value={"true" || "false"} name='is_dangerous' onChange={e => handleChange(index, e)} /> */}
                                                                                                    <label className="form-check-label" htmlFor={`is_dangerous${index}`}>{t('dangerous')}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                                                <div className="request_switch form-check form-switch" onClick={toggleIsTemperatureControlled}>
                                                                                                    <Switch
                                                                                                        name='is_temperature_controlled'
                                                                                                        id={`is_temperature_controlled${index}`}
                                                                                                        // onValueChange={toggleSwitch}
                                                                                                        value={isTemperatureControlled}
                                                                                                        onChange={e => handleChange(index, e)}
                                                                                                    />
                                                                                                    {/* <input className="form-check-input" type="checkbox" value={"true" || "false"} name='is_temperature_controlled' onChange={e => handleChange(index, e)} /> */}
                                                                                                    <label className="form-check-label" htmlFor={`is_temperature_controlled${index}`}>{t('temperature_controlled')}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4">
                                                                                                <div className="request_textarea">
                                                                                                    <div className="input_box_contact form-floating-1 input_box_margin">
                                                                                                        <textarea className="form-control" name='remark' id={`remark${index}`} value={element.remark || ""} onChange={e => handleChange(index, e)}
                                                                                                    ></textarea>
                                                                                                        <label htmlFor="remark" className="form-label">{t('remarks')} ({t('optional')})</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                                                <div className="request_files">
                                                                                                    <div className="drop-zone">
                                                                                                        <span className="drop-zone__prompt">Drop file here or click to
                                                                                                            upload</span>
                                                                                                        <input type="file" name="myFile" className="drop-zone__input" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row justify-content-center">
                                                                                            <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                                                                                <div className="signin_button signin_button_next mt-4">
                                                                                                    {/* <button form='basic_details' type='submit' className="btn btn_signin w-100">Ask htmlFor a quote</button> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}

                                                                        <div className="req_fad_btn">
                                                                            <button type='button' className="btn btn_req_add" onClick={() => addFormFields()}>Add <i className="fa-solid fa-plus"></i></button>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>
                                                        :
                                                            // New Data Add in Road/Sea/Air (On Change)
                                                            <div className="tab-pane fade req_first_tab" id="home" role="tabpanel"
                                                                    aria-labelledby="home-tab">
                                                                { getType == "Sea" ?
                                                                    <>
                                                                        {seaFormValues.map((element, index) => (
                                                                            <div key={index}>
                                                                                <div className="row mt-5">
                                                                                    <div className="request_hr"></div>
                                                                                    <div className="col-9 col-xl-10 col-lg-10 col-md-10 col-sm-10">
                                                                                        <label className="mb-2 con_lab">{t('container')}</label>
                                                                                        <div className="d-flex align-items-center">
                                                                                            <a href="/#" className="me-2">
                                                                                                <i className="fa-solid fa-ruler-horizontal ftgeneral_icon"></i>
                                                                                            </a>
                                                                                            <select id={`container${index}`} name="container" value={element.container || ""} className="form-select" onChange={e => handleChange(index, e)}>
                                                                                                <option value="--"> -- {t('select_container_type')} -- </option>
                                                                                                <option value="20ft general">{t('20ft_general')}</option>
                                                                                                <option value="20ft high cube">{t('20ft_high_cube')}</option>
                                                                                                <option value="40ft general">{t('40ft_general')}</option>
                                                                                                <option value="40ft high cube">{t('40ft_high_cube')}</option>
                                                                                                <option value="LCL">LCL</option>
                                                                                            </select>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-3 col-xl-2 col-lg-2 col-md-2 col-sm-2">
                                                                                        <label className="mb-2">{t('quantity')}</label>
                                                                                        <div className="d-flex align-items-center">
                                                                                            <select id={`qty${index}`} name="qty" value={element.qty || ""} className="form-select" onChange={e => handleChange(index, e)}>
                                                                                                <option value="--">-- {t('select_qty')} --</option>
                                                                                                <option value="1">1</option>
                                                                                                <option value="2">2</option>
                                                                                                <option value="3">3</option>
                                                                                                <option value="4">4</option>
                                                                                            </select>
                                                                                            <a type='button' className="ms-2" onClick={() => removeFormFields(index)}>
                                                                                                <i className="fa-solid fa-xmark ftgeneral_icon remove"></i>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>

                                                                                    <div className="row mt-4">
                                                                                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                                        <h5 className='mb-4'>{t('cargo_type')}:</h5>
                                                                                            <div className="request_textarea">
                                                                                                <div className="input_box_contact form-floating-1 input_box_margin">
                                                                                                    <textarea className="form-control" id={`desc${index}`} name="desc" value={element.desc || ""} onChange={e => handleChange(index, e)}></textarea>
                                                                                                    <label htmlFor={`desc${index}`} name="desc" className="form-label">{t('good_description')}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                                            <div className="request_switch_1 form-check form-switch" onClick={toggleIsInsurance}>
                                                                                                <Switch
                                                                                                    name='is_insurance'
                                                                                                    // onValueChange={toggleSwitch}
                                                                                                    value={isInsurance}
                                                                                                    onChange={e => handleChange(index, e)}
                                                                                                />
                                                                                                {/* <input className="form-check-input" type="checkbox" value={"true" || "false"} name='is_insurance' onChange={e => handleChange(index, e)} /> */}
                                                                                                <label className="form-check-label" htmlFor="is_insurance">{t('insurance')}</label>
                                                                                            </div>
                                                                                            <div className="request_label mt-3">
                                                                                                <div className="input_box input_box_margin">
                                                                                                    <input type="text" className="form-control" id={`goods_value${index}`} name="goods_value" value={element.goods_value || ""} onChange={e => handleChange(index, e)}
                                                                                                        aria-describedby="emailHelp" required="required" />
                                                                                                    <label htmlFor={`goods_value${index}`} className="form-label">{t('goods_value')}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                                            <div className="request_switch form-check form-switch" onClick={toggleIsDangerous}>
                                                                                                <Switch
                                                                                                    name='is_dangerous'
                                                                                                    // onValueChange={toggleSwitch}
                                                                                                    value={isDangerous}
                                                                                                    onChange={e => handleChange(index, e)}
                                                                                                />
                                                                                                {/* <input className="form-check-input" type="checkbox" value={"true" || "false"} name='is_dangerous' onChange={e => handleChange(index, e)} /> */}
                                                                                                <label className="form-check-label" htmlFor="is_dangerous">{t('dangerous')}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                                            <div className="request_switch form-check form-switch" onClick={toggleIsTemperatureControlled}>
                                                                                                <Switch
                                                                                                    name='is_temperature_controlled'
                                                                                                    // onValueChange={toggleSwitch}
                                                                                                    value={isTemperatureControlled}
                                                                                                    onChange={e => handleChange(index, e)}
                                                                                                />
                                                                                                {/* <input className="form-check-input" type="checkbox" value={"true" || "false"} name='is_temperature_controlled' onChange={e => handleChange(index, e)} /> */}
                                                                                                <label className="form-check-label" htmlFor="is_temperature_controlled">{t('temperature_controlled')}</label>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4">
                                                                                            <div className="request_textarea">
                                                                                                <div className="input_box_contact form-floating-1 input_box_margin">
                                                                                                    <textarea className="form-control" id={`remark${index}`} name="remark" value={element.remark || ""} onChange={e => handleChange(index, e)}
                                                                                                        ></textarea>
                                                                                                    <label htmlFor={`remark${index}`} name="remark" className="form-label">{t('remarks')} ({t('optional')})</label>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                                            <div className="request_files">
                                                                                                <div className="drop-zone">
                                                                                                    <span className="drop-zone__prompt">Drop file here or click to
                                                                                                        upload</span>
                                                                                                    <input type="file" name="myFile" className="drop-zone__input" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                        <div className="req_fad_btn">
                                                                            <a type='button' className="btn btn_req_add" onClick={() => addFormFields()}>{t('add')} <i className="fa-solid fa-plus"></i></a>
                                                                        </div>
                                                                    </>
                                                                :
                                                                    <>
                                                                        {formValues.map((element, index) => (
                                                                            <div key={index}>
                                                                                <div style={{ float: "right", margin: "7px" }}>
                                                                                    <span className='btn btn-danger' onClick={() => removeFormFields(index)}> X </span>
                                                                                </div>
                                                                                <div className="fadtab_header">
                                                                                    <div className="input-group mb-3">
                                                                                        <span className="input-group-text"> <i className="fa-solid fa-cube req_icon_size"></i>  </span>
                                                                                        <input type="text" className="form-control" name="pieces" value={element.pieces || ""}  placeholder="5" onChange={e => handleChange(index, e)} />
                                                                                        <span className="input-group-text">pc(s)</span>
                                                                                        <label>{t('pieces')}</label>
                                                                                    </div>
                                                                                    <div className="input-group mb-3">
                                                                                        <span className="input-group-text"><i className="fa-solid fa-weight-hanging req_icon_size"></i></span>
                                                                                        <input type="text" className="form-control" name="weight" value={element.weight || ""} placeholder="1200" onChange={e => handleChange(index, e)} />
                                                                                        <span className="input-group-text">
                                                                                            <div className="dropdown">
                                                                                                <select name="weight_type" value={element.weight_type || ""} className='btn dropdown-toggle grey_span_5' onChange={e => handleChange(index, e)}>
                                                                                                    <option className="dropdown-item" value="--">--</option>
                                                                                                    <option className="dropdown-item" value="kg">kg</option>
                                                                                                    <option className="dropdown-item" value="gm">gm</option>
                                                                                                    <option className="dropdown-item" value="tn">tn</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </span>
                                                                                        <label>{t('weight')}</label>
                                                                                    </div>
                                                                                    <div className="input-group mb-3">
                                                                                        <span className="input-group-text"><i className="fa-solid fa-text-height req_icon_size"></i> </span>
                                                                                        <input type="text" className="form-control" name="volume" value={element.volume || ""} placeholder="5" onChange={e => handleChange(index, e)} />
                                                                                        <span className="input-group-text">m<sup>3</sup> </span>
                                                                                        <label>Volume</label>
                                                                                    </div>
                                                                                    <div className="input-group mb-3">
                                                                                        <span className="input-group-text">1200</span>
                                                                                        <input type="text" className="form-control" disabled />
                                                                                        <span className="input-group-text">kg</span>
                                                                                        <label>{t('chargable_weight')}</label>
                                                                                    </div>
                                                                                    <div className="input-group mb-3">
                                                                                        <span className="input-group-text">
                                                                                            <div className="dropdown">
                                                                                                <select name="type" value={element.type || ""} className='btn dropdown-toggle grey_span_5' onChange={e => handleChange(index, e)}>
                                                                                                    <option className="dropdown-item" value="--">--</option>
                                                                                                    <option className="dropdown-item" value="pallet">{t('pallet')}</option>
                                                                                                    <option className="dropdown-item" value="carton">Carton</option>
                                                                                                    <option className="dropdown-item" value="crate">{t('crate')}</option>
                                                                                                </select>
                                                                                            </div>
                                                                                        </span>
                                                                                        <input type="text" className="form-control" disabled />
                                                                                        {/* <span className="input-group-text">
                                                                                            <img src="assets/images/icon/down-arrow.svg" alt="down-arrow" />
                                                                                        </span> */}
                                                                                        <label>{t('type')}</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="request_hr"></div>
                                                                                <div className="row">
                                                                                    <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                                        <h5 className='mb-4'>{t('cargo_type')}:</h5>
                                                                                        <div className="row">
                                                                                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                                                <div className="request_textarea">
                                                                                                    <div className="input_box_contact form-floating-1 input_box_margin">
                                                                                                        <textarea className="form-control" value={element.desc || ""} name="desc" onChange={e => handleChange(index, e)}></textarea>
                                                                                                        <label htmlFor="desc" name="desc" className="form-label">{t('good_description')}</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                                                <div className="request_switch_1 form-check form-switch" onClick={toggleIsInsurance}>
                                                                                                    <Switch
                                                                                                        name='is_insurance'
                                                                                                        // onValueChange={toggleSwitch}
                                                                                                        value={isInsurance}
                                                                                                        onChange={e => handleChange(index, e)}
                                                                                                    />
                                                                                                    {/* <input className="form-check-input" type="checkbox" value={"true" || "false"} name='is_insurance' onChange={e => handleChange(index, e)} /> */}
                                                                                                    <label className="form-check-label" htmlFor="is_insurance">{t('insurance')}</label>
                                                                                                </div>
                                                                                                <div className="request_label mt-3">
                                                                                                    <div className="input_box input_box_margin">
                                                                                                        <input type="text" className="form-control" value={element.goods_value || ""} name="goods_value" onChange={e => handleChange(index, e)}
                                                                                                            aria-describedby="emailHelp" required="required" />
                                                                                                        <label htmlFor="goods_value" className="form-label">{t('goods_value')}</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                                                <div className="request_switch form-check form-switch" onClick={toggleIsDangerous}>
                                                                                                    <Switch
                                                                                                        name='is_dangerous'
                                                                                                        // onValueChange={toggleSwitch}
                                                                                                        value={isDangerous}
                                                                                                        onChange={e => handleChange(index, e)}
                                                                                                    />
                                                                                                    {/* <input className="form-check-input" type="checkbox" value={"true" || "false"} name='is_dangerous' onChange={e => handleChange(index, e)} /> */}
                                                                                                    <label className="form-check-label" htmlFor="is_dangerous">{t('dangerous')}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                                                <div className="request_switch form-check form-switch" onClick={toggleIsTemperatureControlled}>
                                                                                                    <Switch
                                                                                                        name='is_temperature_controlled'
                                                                                                        // onValueChange={toggleSwitch}
                                                                                                        value={isTemperatureControlled}
                                                                                                        onChange={e => handleChange(index, e)}
                                                                                                    />
                                                                                                    {/* <input className="form-check-input" type="checkbox" value={"true" || "false"} name='is_temperature_controlled' onChange={e => handleChange(index, e)} /> */}
                                                                                                    <label className="form-check-label" htmlFor="is_temperature_controlled">{t('temperature_controlled')}</label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4">
                                                                                                <div className="request_textarea">
                                                                                                    <div className="input_box_contact form-floating-1 input_box_margin">
                                                                                                        <textarea className="form-control" name='remark' value={element.remark || ""} onChange={e => handleChange(index, e)}
                                                                                                    ></textarea>
                                                                                                        <label htmlFor="remark" className="form-label">{t('remark')} ({t('optional')})</label>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                                                <div className="request_files">
                                                                                                    <div className="drop-zone">
                                                                                                        <span className="drop-zone__prompt">Drop file here or click to
                                                                                                            upload</span>
                                                                                                        <input type="file" name="myFile" className="drop-zone__input" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="row justify-content-center">
                                                                                            <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                                                                                <div className="signin_button signin_button_next mt-4">
                                                                                                    {/* <button form='basic_details' type='submit' className="btn btn_signin w-100">Ask htmlFor a quote</button> */}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                        <div className="req_fad_btn">
                                                                            <a className="btn btn_req_add" onClick={() => addFormFields()}>{t('add')} <i className="fa-solid fa-plus"></i></a>
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        }
                                                        <div className="tab-pane fade main_piece_bg" id="profile" role="tabpanel"
                                                            aria-labelledby="profile-tab">

                                                            <div className="fadtab_header">
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text"><i
                                                                            className="fa-solid fa-cube req_icon_size"></i>4</span>
                                                                    <input type="text" className="form-control" disabled />
                                                                    <span className="input-group-text">pc(s)</span>
                                                                    <label>Pieces</label>
                                                                </div>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text"><i
                                                                            className="fa-solid fa-weight-hanging req_icon_size"></i>380</span>
                                                                    <input type="text" className="form-control" disabled />
                                                                    <span className="input-group-text">kg</span>
                                                                    <label>Weight</label>
                                                                </div>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text"><i
                                                                            className="fa-solid fa-text-height req_icon_size"></i>3.84</span>
                                                                    <input type="text" className="form-control" disabled />
                                                                    <span className="input-group-text">m<sup>3</sup> </span>
                                                                    <label>Volume</label>
                                                                </div>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text">640</span>
                                                                    <input type="text" className="form-control" disabled />
                                                                    <span className="input-group-text">kg</span>
                                                                    <label>Chargable weight</label>
                                                                </div>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text">
                                                                        <div className="dropdown">
                                                                            <button
                                                                                className="btn dropdown-toggle w-100 d-flex justify-content-between align-items-center"
                                                                                type="button" id="dropdownMenuButton1"
                                                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                                                <p className="mb-0">pallet</p>

                                                                            </button>
                                                                            <ul className="dropdown-menu w-100 list_contactinput_ul"
                                                                                aria-labelledby="dropdownMenuButton1">
                                                                                <li><a className="dropdown-item" href="/#">carton</a>
                                                                                </li>
                                                                                <li><a className="dropdown-item" href="/#">crate</a></li>
                                                                                <li className="list_contactinput">
                                                                                    <div className="input_box">
                                                                                        <input type="text" className="form-control"
                                                                                            id="exampleInputEmail1" name='others'
                                                                                            placeholder="other"
                                                                                            aria-describedby="emailHelp"
                                                                                            required="required" />
                                                                                        <label htmlFor="exampleInputEmail1"
                                                                                            className="form-label"></label>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </span>
                                                                    <input type="text" className="form-control" disabled />
                                                                    <span className="input-group-text">
                                                                        <img src={process.env.PUBLIC_URL +"/assets/images/icon/down-arrow.svg"}
                                                                            alt="down-arrow" />
                                                                    </span>
                                                                    <label>Type</label>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-2">
                                                                    <div className="piece_heading">
                                                                        <span>Pieces</span>
                                                                    </div>
                                                                    <ul className="piece_li">
                                                                        <li>
                                                                            <p>1</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>3</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>1</p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="piece_heading">
                                                                        <div className="dimen_fle">
                                                                            <span>Dimensions</span>
                                                                            <div className="dropdown">
                                                                                <button className="btn dropdown-toggle" type="button"
                                                                                    id="dropdownMenuButton1"
                                                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                                                    cm <img
                                                                                        src={process.env.PUBLIC_URL +"/assets/images/icon/down-arrow-white.svg"}
                                                                                        alt="down-arrow" />
                                                                                </button>
                                                                                <ul className="dropdown-menu"
                                                                                    aria-labelledby="dropdownMenuButton1">
                                                                                    <li><a className="dropdown-item" href="/#">m</a></li>
                                                                                    <li><a className="dropdown-item" href="/#">km</a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="piece_dimensions">
                                                                        <p>120</p>
                                                                        <p>80</p>
                                                                        <p>100</p>
                                                                    </div>
                                                                    <div className="piece_dimensions">
                                                                        <p>80</p>
                                                                        <p>120</p>
                                                                        <p>100</p>
                                                                    </div>
                                                                    <div className="piece_dimensions">
                                                                        <p className="font_12">Length</p>
                                                                        <p className="font_12">Width</p>
                                                                        <p className="font_12">Height</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="piece_heading">
                                                                        <div className="dimen_fle">
                                                                            <span>Weight</span>
                                                                            <div className="dropdown">
                                                                                <button className="btn dropdown-toggle" type="button"
                                                                                    id="dropdownMenuButton1"
                                                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                                                    kg <img
                                                                                        src={process.env.PUBLIC_URL +"/assets/images/icon/down-arrow-white.svg"}
                                                                                        alt="down-arrow" />
                                                                                </button>
                                                                                <ul className="dropdown-menu"
                                                                                    aria-labelledby="dropdownMenuButton1">
                                                                                    <li><a className="dropdown-item" href="/#">gm</a>
                                                                                    </li>
                                                                                    <li><a className="dropdown-item" href="/#">tn</a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="piece_30 piece_dimensions">
                                                                        <p>150</p>
                                                                        <span className="switch">
                                                                            <input type="checkbox" id="switcher" checked />
                                                                            <label htmlFor="switcher"></label>
                                                                        </span>
                                                                    </div>
                                                                    <div className="piece_30 piece_dimensions">
                                                                        <p>230</p>
                                                                        <span className="switch">
                                                                            <input type="checkbox" id="switcher" />
                                                                            <label htmlFor="switcher"></label>
                                                                        </span>
                                                                    </div>
                                                                    <div className="piece_30 piece_dimensions">
                                                                        <p className="font_12">Width</p>
                                                                        <span className="switch">
                                                                            <input type="checkbox" id="switcher" checked />
                                                                            <label htmlFor="switcher"></label>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="check_box_1">
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox"
                                                                                id="inlineCheckbox1" value="option1" />
                                                                            <label className="form-check-label"
                                                                                htmlFor="inlineCheckbox1">non-stackable</label>
                                                                        </div>
                                                                        <a href="/#">
                                                                            <i className="fa-solid fa-trash incoterm_icon"></i>
                                                                        </a>
                                                                    </div>
                                                                    <div className="check_box_1">
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox"
                                                                                id="inlineCheckbox1" value="option1" />
                                                                            <label className="form-check-label"
                                                                                htmlFor="inlineCheckbox1">non-stackable</label>
                                                                        </div>
                                                                        <a href="/#">
                                                                            <i className="fa-solid fa-trash incoterm_icon"></i>
                                                                        </a>
                                                                    </div>
                                                                    <div className="check_box_1">
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox"
                                                                                id="inlineCheckbox1" value="option1" />
                                                                            <label className="form-check-label"
                                                                                htmlFor="inlineCheckbox1">non-stackable</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="req_fad_btn">
                                                                <a href="/#" className="btn btn_req_add">Add <i
                                                                        className="fa-solid fa-plus"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="request_hr"></div>
                                        <div className="row">
                                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                                        <div className="signin_button signin_button_next">
                                                            <button id='btn_submit' type='submit' className="btn btn_signin w-100">{t('edit_for_quote')}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </section>
                        </section>
                    </div>
                </div>
            </main>

            <Helmet>
                <script>
                    {
                        `
                        $(".add-more").click(function(){ 
                            var html = $(".copy").html();
                            console.log(":-----", html)
                            $(".after-add-more").after(html);
                        });

                        $(document).ready(function(){
                            $('.2').on('click', function(){
                                $('a.2').addClass('btn_quotes_incoterm_active');
                                $('a.1').removeClass('btn_quotes_incoterm_active');
                                $('a.3').removeClass('btn_quotes_incoterm_active');
                                $('a.4').removeClass('btn_quotes_incoterm_active');
                            })
                            $('.1').on('click', function(){
                                $('a.1').addClass('btn_quotes_incoterm_active');
                                $('a.2').removeClass('btn_quotes_incoterm_active');
                                $('a.3').removeClass('btn_quotes_incoterm_active');
                                $('a.4').removeClass('btn_quotes_incoterm_active');
                            })
                            $('.3').on('click', function(){
                                $('a.3').addClass('btn_quotes_incoterm_active');
                                $('a.2').removeClass('btn_quotes_incoterm_active');
                                $('a.1').removeClass('btn_quotes_incoterm_active');
                                $('a.4').removeClass('btn_quotes_incoterm_active');
                            })
                            $('.4').on('click', function(){
                                $('a.4').addClass('btn_quotes_incoterm_active');
                                $('a.2').removeClass('btn_quotes_incoterm_active');
                                $('a.3').removeClass('btn_quotes_incoterm_active');
                                $('a.1').removeClass('btn_quotes_incoterm_active');
                            })
                        });
                        `
                    }
                </script>
            </Helmet>
        </>
    )
}
