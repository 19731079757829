import React from 'react'
import Sidebar from '../../components/customerComponents/Sidebar/Sidebar'
import Topbar from '../../components/customerComponents/Topbar/Topbar'

export default function Others() {
    const commonProps = {title: "others"}

    return (
        <>
            <main calassName="dash_b h-100">
                <div calassName="container-fluid h-100">
                    <div id="wrapper">
                        <Sidebar commonProps={commonProps}/>

                        <section id="content-wrapper" calassName="h-100">
                            <Topbar />

                            <section calassName="dashboard_area h-100">
                                <div calassName="main_my_account h-100">
                                    <div calassName="row h-100">
                                        <div calassName="col-xl-4 align-items-start">
                                            <div calassName="nav flex-column nav-pills" id="v-pills-tab" role="tablist"
                                                aria-orientation="vertical">
                                                <button calassName="nav-link active d-flex align-items-center" id="v-pills-home-tab"
                                                    data-bs-toggle="pill" data-bs-target="#v-pills-home" type="button"
                                                    role="tab" aria-controls="v-pills-home" aria-selected="true"> <img
                                                        src="assets/images/icon/edit-profile.svg" alt="edit" calassName="me-2" /> Edit
                                                    profile <img src="assets/images/icon/right-arrow.svg" calassName="ms-auto"
                                                        alt="arrow" /> 
                                                </button>

                                                 <button calassName="nav-link d-flex align-items-center" id="v-pills-profile-tab"
                                                    data-bs-toggle="pill" data-bs-target="#v-pills-profile" type="button"
                                                    role="tab" aria-controls="v-pills-profile" aria-selected="false"> <img
                                                        src="assets/images/icon/change-language.svg" alt="language"
                                                        calassName="me-2" /> Change
                                                    language <img src="assets/images/icon/right-arrow.svg" calassName="ms-auto"
                                                        alt="arrow" /> </button>

                                                <button calassName="nav-link d-flex align-items-center" id="v-pills-messages-tab"
                                                    data-bs-toggle="pill" data-bs-target="#v-pills-messages" type="button"
                                                    role="tab" aria-controls="v-pills-messages" aria-selected="false"> <img
                                                        src="assets/images/icon/change-password.svg" alt="key" calassName="me-2" />
                                                    Change
                                                    password <img src="assets/images/icon/right-arrow.svg" calassName="ms-auto"
                                                        alt="arrow" /> 
                                                </button>

                                                <button calassName="nav-link d-flex align-items-center" id="v-pills-privacy-tab"
                                                    data-bs-toggle="pill" data-bs-target="#v-pills-privacy" type="button"
                                                    role="tab" aria-controls="v-pills-privacy" aria-selected="false"> <img
                                                        src="assets/images/icon/privacy.svg" alt="privacy" calassName="me-2" /> Privacy
                                                    <img src="assets/images/icon/right-arrow.svg" calassName="ms-auto" alt="arrow" />
                                                </button>

                                                <button calassName="nav-link d-flex align-items-center" id="v-pills-about-tab"
                                                    data-bs-toggle="pill" data-bs-target="#v-pills-about" type="button"
                                                    role="tab" aria-controls="v-pills-about" aria-selected="false"> <img
                                                        src="assets/images/icon/about-us.svg" alt="about" calassName="me-2" /> About us
                                                    <img src="assets/images/icon/right-arrow.svg" calassName="ms-auto" alt="arrow" />
                                                </button>

                                                <button calassName="nav-link d-flex align-items-center" id="v-pills-terms-tab"
                                                    data-bs-toggle="pill" data-bs-target="#v-pills-terms" type="button"
                                                    role="tab" aria-controls="v-pills-terms" aria-selected="false"> <img
                                                        src="assets/images/icon/terms-and-conditon.svg" alt="terms"
                                                        calassName="me-2" /> Terms & conditions <img
                                                        src="assets/images/icon/right-arrow.svg" calassName="ms-auto" alt="arrow" />
                                                </button>

                                                <button calassName="nav-link d-flex align-items-center" id="v-pills-faq-tab"
                                                    data-bs-toggle="pill" data-bs-target="#v-pills-faq" type="button"
                                                    role="tab" aria-controls="v-pills-faq" aria-selected="false"> <img
                                                        src="assets/images/icon/faq.svg" alt="faq" calassName="me-2" /> FAQ & feedback
                                                    <img src="assets/images/icon/right-arrow.svg" calassName="ms-auto" alt="arrow" />
                                                </button>
                                            </div>
                                        </div>

                                        <div calassName="col-xl-8 justify-content-center">
                                            <div calassName="tab-content w-100" id="v-pills-tabContent">
                                                <div calassName="tab-pane fade show active" id="v-pills-home" role="tabpanel"
                                                    aria-labelledby="v-pills-home-tab">
                                                    <div calassName="about_text">
                                                        <div calassName="acoount_image">
                                                            <img src="assets/images/user/user-1.svg" alt="profile-omage" />
                                                        </div>
                                                        <div calassName="input_box input_box_margin change_drop_zone">
                                                            <div calassName="account_input drop-zone">
                                                                <div calassName="drop-zone__prompt"> 
                                                                    <p>Change profile picture</p> 
                                                                    <a href="/#">
                                                                        <img src="assets/images/icon/upload.svg" alt="upload" />
                                                                    </a>
                                                                </div>
                                                                <input type="file" name="myFile" calassName="drop-zone__input" />
                                                            </div>
                                                        </div>
                                                        <div calassName="input_box input_box_margin">
                                                            <input type="text" calassName="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" required="required" />
                                                            <label for="exampleInputEmail1" calassName="form-label">First
                                                                name</label>
                                                        </div>
                                                        <div calassName="input_box input_box_margin">
                                                            <input type="text" calassName="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" required="required" />
                                                            <label for="exampleInputEmail1" calassName="form-label">Last
                                                                name</label>
                                                        </div>
                                                        <div calassName="input_box input_box_margin">
                                                            <input type="text" calassName="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" required="required" />
                                                            <label for="exampleInputEmail1" calassName="form-label">Mobile
                                                                number</label>
                                                        </div>
                                                        <div calassName="input_box">
                                                            <input type="email" calassName="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" required="required" />
                                                            <label for="exampleInputEmail1" calassName="form-label">Enter
                                                                email</label>
                                                        </div>
                                                        <div calassName="row justify-content-center">
                                                            <div calassName="col-10 col-xl-4 col-lg-4 col-md-5 col-sm-5">
                                                                <div calassName="signin_button account_sign_in">
                                                                    <a href="/#" calassName="btn btn_signin w-100">Save</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div calassName="tab-pane fade" id="v-pills-profile" role="tabpanel"
                                                    aria-labelledby="v-pills-profile-tab">
                                                    <div calassName="about_text_2">
                                                        <div calassName="request_select">
                                                            <h4>Select language</h4>
                                                            <select calassName="form-select" aria-label="Default select example">
                                                                <option selected="">English</option>
                                                                <option value="1">French</option>
                                                                <option value="2">German</option>
                                                                <option value="3">Irish</option>
                                                            </select>
                                                            <div calassName="row justify-content-center">
                                                                <div calassName="col-4 col-xl-3 col-lg-3 col-md-4 col-sm-5">
                                                                    <div calassName="signin_button account_sign_in">
                                                                        <a href="/#" calassName="btn btn_signin w-100">Save</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div calassName="tab-pane fade" id="v-pills-messages" role="tabpanel"
                                                    aria-labelledby="v-pills-messages-tab">
                                                    <div calassName="about_text_3">
                                                        <h4>Change password</h4>
                                                        <div calassName="input_box input_box_margin">
                                                            <input type="text" calassName="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" required="required" />
                                                            <label for="exampleInputEmail1" calassName="form-label">Old password</label>
                                                        </div>
                                                        <div calassName="input_box input_box_margin">
                                                            <input type="text" calassName="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" required="required" />
                                                            <label for="exampleInputEmail1" calassName="form-label">New password</label>
                                                        </div>
                                                        <div calassName="input_box">
                                                            <input type="text" calassName="form-control" id="exampleInputEmail1"
                                                                aria-describedby="emailHelp" required="required" />
                                                            <label for="exampleInputEmail1" calassName="form-label">Confirm password</label>
                                                        </div>
                                                        <div calassName="row justify-content-center">
                                                            <div calassName="col-10 col-xl-4 col-lg-4 col-md-5 col-sm-5">
                                                                <div calassName="signin_button account_sign_in">
                                                                    <a href="/#" calassName="btn btn_signin w-100">Save</a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div calassName="tab-pane fade" id="v-pills-privacy" role="tabpanel"
                                                    aria-labelledby="v-pills-privacy-tab">
                                                    <div calassName="about_text_4"></div>
                                                </div>

                                                <div calassName="tab-pane fade" id="v-pills-about" role="tabpanel"
                                                    aria-labelledby="v-pills-about-tab">
                                                    <div calassName="about_text_5">
                                                        <h4>About us</h4>
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Sapiente consequuntur saepe dolor maiores officia, voluptate est tempora recusandae doloremque eius quis fugiat, explicabo ipsum, expedita et molestias vel ut facere.</p>
                                                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quia, accusamus officiis minus molestias eius vitae.</p>
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quis nihil rem eius necessitatibus inventore voluptate facilis pariatur molestiae libero ipsa? Voluptatem, eligendi voluptate! Molestiae qui voluptatum deleniti aperiam vero quaerat inventore eum vel iste, ut officia asperiores dignissimos laborum voluptate dolores porro repellendus nisi unde incidunt recusandae! Obcaecati, numquam dicta.</p>
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Alias, nam iusto necessitatibus natus eaque temporibus. At maiores ea assumenda praesentium voluptas fugit hic nobis officiis quisquam cumque, sequi expedita quae perspiciatis ipsam aspernatur. Similique architecto sequi earum id fugiat accusantium, quia, fugit, omnis dolore est voluptatum iure natus quae! Labore, incidunt. Similique, voluptatibus voluptatum quas ducimus a veniam nobis quidem iure eum placeat, minima numquam. Rem dolor quibusdam debitis qui.</p>
                                                    </div>
                                                </div>

                                                <div calassName="tab-pane fade" id="v-pills-terms" role="tabpanel"
                                                    aria-labelledby="v-pills-terms-tab">
                                                    <div calassName="about_text_6">
                                                        <h4>Terms & conditions</h4>
                                                        <h6>Privacy</h6>
                                                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laborum cupiditate neque error architecto aperiam ullam corrupti facilis eos optio quo nisi, ea ipsa hic ducimus accusantium possimus quibusdam inventore maiores.</p>
                                                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laborum cupiditate neque error architecto aperiam ullam corrupti facilis eos optio quo nisi, ea ipsa hic ducimus accusantium possimus quibusdam inventore maiores.</p>
                                                        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Laborum cupiditate neque error architecto aperiam ullam corrupti facilis eos optio quo nisi, ea ipsa hic ducimus accusantium possimus quibusdam inventore maiores.</p>

                                                        <h6 calassName="mt-4">Policy</h6>
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur ipsum quas, itaque velit id corporis possimus. Recusandae, sunt voluptatem. Consequatur.</p>
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur ipsum quas, itaque velit id corporis possimus. Recusandae, sunt voluptatem. Consequatur.</p>
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur ipsum quas, itaque velit id corporis possimus. Recusandae, sunt voluptatem. Consequatur.</p>
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur ipsum quas, itaque velit id corporis possimus. Recusandae, sunt voluptatem. Consequatur.</p>
                                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur ipsum quas, itaque velit id corporis possimus. Recusandae, sunt voluptatem. Consequatur.</p>
                                                    </div>
                                                </div>

                                                <div calassName="tab-pane fade" id="v-pills-faq" role="tabpanel"
                                                    aria-labelledby="v-pills-faq-tab">
                                                    <div calassName="about_text_7">
                                                        <div calassName="about_text_7">
                                                            <h4>Feedback</h4>
                                                            <div calassName="about_text_7_textarea">
                                                                <label for="exampleFormControlTextarea1" calassName="form-label">Report a problem</label>
                                                                <textarea calassName="form-control" id="exampleFormControlTextarea1" rows="1" placeholder="Lorem ipsum dolor sit amet consectetur adipisicing elit. Aspernatur ipsum quas"></textarea>
                                                            </div>
                                                            <div calassName="row justify-content-end">
                                                                <div calassName="col-4 col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                                                    <div calassName="signin_button">
                                                                        <a href="/#" calassName="btn btn_signin w-100">Submit</a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div calassName="about_text_7_help">
                                                                <p calassName="mb-4">Help</p>
                                                                <p calassName="about_text_7_help_p"><span>Q.1</span> Lorem ipsum dolor sit, amet consectetur adipisicing.</p>
                                                                <p><span>Ans.</span> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quisquam temporibus officiis quis sunt debitis voluptates aut recusandae sed assumenda, eos corrupti magnam consectetur earum et?</p>
                                                                <p calassName="about_text_7_help_p"><span>Q.2</span> Lorem ipsum dolor sit, amet consectetur adipisicing.</p>
                                                                <p><span>Ans.</span> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quisquam temporibus officiis quis sunt debitis voluptates aut recusandae sed assumenda, eos corrupti magnam consectetur earum et?</p>
                                                                <p calassName="about_text_7_help_p"><span>Q.3</span> Lorem ipsum dolor sit, amet consectetur adipisicing.</p>
                                                                <p><span>Ans.</span> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quisquam temporibus officiis quis sunt debitis voluptates aut recusandae sed assumenda, eos corrupti magnam consectetur earum et?</p>
                                                                <p calassName="about_text_7_help_p"><span>Q.4</span> Lorem ipsum dolor sit, amet consectetur adipisicing.</p>
                                                                <p><span>Ans.</span> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quisquam temporibus officiis quis sunt debitis voluptates aut recusandae sed assumenda, eos corrupti magnam consectetur earum et?</p>
                                                                <p calassName="about_text_7_help_p"><span>Q.5</span> Lorem ipsum dolor sit, amet consectetur adipisicing.</p>
                                                                <p><span>Ans.</span> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quisquam temporibus officiis quis sunt debitis voluptates aut recusandae sed assumenda, eos corrupti magnam consectetur earum et?</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </section>
                    </div>
                </div>
            </main>
        </>
    )
}
