import React, { useState } from 'react'
import {Helmet} from "react-helmet";
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { changePassword, forgotPassword, login, verifyCode } from '../../../api/apiHandler';
import useStorage from "../../../hooks/useStorage.ts";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastMessage from '../../Toast';
import Slider from '../Slider/Slider';
import { useTranslation } from "react-i18next";
// import { FormControl, InputAdornment, InputLabel, OutlinedInput, TextField } from '@material-ui/core';

const Login = () => {
    const navigate = useNavigate();

    const { setItem,getItem } = useStorage();
    const [getMail, setMail] = useState([]);
    const [passwordType, setPasswordType] = useState("password");
    const { t, i18n } = useTranslation();
    var [language, setLanguage] = useState(localStorage.getItem("language") === "fr" ? "fr" : "en");
    
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required('Email / Phone is required')
            .test('email_or_phone', 'Email / Phone is invalid', (value) => {
                return validateEmail(value) || validatePhone(parseInt(value ?? '0'));
            }),
            // .required('Email is required')
            // .email('Email is invalid'),
        password: Yup.string()
            .min(6, 'Password must be at least 6 characters')
            .required('Password is required'),
    });

    const validateEmail = (email) => {
        return Yup.string().email().isValidSync(email)
    };

    const validatePhone = (phone) => {
        return Yup.number().integer().positive().test(
           (phone) => {
             return (phone && phone.toString().length >= 8 && phone.toString().length <= 14) ? true : false;
           }
         ).isValidSync(phone);
    };

    const forgotPasswordValidation = Yup.object().shape({       
        email: Yup.string()
                .email('Email address is invalid')
                .required('Email address is required')
    });

    // const email = getMail;
    const verifyCodeValidation = Yup.object().shape({       
        email: Yup.string(),
        code: Yup.string()
            .required('Code is required'),
    });

    const changePasswordValidation = Yup.object().shape({       
        email: Yup.string(),
        new_password: Yup.string()
            .required('Password is required')    
            .min(6, 'Password must be at least 6 characters'),
        conform_password: Yup.string()
            .required('Confirm password is required')
            .min(6, 'Password must be at least 6 characters')
            .oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    const forgotPasswordFormOptions = { resolver: yupResolver(forgotPasswordValidation) };
    const verifyCodeFormOptions = { resolver: yupResolver(verifyCodeValidation) };
    const changePasswordFormOptions = { resolver: yupResolver(changePasswordValidation) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const {register: register2, formState: { errors: errors2 },handleSubmit: handleSubmit2} = useForm(forgotPasswordFormOptions);
    const {register: register3, formState: { errors: errors3 },handleSubmit: handleSubmit3} = useForm(verifyCodeFormOptions);
    const {register: register4, formState: { errors: errors4 },handleSubmit: handleSubmit4} = useForm(changePasswordFormOptions);

    const { errors } = formState;

    // Log In API
    function onSubmit(data) {
        login(data).then((response) => {
            response = JSON.parse(response)
            if(response.code == 1)
            {
                setItem("MAisLogin", true);
                setItem('authToken', response.data.token);
                setItem('userDetail', JSON.stringify(response.data));
                // setTimeout(() => {
                    window.location.reload();
                    navigate("/");
                // }, 2000);
            }
            else
            {
                ToastMessage({type:"error", message: response.message});
            }
        });
    }

    // Forgot Password API
    const [demail, setEmail] = useState("");
    function onSubmitForgotPassword(data) {
        forgotPassword(data).then((response) => {
            response = JSON.parse(response)
            if(response.code == 1)
            {
                ToastMessage({type:"success", message: response.message});
                setMail(response.data.email)
            }
            else
            {   
                ToastMessage({type:"error", message: response.message})
            }
        });
    }

    // Verify Code API
    const [dcode, setCode] = useState("");
    function onSubmitVerifyCode(data){
        verifyCode(data).then((response) => {
            response = JSON.parse(response);
            if(response.code == 1){
                ToastMessage({type:"success", message: response.message})
            }
            else{
                ToastMessage({type:"error", message: response.message})
            }
        });
    }

    // Chnage Password API
    const [dpass, setPass] = useState("");
    function onSubmitChangePassword(data){
        changePassword(data).then((response) => {
            response = JSON.parse(response);

            if(response.code == 1){
                localStorage.removeItem("email")
                ToastMessage({type:"success", message: response.message})
            }
            else{
                localStorage.removeItem("email")
                ToastMessage({type:"error", message: response.message})
            }
        });
    }

    const rememberFunc = () => {
        if(localStorage.getItem('email') != undefined && localStorage.getItem('password') != undefined){
            localStorage.removeItem('email');
            localStorage.removeItem('password');
        }
        else{
            localStorage.setItem('email', document.getElementById('email').value);
            localStorage.setItem('password', document.getElementById('userpassword').value);
        }
    }

    const togglePassword =()=>{
        if(passwordType==="password")
        {
         setPasswordType("text")
         return;
        }
        setPasswordType("password")
    }

    const changeLanguageHandler = (e) => {
        const languageValue = e.target.value;
        i18n.changeLanguage(languageValue);
        localStorage.setItem("language", languageValue);
        setLanguage(languageValue);
    }

  return (
    <React.Fragment>
    <Helmet>
        <title>{t('sign_in')}</title>
    </Helmet>
    <main className="main_cargo h-100">
        <div className="container-fluid h-100">
            <div className="row h-100">
                <ToastContainer />
                <Slider />
                <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 h-100 box-height" style={{ minHeight: "750px", overflowY: "auto" }}>
                    <div className="row justify-content-center align-items-center h-100">
                        <div className="col-12 col-xl-7 col-lg-7 col-md-8 col-sm-9 text-center h-100">
                            <div className="signin_between">
                                <div className='main_language'>
                                    <select className="form-select w-auto ms-auto mt-2" value={language} onChange={changeLanguageHandler} aria-label="Default select example">
                                        <option value="en">English</option>
                                        <option value="fr">Français</option>
                                    </select>
                                </div>
                                <div className="signin_logo">
                                    <img src={process.env.PUBLIC_URL +"/assets/images/icon/logo.svg"} alt="logo" />
                                </div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="signin_content">
                                        <h1 className="sign_h1">{t('hello_text')}</h1>

                                        <div class="mobile_number_input">
                                            <div class="input-group">
                                                <input type="text" class="form-control" required="" {...register('email')} id="email" defaultValue={localStorage.getItem('email') != undefined ? localStorage.getItem('email') : ""} />
                                                <span class="input-group-text" id="basic-addon1"><iconify-icon icon="mdi:alternate-email"></iconify-icon></span><label for="email">Email</label>
                                            </div>
                                            <div className="invalid-feedback">{errors.email?.message}</div>
                                        </div>

                                        <div class="mobile_number_input">
                                            <div class="input-group">
                                                <input type={passwordType} class="form-control" required="" {...register('password')} id="password" defaultValue={localStorage.getItem('password') != undefined ? localStorage.getItem('password') : ""} />
                                                <span class="input-group-text" id="basic-addon1" onClick={() => togglePassword()}>{ passwordType==="password"? <iconify-icon icon="ic:baseline-remove-red-eye"></iconify-icon> : <iconify-icon icon="mdi:eye-off"></iconify-icon> }</span><label for="password">Password</label>
                                            </div>
                                            <div className="invalid-feedback">{errors.password?.message}</div>
                                        </div>

                                        <a href="#" className="float-end forgot_color" type="button" data-bs-toggle="modal" data-bs-target="#exampleModalToggle1"><b>{t('forgot_password')}</b></a>

                                        <div className="form-check text-start agree_privacy_policy">
                                            {localStorage.getItem('email') != undefined && localStorage.getItem('password') != undefined ?
                                                <input type="checkbox" className="form-check-input" id="customControlInline" onClick={() => rememberFunc()} defaultChecked="checked" />
                                            :
                                                <input type="checkbox" className="form-check-input" id="customControlInline" onClick={() => rememberFunc()} />
                                            }
                                            <label className="form-check-label" htmlFor="flexCheckDefault">
                                                <span className="agree_privacy"> {t('remember_me')} </span>
                                            </label>
                                        </div>

                                        <div className="signin_button">
                                            <button type='submit' className="btn btn_signin w-100">{t('sign_in')}</button>
                                        </div>
                                    </div>
                                </form>

                                <div className="signin_forgot">
                                    <Link to='/select-account'>{t('signup_text')} <span>{t('sign_up')}</span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>

    <div className="modal fade" id="exampleModalToggle1" aria-hidden="true" aria-labelledby="exampleModalToggleLabel1"
        tabIndex="-1">
        <form onSubmit={handleSubmit2(onSubmitForgotPassword)} noValidate>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content text-center">
                    <div className="modal-body">
                        <div className="row justify-content-center">
                            <div className="col-xl-10">
                                <div className="modal_header_image">
                                    <img src={process.env.PUBLIC_URL +"/assets/images/icon/forgot-password.svg"} alt="forgot" />
                                </div>
                                <div className="input_box input_box_margin_2">
                                    <input type="email" className="form-control" {...register2('email')} id="exampleInputEmail1" aria-describedby="emailHelp" required="required" onChange={(e) => setEmail(e.target.value)} />
                                    <label htmlFor="exampleInputEmail1" className="form-label">{t('type_mail')}</label>
                                    <div className="invalid-feedback">{errors2.email?.message}</div>
                                </div>
                                <p className="modal_p mb-0">{t('forgot_text')}</p>
                                <div className="signin_button modal_signin_button">
                                    {demail !== "" ?
                                        <button type='submit' className="btn btn_signin w-100" data-bs-target="#exampleModalToggle" data-bs-toggle="modal"
                                        data-bs-dismiss="modal">{t('submit')} </button>
                                    :
                                        <button type='submit' className="btn btn_signin w-100">{t('submit')}</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div className="modal fade" id="exampleModalToggle" aria-hidden="true" aria-labelledby="exampleModalToggleLabel"
        tabIndex="-1">
        <form onSubmit={handleSubmit3(onSubmitVerifyCode)} noValidate>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content text-center">
                    <div className="modal-body">
                        <div className="row justify-content-center">
                            <div className="col-xl-10">
                                <div className="modal_header_image">
                                    <img src={process.env.PUBLIC_URL +"/assets/images/icon/forgot-password.svg"} alt="forgot" />
                                </div>
                                <div className="input_box input_box_margin_2">
                                    {getMail.length > 0 ? <input type="hidden" value={getMail} {...register3('email')} /> : ""}
                                    <input type="text" className="form-control" name='code' {...register3('code')} id="exampleInputEmail1" autoComplete='off' aria-describedby="emailHelp" required="required" onChange={(e) => setCode(e.target.value)} />
                                    <label htmlFor="exampleInputEmail1" className="form-label">Type Code</label>
                                    <div className="invalid-feedback">{errors3.code?.message}</div>
                                </div>
                                <p className="modal_p mb-0">{t('forgot_code_text')}</p>
                                <div className="signin_button modal_signin_button">
                                    {dcode !== "" ?
                                        <button type='submit' className="btn btn_signin w-100" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal"
                                        data-bs-dismiss="modal">{t('submit')}</button>
                                    :
                                        <button type='submit' className="btn btn_signin w-100">{t('submit')}</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div className="modal fade" id="exampleModalToggle2" aria-hidden="true" aria-labelledby="exampleModalToggleLabel2"
        tabIndex="-1">
        <form onSubmit={handleSubmit4(onSubmitChangePassword)} noValidate>
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content text-center">
                    <div className="modal-body">
                        <div className="row justify-content-center">
                            <div className="col-xl-10">
                                <div className="modal_header_image">
                                    <img src={process.env.PUBLIC_URL +"/assets/images/icon/confirm-password.svg"} alt="forgot" />
                                </div>
                                <p className="modal_p">{t('change_pass_text')}</p>
                                <div className="input_box input_box_margin_1">
                                    {getMail.length > 0 ? <input type="hidden" value={getMail} {...register4('email')} /> : ""}
                                    <input type="password" name='new_password' className="form-control" {...register4('new_password')} id="exampleInputEmail1" aria-describedby="emailHelp" required="required" onChange={(e) => setPass(e.target.value)} />
                                    <label htmlFor="exampleInputEmail1" className="form-label">{t('reset_your_pass')}</label>
                                    <div className="invalid-feedback">{errors4.new_password?.message}</div>
                                </div>
                                <div className="input_box input_box_margin_1">
                                    <input type="password" name='conform_password' className="form-control" {...register4('conform_password')} id="exampleInputEmail1" aria-describedby="emailHelp" required="required" onChange={(e) => setPass(e.target.value)} />
                                    <label htmlFor="exampleInputEmail1" className="form-label">{t('confirm_password')}</label>
                                    <div className="invalid-feedback">{errors4.conform_password?.message}</div>
                                </div>
                                <div className="signin_button modal_signin_button_2">
                                    {dpass !== "" ?
                                        <button type='submit' className="btn btn_signin w-100" data-bs-target="#exampleModalToggle2" data-bs-toggle="modal"
                                        data-bs-dismiss="modal">{t('submit')}</button>
                                    :
                                        <button type='submit' className="btn btn_signin w-100">{t('submit')}</button>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
<Helmet>
    <script>
        
        {
            `
            jQuery("#carousel").owlCarousel({
                autoplay: true,
                loop: true,
                touchDrag: true,
                mouseDrag: true,
                items: 1,
                responsiveClass: true,
                autoplayTimeout: 7000,
                smartSpeed: 800,
                nav: false,
                dots: true,
                responsive: {
                    0: {
                        items: 1
                    },
            
                    468: {
                        items: 1
                    },
            
                    767: {
                        items: 1
                    },
            
                    991: {
                        items: 1
                    },
            
                    1200: {
                        items: 1
                    },
            
                    1400: {
                        items: 1
                    }
                }
            });

            

            // if (isEmpty($(".invalid-feedback"))) {
            //     console.log("]====================================>>>")
            //     $('#btn1').on('click', function () {
            //         $('#exampleModalToggle').modal('show');
            //     })
            // }
            `
        }
    </script>
</Helmet>
</React.Fragment>
   
  )
}

export default Login