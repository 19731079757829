import React, { useState, useEffect } from 'react'
import {Helmet} from "react-helmet";
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { getCountryCode, signUp } from '../../../api/apiHandler';
import useStorage from "../../../hooks/useStorage.ts";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ToastMessage from '../../Toast';
import Slider from '../Slider/Slider';
import { useTranslation } from "react-i18next";
import Swal from 'sweetalert2';
import PhoneInput,{ isValidPhoneNumber }  from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

const Signup = () => {
    let[data, setData] = useState({
        country_code: ""
    })

    let[isCountryData, setIsCountryData] = useState(null);
    const { t, i18n } = useTranslation();
    var [language, setLanguage] = useState(localStorage.getItem("language") === "fr" ? "fr" : "en");
    
    const [value, setValue] = useState('');
    const [value1, setValue1] = useState('');
    const [num, setNum] = useState('');
    let [mbValidate,setMbValidate] = useState(null)
    let validation = num ? 'true' : 'false'
    let possible = num ? 'true' : 'false'

    const handleChange4 = event => {
        // const result = event.replace(/\D/g, '');
        setNum(event);
    };

    const handleChange6 = event => {
        setValue1(event);
    };

    let handleChange = (e) => {
        const value = e.target.value;
        setData({
            ...data,
            [e.target.name]: value
        });
    }

    const { setItem,getItem } = useStorage();

    useEffect(() => {
        countryData({});
    }, []);

    function countryData(data){
        getCountryCode(data).then((response) => {
            response = JSON.parse(response);
            if(response.code == 1){
                setIsCountryData(response.data);
            }
            else{
                console.log("null");
            }
        });
    }

    const navigate = useNavigate();
    const validationSchema = Yup.object().shape({
        company_id: Yup.string(),
        first_name: Yup.string()
            .required('First name is required')
            .min(2, 'First name must be at least 2 characters')
            .matches('^[a-zA-Z]', 'Only alphabets are allowed for this field'),
        last_name : Yup.string()
            .required('Last name is required')
            .matches('^[a-zA-Z]', 'Only alphabets are allowed for this field')
            .min(2, 'Last name must be at least 2 characters'),
        email : Yup.string()
            .email('Email address is invalid')
            .required('Email address is required'),
        // country_code: Yup.string(),
        phone_number : Yup.string()
            .typeError('You must specify a number')
            .required('Phone number is required')
            .min(6, 'Phone Number must be at least some characters'),
        office_phone : Yup.string()
            .when("phone_number", (phone, schema) => {
                if(phone)
                    return schema
                return schema.required("Office Number is required.").typeError('You must specify a number')
            }),
        password: Yup.string()
            .required('Password is required')
            .min(6, 'Password must be at least 6 characters')
            .matches('^(?=.*[^\\s-])', "Please Check your Password"),
        confirm_password: Yup.string()
            .required('Confirm password is required')
            .min(6, 'Password must be at least 6 characters')
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
            .matches('^(?=.*[^\\s-])', "Please Check your Password"),
        role : Yup.string()
            .required('Role is required'),
        is_privacy_policy: Yup.boolean()
            .oneOf([true],'Please check privacy policy')
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
   
        
    const onSubmit = (data) => {
        if(validation === "true" && possible === "true"){
            setMbValidate(true)
            if(getItem("user_role") === "Agent"){
                data.user_role = "Agent"
                data.networks = getItem("networks")
            }
            else{
                data.user_role = "Client"
                localStorage.removeItem("networks")
            }
            setItem('userDetail', JSON.stringify(data));
            
            var a = data.phone_number;
            data.country_code= a.split(" ")[0];
            data.phone = a.replace(a.split(" ")[0],"").replace(/ /g, '');
            var b = data.office_phone;
            data.office_country_code= b.split(" ")[0];
            data.office_phone = b.replace(b.split(" ")[0],"").replace(/ /g, '');
            signUp(data).then((response) => {
                response = JSON.parse(response)
                if(response.code == 1)
                {
                    // setItem('authToken', response.data.token);
                    // setItem('email', response.data.email);
                    // setItem('phone', response.data.phone);
                    localStorage.removeItem("companyData")
                    localStorage.removeItem("company")
                    Swal.fire({
                        icon: 'success',
                        html:
                        '<b>Soon you get Mail from Administration</b>',
                        focusConfirm: false,
                        confirmButtonText:
                        '<i class="fa fa-thumbs-up"></i> Great!'
                    }).then(() => {
                        setTimeout(() => {
                            navigate('/');
                        }, 500);
                    });
                }
                else
                {
                    ToastMessage({type:"error", message: response.message})
                }
            });
        }
    }

    const changeLanguageHandler = (e) => {
        const languageValue = e.target.value;
        i18n.changeLanguage(languageValue);
        localStorage.setItem("language", languageValue);
        setLanguage(languageValue);
    }

    // if (isCountryData == null) return <></>

    return (
        <React.Fragment>
            <Helmet>
                <title>{t('sign_up')}</title>
            </Helmet>
            <main className="main_cargo h-100">
                <div className="container-fluid h-100">
                    <div className="row h-100">
                        <ToastContainer />
                        <Slider />
                        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 h-100 box-height" style={{ minHeight: "750px", overflowY: "auto" }}>
                            <div className="row justify-content-center align-items-center h-100">
                                <div className="col-12 col-xl-6 col-lg-6 col-md-8 col-sm-9 text-center h-100">
                                    <div className="signin_between">
                                        <div className='main_language'>
                                            <select className="form-select w-auto ms-auto mt-2" value={language} onChange={changeLanguageHandler} aria-label="Default select example">
                                                <option value="en">English</option>
                                                <option value="fr">Français</option>
                                            </select>
                                        </div>
                                        <div className="signin_logo">
                                            <img src={process.env.PUBLIC_URL +"/assets/images/icon/logo.svg"} alt="logo" />
                                        </div>

                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="signin_content">
                                                <h1 className="sign_h1">{t('sign_up')}</h1>
                                                <input type="hidden" {...register('company_id')} value={localStorage.getItem("company")} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />

                                                <div className="input_box input_box_margin mt-3">
                                                    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" {...register('role')} autoComplete="off" />
                                                    <label htmlFor="exampleInputEmail1" className="form-label">{t('job_title')}</label>
                                                    {/* <FormControl sx={{ m: 1, width: '25ch' }} variant="outlined">
                                                        <InputLabel htmlFor="outlined-adornment-role">{t('job_title')}</InputLabel>
                                                        <OutlinedInput
                                                            id="outlined-adornment-role"
                                                            {...register('role')}
                                                            type="text"
                                                            style={{borderRadius: '15px', width: "435px" }}
                                                            label="role"
                                                            autoComplete='off'
                                                        />
                                                    </FormControl> */}

                                                    <div className='invalid-feedback'>{errors.role?.message}</div>
                                                </div>

                                                <div className="input_box input_box_margin_1">
                                                    <input type="text" {...register('first_name')} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                                    <label htmlFor="exampleInputEmail1" className="form-label">{t('first_name')}</label>
                                                    <div className="invalid-feedback">{errors.first_name?.message}</div>
                                                </div>

                                                <div className="input_box input_box_margin_1">
                                                    <input type="text"  {...register('last_name')} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  />
                                                    <label htmlFor="exampleInputEmail1" className="form-label">{t('last_name')}</label>
                                                    <div className="invalid-feedback">{errors.last_name?.message}</div>
                                                </div>

                                                {/* <div className="mobile_input input-group input_box_margin_1 mb-4">
                                                    <select className="btn dropdown-toggle" name="country_code" {...register('country_code')} onChange={handleChange} style={{width: "150px", textAlign: "left"}}>
                                                        {isCountryData && isCountryData.map((item, key) => {
                                                            return(
                                                                <option className="dropdown-item" id={item.dial_code} value={item.dial_code}>{item.dial_code} {item.country_name}</option>
                                                            );
                                                        })}
                                                    </select>
                                                    <input type="text" className="form-control" placeholder={t('mobile_no')} {...register('phone')} value={value} aria-label="Text input with dropdown button" onChange={handleChange4} />
                                                </div>
                                                <div className="invalid-feedback mb-4" style={{ marginTop: "-16px" }}>{errors.phone?.message}</div> */}

                                                <div style={{textAlign:"left", alignItems: "left"}}>
                                                    <label htmlFor="exampleInputEmail1" style={{pointerEvents: "none", color: "rgb(16 64 29 / 50%)", fontSize: "12px", transition: "0.5s"}}>{t('mobile_no')}</label>
                                                    <PhoneInput
                                                        international
                                                        defaultCountry='FR'
                                                        {...register('phone_number')}
                                                        name="phone_number"
                                                        style={{padding: "16px", gap: "8px", outline: "none", color: "rgba(53, 70, 101, 0.5)", fontSize: "16px", border: "1px solid #70A7A4", display: "flex", borderRadius: "8px"}}
                                                        value={num}
                                                        onChange={handleChange4}
                                                        error={num ? (isValidPhoneNumber(num) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                                    />
                                                    <div className="invalid-feedback">{errors.phone_number?.message}</div>
                                                    <div className="invalid-feedback">{ mbValidate == false ? "Please enter valid mobile number." :"" }</div>
                                                </div>

                                                <div style={{textAlign:"left", alignItems: "left", marginTop: "20px"}}>
                                                    {/* <input type="text" {...register('office_phone')} className="form-control" id="exampleInputEmail1" 
                                                    style={{marginTop: "20px"}} 
                                                    value={value1} aria-describedby="emailHelp" onChange={handleChange6} />
                                                    <label htmlFor="exampleInputEmail1" className="form-label" 
                                                    style={{marginTop: "20px"}}
                                                    >{t('office_phone')}</label> */}
                                                    <label htmlFor="exampleInputEmail1" style={{pointerEvents: "none", color: "rgb(16 64 29 / 50%)", fontSize: "12px", transition: "0.5s"}}>{t('office_phone')}</label>
                                                    <PhoneInput
                                                        international
                                                        defaultCountry='FR'
                                                        {...register('office_phone')}
                                                        name="office_phone"
                                                        style={{padding: "16px", gap: "8px", outline: "none", color: "rgba(53, 70, 101, 0.5)", fontSize: "16px", border: "1px solid #70A7A4", display: "flex", borderRadius: "8px"}}
                                                        value={value1}
                                                        onChange={handleChange6}
                                                        error={value1 ? (isValidPhoneNumber(value1) ? undefined : 'Invalid phone number') : 'Phone number required'}
                                                    />
                                                    <div className="invalid-feedback">{errors.office_phone?.message}</div>
                                                </div>
                                                
                                                <div className="input_box input_box_margin_1">
                                                    <input type="email" {...register('email')} style={{marginTop: "20px"}} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                                    <label htmlFor="exampleInputEmail1" style={{marginTop: "20px"}} className="form-label">{t('enter_email')}</label>
                                                    <div className="invalid-feedback">{errors.email?.message}</div>
                                                </div>

                                                <div className="input_box input_box_margin_1">
                                                    <input type="password" {...register('password')}  className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                                                    <label htmlFor="exampleInputEmail1" className="form-label">{t('create_password')}</label>
                                                    <div className="invalid-feedback">{errors.password?.message}</div>
                                                </div>

                                                <div className="input_box input_box_margin_1">
                                                    <input type="password" {...register('confirm_password')}  className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  />
                                                    <label htmlFor="exampleInputEmail1" className="form-label">{t('confirm_password')}</label>
                                                    <div className="invalid-feedback">{errors.confirm_password?.message}</div>
                                                </div>

                                                <div className="form-check text-start agree_privacy_policy">
                                                    <input className="form-check-input" {...register('is_privacy_policy')}  type="checkbox" value="" id="flexCheckDefault" />
                                                    <label className="form-check-label" htmlFor="flexCheckDefault">
                                                        <span className="agree_privacy">{t('privacy_text')} <span>{t('privacy_policy')}</span> </span>
                                                    </label>
                                                    <div className="invalid-feedback">{errors.is_privacy_policy?.message}</div>
                                                </div>

                                                <div className="signin_button signin_button_next">
                                                    <div className='row'>
                                                        <div className='col-sm-6'>
                                                            <Link to="/complete-profile" className='btn btn_signin w-100'>{t('back')}</Link>
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <button type='submit' className='btn btn_signin w-100'>{t('sign_up')}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        
            <Helmet>
                <script>
                    {
                        `
                        $(function(){
                            $("#1").click(function () {
                                $("#opt").text($(this).text());
                            });
                            $("#2").click(function () {
                                $("#opt").text($(this).text());
                            });
                        });

                        console.log('signup')
                        jQuery("#carousel").owlCarousel({
                            autoplay: true,
                            loop: true,
                            touchDrag: true,
                            mouseDrag: true,
                            items: 1,
                            responsiveClass: true,
                            autoplayTimeout: 7000,
                            smartSpeed: 800,
                            nav: false,
                            dots: true,
                            responsive: {
                                0: {
                                    items: 1
                                },
                        
                                468: {
                                    items: 1
                                },
                        
                                767: {
                                    items: 1
                                },
                        
                                991: {
                                    items: 1
                                },
                        
                                1200: {
                                    items: 1
                                },
                        
                                1400: {
                                    items: 1
                                }
                            }
                        });

                        ////text height////
                        var highestBox = 0;
                            $('.box-height').each(function(){  
                                    if($(this).height() > highestBox){  
                                    highestBox = $(this).height();  
                            }
                        });    
                        $('.box-height').height(highestBox);
                        `
                    }
                </script>
            </Helmet>

        </React.Fragment>
    )
}

export default Signup