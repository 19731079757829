import React, {useEffect, useState} from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { getActiveShipment, getAlertNo, getPendingQuotes, getModuleCount } from '../../api/apiHandler';
import Sidebar from '../../components/customerComponents/Sidebar/Sidebar';
import Topbar from '../../components/customerComponents/Topbar/Topbar';
import { useTranslation } from "react-i18next";

export default function Home() {
    const commonProps = {title: "home"}

    var [quoteData, setPendingQuoteData] = useState([]);
    var [shipmentData, setActiveShipmentData] = useState([]);
    var [alertData, setAlerttData] = useState([]);
    const { t, i18n } = useTranslation();

    function getTotalNo() {
        getModuleCount({}).then((response) => {
            response = JSON.parse(response);
            if(response.code == 1){
                setPendingQuoteData(response.data[0].Quotes);
                setActiveShipmentData(response.data[1].Shipments);
                setAlerttData(response.data[2].Alerts)
            }
            else{
                console.log("null");
            }
        });
    }
    
    useEffect(() => {
        getTotalNo();
    }, []);


    return (
        <>
            <Helmet>
                <title>{t('dashboard')}</title>
            </Helmet>
            <main className="dash_b h-100">
                <div className="container-fluid h-100">
                    <div id="wrapper">
                        <Sidebar commonProps={commonProps}/>
                        <section id="content-wrapper" className="h-100">
                            <Topbar />

                            <section className="dashboard_area">
                                <div className="row">
                                    <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                        <div className="box_shd dash_1">
                                            <div className="gross_position">
                                                <iconify-icon icon="mdi:truck-fast" style={{fontSize: "30px"}}></iconify-icon>
                                                {/* <img src="assets/images/icon/Asset 5.svg" className="img-fluid" alt="transport" /> */}
                                            </div>
                                            <span className="green_5">{shipmentData}</span>
                                            <span>Active {t('shipment')}</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                        <div className="box_shd dash_1">
                                            <div className="gross_position">
                                                <iconify-icon icon="mdi:clock-time-five-outline" style={{fontSize: "30px"}}></iconify-icon>
                                                {/* <img src="assets/images/icon/Asset 3.svg" className="img-fluid" alt="clock" /> */}
                                            </div>
                                            <span className="orange_3">{quoteData}</span>
                                            <span>{t('pending')} {t('quotes')}</span>
                                        </div>
                                    </div>
                                    <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                        <Link to="/alert">
                                            <div className="box_shd dash_1">
                                                <div className="gross_position">
                                                    <iconify-icon icon="mdi:shield-alert-outline" style={{fontSize: "30px", color: "black"}}></iconify-icon>
                                                    {/* <img src="assets/images/icon/Asset 4.svg" className="img-fluid" alt="alert" /> */}
                                                </div>
                                                <span className="red_1">{alertData}</span>
                                                <span>{t('alert')}</span>
                                            </div>
                                        </Link>
                                    </div>
                                    <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                        <div className="box_shd dash_1">
                                            <div className="gross_position">
                                                <iconify-icon icon="mdi:calendar-clock" style={{fontSize: "30px"}}></iconify-icon>
                                                {/* <img src="assets/images/icon/videotime.svg" className="img-fluid" alt="videotime" /> */}
                                            </div>
                                            <span className="red_1">5456</span>
                                            <span>{t('amount_past_due_date')}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="dash_maps">
                                    <div className="row map_margin">
                                        <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-6">
                                            <div className="world_map">
                                                <iframe
                                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d54162954.198218316!2d64.76028029999998!3d34.04786299999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3663f18a24cbe857%3A0xa9416bfcd3a0f459!2sAsia!5e0!3m2!1sen!2sin!4v1665657494460!5m2!1sen!2sin"
                                                    title="map" width="100%" height="100%" style={{ border:0 }}
                                                    loading="lazy"></iframe>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                            <div className="all_tasks">
                                                <div className="task_heading">
                                                    <Link to="/alert">
                                                        <h6 className="mb-0 al_h6">{t('tasks')} <span className="task_4">6</span> </h6>
                                                    </Link>
                                                    <Link to="/alert"> <u> {t('see_all')}</u></Link>
                                                </div>
                                                <div className="task_details">
                                                    <div className="task_text">
                                                        <div className="billing_icon task_text_logo">
                                                            <i className="fa-solid fa-credit-card"></i>
                                                        </div>
                                                        <div className="task_text_text">
                                                            <p>Billing</p>
                                                            <span>3 invoices ready for review</span>
                                                        </div>
                                                    </div>
                                                    <div className="task_text">
                                                        <div className="billing_icon task_text_logo">
                                                            <i className="fa-solid fa-credit-card"></i>
                                                        </div>
                                                        <div className="task_text_text">
                                                            <p>Billing</p>
                                                            <span>1 invoices ready for review</span>
                                                        </div>
                                                    </div>
                                                    <div className="t_t_1">
                                                        <div className="task_text_1">
                                                            <div className="billing_icon task_text_logo">
                                                                <i className="fa-regular fa-message"></i>
                                                            </div>
                                                            <div className="task_text_text">
                                                                <p>FLEX-625004 • <span>Return shipment </span> </p>
                                                                <div className="invoice_text">
                                                                    <span> <i className="fa-solid fa-industry"></i> 1 invoices ready
                                                                        for
                                                                        review </span>
                                                                </div>
                                                            </div>
                                                            <div className="ms-auto">
                                                                <a href="/#">
                                                                    <i className="fa-regular fa-bookmark"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="float-end">
                                                            <span className="sep_6">Sep 23rd</span>
                                                        </div>
                                                    </div>
                                                    <div className="t_t_1">
                                                        <div className="task_text_1">
                                                            <div className="billing_icon task_text_logo">
                                                                <i className="fa-regular fa-message"></i>
                                                            </div>
                                                            <div className="task_text_text">
                                                                <p>FLEX-624123 • <span>PO 1234</span> </p>
                                                                <div className="invoice_text_2">
                                                                    <span>PO: 4567</span>
                                                                    <span>Type: Direct to customer</span>
                                                                </div>
                                                            </div>
                                                            <div className="ms-auto">
                                                                <a href="/#">
                                                                    <i className="fa-regular fa-bookmark"></i>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <span className="ready_12"> <i className="fa-solid fa-industry"></i> 1
                                                                invoices ready
                                                                for
                                                                review </span>
                                                            <p className="sep_6 mb-0 ms-auto">Sep 24rd</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                            <div className="all_tasks h-auto">
                                                <div className="task_heading">
                                                    <h6 className="mb-0">{t('quotes')} <Link to="/requestquotes4"
                                                            className="btn btn_q_new">{t('new')}</Link> </h6>
                                                    <Link to="/quotes"> <u>{t('see_all')}</u> </Link>
                                                </div>
                                                <div className="task_details">
                                                    <div className="t_t_1">
                                                        <div className="task_text_1">
                                                            <div className="task_text_logo">
                                                                <img src={process.env.PUBLIC_URL +"/assets/images/icon/air-white.svg"} alt="air" />
                                                            </div>
                                                            <div className="task_text_text">
                                                                <p>Import FOB Shanghai • 1*20' DGR • quote</p>
                                                                <div className="invoice_text_3">
                                                                    <span>BGEO</span>
                                                                </div>
                                                            </div>
                                                            <div className="ms-auto">
                                                                <a href="/#">
                                                                    <span className="table_respond shan_1">Responded</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <span className="ready_12">Shanghai - lorem ipsum dolor sit</span>
                                                            <p className="sep_6 mb-0 ms-auto">Sep 24rd</p>
                                                        </div>
                                                    </div>
                                                    <div className="t_t_1">
                                                        <div className="task_text_1">
                                                            <div className="task_text_logo">
                                                                <img src={process.env.PUBLIC_URL +"/assets/images/icon/air-white.svg"} alt="air" />
                                                            </div>
                                                            <div className="task_text_text">
                                                                <p>Import FOB Shanghai • 1*20' DGR • quote</p>
                                                                <div className="invoice_text_3">
                                                                    <span>BGEO</span>
                                                                </div>
                                                            </div>
                                                            <div className="ms-auto">
                                                                <a href="/#">
                                                                    <span className="table_pending shan_1">Pending</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <span className="ready_12">Shanghai - lorem ipsum dolor sit</span>
                                                            <p className="sep_6 mb-0 ms-auto">Sep 24rd</p>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                            <div className="all_tasks h-auto">
                                                <div className="task_heading">
                                                    <h6 className="mb-0">{t('shipment')}s </h6>
                                                    <Link to="/shipment"> <u> {t('see_all')}</u></Link>
                                                </div>
                                                <div className="task_details">
                                                    <div className="t_t_1">
                                                        <div className="task_text_1">
                                                            <div className="task_text_text">
                                                                <p>Import FOB Shanghai 1*20' DGR quote</p>
                                                                <div className="invoice_text_3">
                                                                    <span>BGEO</span>
                                                                </div>
                                                            </div>
                                                            <div className="ms-auto">
                                                                <a href="/#">
                                                                    <span className="table_pending shan_1">Shipped</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <span className="ready_12 ms-0"><i
                                                                    className="fa-solid fa-industry me-1"></i>Shanghai
                                                                - lorem ipsum dolor sit</span>
                                                        </div>
                                                        <div className="order_shipped ship_grey">
                                                            <div className="order-tracking completed">
                                                                <span className="is-complete"><img
                                                                        src={process.env.PUBLIC_URL +"/assets/images/icon/building.svg"} alt="" /></span>

                                                            </div>
                                                            <div className="order-tracking completed">
                                                                <span className="is-complete"><img
                                                                        src={process.env.PUBLIC_URL +"/assets/images/icon/truck-white.svg"} alt="" /></span>

                                                            </div>
                                                            <div className="order-tracking completed">
                                                                <span className="is-complete"><img
                                                                        src={process.env.PUBLIC_URL +"/assets/images/icon/air-white.svg"} alt="" /></span>

                                                            </div>
                                                            <div className="order-tracking completed">
                                                                <span className="is-complete"><img
                                                                        src={process.env.PUBLIC_URL +"/assets/images/icon/truck-white.svg"} alt="" /></span>

                                                            </div>
                                                        </div>
                                                        <p className="mt-2 font_12">Last update : In transit - 05/02/22 12:54</p>
                                                    </div>
                                                    <div className="t_t_1">
                                                        <div className="task_text_1">
                                                            <div className="task_text_text">
                                                                <p>Import FOB Shanghai 1*20' DGR quote</p>
                                                                <div className="invoice_text_3">
                                                                    <span>BGEO</span>
                                                                </div>
                                                            </div>
                                                            <div className="ms-auto">
                                                                <a href="/#">
                                                                    <span className="table_respond shan_1">Ordered</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <span className="ready_12 ms-0"><i
                                                                    className="fa-solid fa-industry me-1"></i>Shanghai - lorem ipsum
                                                                dolor sit</span>
                                                        </div>
                                                        <div className="order_shipped ship_grey">
                                                            <div className="order-tracking completed">
                                                                <span className="is-complete"><img
                                                                        src={process.env.PUBLIC_URL +"/assets/images/icon/building.svg"} alt="" /></span>

                                                            </div>
                                                            <div className="order-tracking completed">
                                                                <span className="is-complete"><img
                                                                        src={process.env.PUBLIC_URL +"/assets/images/icon/truck-white.svg"} alt="" /></span>

                                                            </div>
                                                            <div className="order-tracking completed">
                                                                <span className="is-complete"><img
                                                                        src={process.env.PUBLIC_URL +"/assets/images/icon/air-white.svg"} alt="" /></span>

                                                            </div>
                                                            <div className="order-tracking completed">
                                                                <span className="is-complete"><img
                                                                        src={process.env.PUBLIC_URL +"/assets/images/icon/truck-white.svg"} alt="" /></span>

                                                            </div>
                                                        </div>
                                                        <p className="mt-2 font_12">Last update : In transit - 05/02/22 12:54</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                            <div className="all_tasks h-auto">
                                                <div className="task_heading">
                                                    <h6 className="mb-0">{t('invoice')} </h6>
                                                    <Link to="/invoice"> <u> {t('see_all')}</u></Link>
                                                </div>
                                                <div className="task_details">
                                                    <div className="t_t_1">
                                                        <div className="task_text_1">
                                                            <div className="task_text_logo">
                                                                <img src={process.env.PUBLIC_URL +"/assets/images/icon/air-white.svg"} alt="air" />
                                                            </div>
                                                            <div className="task_text_text">
                                                                <p>Import FOB Shanghai (Air)</p>
                                                            </div>
                                                            <div className="ms-auto">
                                                                <a href="/#">
                                                                    <span className="table_respond shan_1">paid</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex">
                                                            <span className="ready_12">BGEO</span>
                                                            <p className="sep_6 mb-0 ms-auto">Sep 24rd</p>
                                                            </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </section>
                    </div>
                </div>
            </main>

            <Helmet>
                <script>
                    {
                        `
                        function showTime() {
                            let time = new Date();
                            let hour = time.getHours();
                            let min = time.getMinutes();
                            let sec = time.getSeconds();
                            // let am_pm = "AM";
                        
                            // if (hour > 12) {
                            //     hour -= 12;
                            //     am_pm = "PM";
                            // }
                            // if (hour == 0) {
                            //     hr = 12;
                            //     am_pm = "AM";
                            // }
                            // console.log(am_pm)
                        
                            hour = hour < 10 ? "0" + hour : hour;
                            min = min < 10 ? "0" + min : min;
                            sec = sec < 10 ? "0" + sec : sec;
                        
                            let currentTime = hour + ":" + min + ":" + sec;
                        
                            document.getElementById("clock").innerHTML = currentTime;
                        }
                        
                        showTime();
                        `
                    }
                </script>
            </Helmet>
        </>
    )
}
