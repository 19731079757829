
export function loginRedirectCall(){
    let path= window.location.protocol+"//"+window.location.host+"/" 
//    window.open(path,'mywindow').focus()
console.log(path)
    window.location.href = path;
  }

export function homeRedirectCall(){
    let path= window.location.protocol+"//"+window.location.host+"/"
//    window.open(path,'mywindow').focus()
    console.log(path)
    window.location.href = path;  
}

export function logOutRedirectCall(){
  localStorage.removeItem("MAisLogin");
  localStorage.removeItem("MAid");
  localStorage.removeItem("authToken");
  localStorage.removeItem("userDetail");

  loginRedirectCall()
}

export function loginRedirectCallWithDataStore(data){
  localStorage.setItem("MAisLogin",true);
  // localStorage.setItem("MAid",data.data._id);
  // localStorage.setItem("MAfname",data.data.first_name);
  // localStorage.setItem("MAlname",data.data.last_name);
  // localStorage.setItem("MAtoken",data.data.token);
  // localStorage.setItem("MAlatitude",data.data.latitude);
  // localStorage.setItem("MAlongitude",data.data.longitude);
  homeRedirectCall()
}

export function signupRedirectCallWithDataStore(data){
  // localStorage.setItem("MAisLogin",true);
  localStorage.setItem("MAid",data.data._id);
  localStorage.setItem("MAfname",data.data.first_name);
  localStorage.setItem("MAlname",data.data.last_name);
  localStorage.setItem("MAtoken",data.data.token);
  localStorage.setItem("MAlatitude",data.data.latitude);
  localStorage.setItem("MAlongitude",data.data.longitude);
}


  