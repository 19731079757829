import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Sidebar from '../../components/customerComponents/Sidebar/Sidebar';
import Topbar from '../../components/customerComponents/Topbar/Topbar';
import { Helmet } from 'react-helmet';
import { addQuotes } from '../../api/apiHandler';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function RequestQuotes() {
    const commonProps = {title: "quotes"}
    const [inputFields, setInputFields] = useState([
        { pieces: '', weight: '', volume: '', chargeable_weight: '', type: '' }
    ]);

    const addFields = (() => {
        let newfield = { pieces: '', weight: '', volume: '', chargeable_weight: '', type: '' }
        setInputFields([...inputFields, newfield])
        // setTempData([...tempData, newfield])
    })

    const [getIncoterm, setIncoterm] = useState([]);
    const [getArr, setArr] = useState([]);
    console.log("---------------------------------------------------------->>>", getIncoterm)

    const validationQuoteSchema = Yup.object().shape({
        your_reference: Yup.string()
            .min(3, 'Reference must be at least 3 characters')
            .matches('[a-zA-Z]', 'Only alphabets are allowed htmlFor this field'),
        transport_mode : Yup.string(),
        incoterm : Yup.string(),
        //     .oneOf(cargo_types),
        // incoterm : Yup.array()
        //     .oneOf(incoterm_types)
        //     .required('incoterm is required'),
        pick_address: Yup.string()
            .required('Pick Address is required'),
        airport_departure: Yup.string()
            .required('Departure of Airport is required'),
        delivery_address: Yup.string()
            .required('Delivery Address is required'),
        airport_arrival: Yup.string()
            .required('Arrival of Airport is required'),
    });

    const arrayValidation = Yup.object().shape({
        cargo: Yup.array()
            .of(
                Yup.object().shape({
                    pieces: Yup.number().required('Piece number is required'),
                    weight: Yup.number().required('Weight is required'),
                    volume: Yup.number().required('Volume is required'),
                    chargeable_weight: Yup.number().required('Charge Weight is required'),
                    type: Yup.string(),
                    desc: Yup.string().required('Goods Description is required'),
                    is_insurance: Yup.string(),
                    is_dangerous: Yup.string(),
                    is_temperature_controlled: Yup.string(),
                    remark: Yup.string()
                    //.min(3, 'Interest name must be at least 3 characters')
                    //.matches(/^(?=[A-Za-z0-9_@./#&+-/^/%/!/$/*/(/)/~])([A-Za-z0-9\s_@./#&+-/^/%/!/$/*/(/)/~]*)(?<=[A-Za-z0-9_@./#&+-/^/%/!/$/*/(/)/~])$/g, 'This field cannot contain blankspaces'),
                })
            )
    });

    const ArrayOfCargoSchema = Yup.array().of(arrayValidation);

    const validationSchema = Yup.lazy((value) => {
        if (Array.isArray(value)) {
            return ArrayOfCargoSchema;
        }
        return arrayValidation;
    });
    
    const formOptions = { resolver: yupResolver(validationQuoteSchema) };
    const formArrayOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { register: register2, formState: { errors: errors2 }, handleSubmit: handleSubmit2 } = useForm(formArrayOptions);

    const { errors } = formState;

    const onCargoSubmit = (data) => {
        console.log("==-=-=-=-=-=-=-=-=>>>>", getArr)
        getArr.push(data)
        setArr(getArr)
    }

    const onSubmit = (data) => {
        console.log("======>>>", getArr)
        data.cargo = getArr;
        console.log("======>>>", data)
        addQuotes(data).then((response) => {
            response = JSON.parse(response);
            if(response.code == 1){
                toast.success(response.message, {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
            else{
                toast.error(response.message, {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        })
    }

    return (
        <>
            <Helmet>
                <title>Request Quotes Air - 2</title>
            </Helmet>
            <main className="dash_b">
                <div className="container-fluid">
                    <div id="wrapper">
                        <Sidebar commonProps={commonProps} />

                        <section id="content-wrapper">
                            <Topbar />

                            <ToastContainer />
                            <section className="dashboard_area">
                                <div className="main_quote_request">
                                    <form id="basic_details" onSubmit={handleSubmit(onSubmit)}></form>
                                    <form id="cargo_details" onSubmit={handleSubmit2(onCargoSubmit)}></form>
                                        <div className="row">
                                            <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <h5>Basic Details:</h5>
                                            </div>
                                            <div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12">
                                                <div className="request_label">
                                                    <div className="input_box input_box_margin">
                                                        <input type="text" className="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" name='your_reference' required="required" form="basic_details" {...register('your_reference')} />
                                                        <label htmlFor="exampleInputEmail1" className="form-label">Your reference
                                                            (optional)</label>
                                                        <div className="invalid-feedback">{errors.your_reference?.message}</div>
                                                    </div>
                                                </div>
                                                <input type="hidden" form='basic_details' value="Air" {...register("transport_mode")} />

                                                <p className="font_16">Transport mode</p>
                                                {/* <div className="row">
                                                    <div className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <div className="trans_button">
                                                            <a href="/#" className="btn btn_signin w-100"> <img
                                                                    src="assets/images/icon/air-white.svg" className="me-1" alt="plane" />
                                                                Air</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <div className="trans_button">
                                                            <a href="/#" className="btn btn_signin w-100"><img
                                                                    src="assets/images/icon/sea-white.svg" className="me-1"
                                                                    alt="boat" />Sea</a>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <div className="trans_button">
                                                            <a href="/#" className="btn btn_signin w-100"><img
                                                                    src="assets/images/icon/truck-white.svg" className="me-1"
                                                                    alt="truck" />Other</a>
                                                        </div>
                                                    </div>
                                                </div> */}

                                                <div className="row">
                                                    <div className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <div className="quotes_drop_1">
                                                            <Link className="dropdown-item btn btn_quotes_active" to="/requestquotes"><img
                                                                    src="assets/images/icon/air-white.svg" alt="plane" /> Air </Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <div className="quotes_drop_1">
                                                            <Link className="dropdown-item btn btn_quotes" to="/requestquotessea"><img
                                                                    src="assets/images/icon/sea-blue.svg" alt="ship" /> Sea </Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <div className="quotes_drop_1">
                                                            <Link className="dropdown-item btn btn_quotes" to="/requestquotesothers"><img
                                                                    src="assets/images/icon/truck-blue.svg" alt="truck" /> Other </Link>
                                                        </div>
                                                    </div>
                                                </div>

                                                <p className="font_16">Incoterm <a href="/#" data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"><i
                                                            className="fa-solid fa-circle-info incoterm_icon"></i></a> </p>
                                                <div id='main_incoterm' className="main_incoterm">
                                                    <a className="btn btn_incoterm btn_quotes_incoterm_active 1" onClick={() => setIncoterm("EXW")}>EXW</a>
                                                    <a className="btn btn_incoterm 2" onClick={() => setIncoterm("FOB")}>FOB</a>
                                                    <a className="btn btn_incoterm 3" onClick={() => setIncoterm("CFR")}>CFR</a>
                                                    <a className="btn btn_incoterm 4" onClick={() => setIncoterm("DDP")}>DDP</a>
                                                    <div className="dropdown w-100">
                                                        <button className="btn btn_incoterm dropdown-toggle" type="button"
                                                            id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                            aria-expanded="false">
                                                            Others
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li><a className="dropdown-item" href="/#">Action</a></li>
                                                            <li><a className="dropdown-item" href="/#">Another action</a></li>
                                                            <li><a className="dropdown-item" href="/#">Something else here</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <input type="hidden" form='basic_details' value={getIncoterm} {...register("incoterm")} />

                                        <div className="request_hr"></div>
                                        <div className="row">
                                            <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <h5>Itinerary:</h5>
                                            </div>
                                            <div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12">
                                                <div className="row">
                                                    <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                        <div className="request_label">
                                                            <div className="input_box input_box_margin">
                                                                <input type="text" className="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp" required="required" form='basic_details' {...register('pick_address')} />
                                                                <label htmlFor="exampleInputEmail1" className="form-label">Pick up address</label>
                                                                <div className="invalid-feedback">{errors.pick_address?.message}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                        <div className="request_label">
                                                            <div className="input_box input_box_margin">
                                                                <input type="text" className="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp" required="required" form='basic_details' {...register('delivery_address')} />
                                                                <label htmlFor="exampleInputEmail1"
                                                                    className="form-label">Delivery address</label>
                                                                <div className="invalid-feedback">{errors.delivery_address?.message}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                        <div className="request_label">
                                                            <div className="input_box input_box_margin">
                                                                <input type="text" className="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp" required="required" form='basic_details' {...register('airport_departure')} />
                                                                <label htmlFor="exampleInputEmail1" className="form-label">Airport of departure</label>
                                                                <div className="invalid-feedback">{errors.airport_departure?.message}</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                        <div className="request_label">
                                                            <div className="input_box input_box_margin">
                                                                <input type="text" className="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp" required="required" form='basic_details' {...register('airport_arrival')} />
                                                                <label htmlFor="exampleInputEmail1"
                                                                    className="form-label">Airport of arrival</label>
                                                                <div className="invalid-feedback">{errors.airport_arrival?.message}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="request_hr"></div>
                                        <div className="cargo_margin">
                                            <div className="row">
                                                <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                    <h5>Cargo:</h5>
                                                </div>
                                                <div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12">
                                                    <ul className="nav nav-tabs quotes_tabs ship_all_details_tab" id="myTab" role="tablist">
                                                        {/* <li>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control"
                                                                    aria-label="Text input with 2 dropdown buttons" />
                                                                    <select className="form-select" id="inputGroupSelect01">
                                                                        <option selected>kg</option>
                                                                        <option value="1">gm</option>
                                                                        <option value="2">tn</option>
                                                                    </select>
                                                            </div>
                                                        </li> */}
                                                        <li className="nav-item ms-auto me-2" role="presentation">
                                                            <button className="nav-link" id="home-tab" data-bs-toggle="tab"
                                                                data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                                                aria-selected="true"><i className="fa-solid fa-cube"></i></button>
                                                            <p className="quotes_total_blue">Total</p>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link" id="profile-tab" data-bs-toggle="tab"
                                                                data-bs-target="#profile" type="button" role="tab"
                                                                aria-controls="profile" aria-selected="false"><i
                                                                    className="fa-solid fa-coins"></i></button>
                                                            <p className="quotes_total_blue">Dimensions</p>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content" id="myTabContent">
                                                        <div className="tab-pane fade req_first_tab" id="home" role="tabpanel"
                                                            aria-labelledby="home-tab">
                                                            <div className="fadtab_header">
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text"> <i className="fa-solid fa-cube req_icon_size"></i>  </span>
                                                                    <input type="text" className="form-control" placeholder="5" {...register2("pieces")} />
                                                                    <span className="input-group-text">pc(s)</span>
                                                                    <label>Pieces</label>
                                                                    <div className="invalid-feedback">{errors2.pieces?.message}</div>
                                                                </div>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text"><i className="fa-solid fa-weight-hanging req_icon_size"></i></span>
                                                                    <input type="text" className="form-control" form='cargo_details' placeholder="1200" {...register2("weight")} />
                                                                    <span className="input-group-text">
                                                                        <div className="dropdown">
                                                                            <button className="btn dropdown-toggle grey_span_5" type="button" 
                                                                                id="dropdownMenuButton1"
                                                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                                                kg <img src="assets/images/icon/down-arrow-grey.svg"
                                                                                    alt="down-arrow" />
                                                                            </button>
                                                                            <ul className="dropdown-menu"
                                                                                aria-labelledby="dropdownMenuButton1">
                                                                                <li><a className="dropdown-item" href="/#">gm</a>
                                                                                </li>
                                                                                <li><a className="dropdown-item" href="/#">tn</a>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </span>
                                                                    <label>Weight</label>
                                                                    <div className="invalid-feedback">{errors2.weight?.message}</div>
                                                                </div>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text"><i className="fa-solid fa-text-height req_icon_size"></i> </span>
                                                                    <input type="text" className="form-control" form='cargo_details' placeholder="5" {...register2("volume")} />
                                                                    <span className="input-group-text">m<sup>3</sup> </span>
                                                                    <label>Volume</label>
                                                                    <div className="invalid-feedback">{errors2.volume?.message}</div>
                                                                </div>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text">1200</span>
                                                                    <input type="text" className="form-control" form='cargo_details' {...register2("chargeable_weight")} disabled />
                                                                    <span className="input-group-text">kg</span>
                                                                    <label>Chargable weight</label>
                                                                    <div className="invalid-feedback">{errors2.chargeable_weight?.message}</div>
                                                                </div>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text">
                                                                        <div className="dropdown">
                                                                            <button className="btn dropdown-toggle w-100 d-flex justify-content-between align-items-center" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"> <p className="mb-0">pallet</p>
                                                                                
                                                                            </button>
                                                                            <ul className="dropdown-menu w-100 list_contactinput_ul" aria-labelledby="dropdownMenuButton1">
                                                                                <li><a className="dropdown-item" href="/#">carton</a></li>
                                                                                <li><a className="dropdown-item" href="/#">crate</a></li>
                                                                                <li className="list_contactinput">
                                                                                    <div className="input_box">
                                                                                        <input type="text" className="form-control" id="exampleInputEmail1" placeholder="other" aria-describedby="emailHelp" required="required" />
                                                                                        <label htmlFor="exampleInputEmail1" className="form-label"></label>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </span>
                                                                    <input type="text" className="form-control" disabled />
                                                                    <span className="input-group-text">
                                                                        <img src="assets/images/icon/down-arrow.svg" alt="down-arrow" />
                                                                    </span>
                                                                    <label>Type</label>
                                                                </div>
                                                            </div>
                                                            <div className="req_fad_btn">
                                                                <button form='cargo_details' type='submit' className="btn btn_req_add">Add <i className="fa-solid fa-plus"></i></button>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade main_piece_bg" id="profile"
                                                            role="tabpanel" aria-labelledby="profile-tab">

                                                            <div className="fadtab_header">
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text"><i className="fa-solid fa-cube req_icon_size"></i>4</span>
                                                                    <input type="text" className="form-control" disabled />
                                                                    <span className="input-group-text">pc(s)</span>
                                                                    <label>Pieces</label>
                                                                </div>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text"><i className="fa-solid fa-weight-hanging req_icon_size"></i>380</span>
                                                                    <input type="text" className="form-control" disabled />
                                                                    <span className="input-group-text">kg</span>
                                                                    <label>Weight</label>
                                                                </div>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text"><i className="fa-solid fa-text-height req_icon_size"></i>3.84</span>
                                                                    <input type="text" className="form-control" disabled />
                                                                    <span className="input-group-text">m<sup>3</sup> </span>
                                                                    <label>Volume</label>
                                                                </div>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text">640</span>
                                                                    <input type="text" className="form-control" disabled />
                                                                    <span className="input-group-text">kg</span>
                                                                    <label>Chargable weight</label>
                                                                </div>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text">
                                                                        <div className="dropdown">
                                                                            <button className="btn dropdown-toggle w-100 d-flex justify-content-between align-items-center" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false"> <p className="mb-0">pallet</p>
                                                                                
                                                                            </button>
                                                                            <ul className="dropdown-menu w-100 list_contactinput_ul" aria-labelledby="dropdownMenuButton1">
                                                                                <li><a className="dropdown-item" href="/#">carton</a></li>
                                                                                <li><a className="dropdown-item" href="/#">crate</a></li>
                                                                                <li className="list_contactinput">
                                                                                    <div className="input_box">
                                                                                        <input type="text" className="form-control" id="exampleInputEmail1" placeholder="other" aria-describedby="emailHelp" required="required" />
                                                                                        <label htmlFor="exampleInputEmail1" className="form-label"></label>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </span>
                                                                    <input type="text" className="form-control" disabled />
                                                                    <span className="input-group-text">
                                                                        <img src="assets/images/icon/down-arrow.svg" alt="down-arrow" />
                                                                    </span>
                                                                    <label>Type</label>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-2">
                                                                    <div className="piece_heading">
                                                                        <span>Pieces</span>
                                                                    </div>
                                                                    <ul className="piece_li">
                                                                        <li>
                                                                            <p>1</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>3</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>1</p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="piece_heading">
                                                                        <div className="dimen_fle">
                                                                            <span>Dimensions</span>
                                                                            <div className="dropdown">
                                                                                <button className="btn dropdown-toggle" type="button"
                                                                                    id="dropdownMenuButton1"
                                                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                                                    cm <img src="assets/images/icon/down-arrow-white.svg"
                                                                                        alt="down-arrow" />
                                                                                </button>
                                                                                <ul className="dropdown-menu"
                                                                                    aria-labelledby="dropdownMenuButton1">
                                                                                    <li><a className="dropdown-item" href="/#">m</a></li>
                                                                                    <li><a className="dropdown-item" href="/#">km</a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="piece_dimensions">
                                                                        <p>120</p>
                                                                        <p>80</p>
                                                                        <p>100</p>
                                                                    </div>
                                                                    <div className="piece_dimensions">
                                                                        <p>80</p>
                                                                        <p>120</p>
                                                                        <p>100</p>
                                                                    </div>
                                                                    <div className="piece_dimensions">
                                                                        <p className="font_12">Length</p>
                                                                        <p className="font_12">Width</p>
                                                                        <p className="font_12">Height</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="piece_heading">
                                                                        <div className="dimen_fle">
                                                                            <span>Weight</span>
                                                                            <div className="dropdown">
                                                                                <button className="btn dropdown-toggle" type="button"
                                                                                    id="dropdownMenuButton1"
                                                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                                                    kg <img src="assets/images/icon/down-arrow-white.svg"
                                                                                        alt="down-arrow" />
                                                                                </button>
                                                                                <ul className="dropdown-menu"
                                                                                    aria-labelledby="dropdownMenuButton1">
                                                                                    <li><a className="dropdown-item" href="/#">gm</a>
                                                                                    </li>
                                                                                    <li><a className="dropdown-item" href="/#">tn</a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="piece_30 piece_dimensions">
                                                                        <p>150</p>
                                                                        <span className="switch">
                                                                            <input type="checkbox" id="switcher" checked />
                                                                            <label htmlFor="switcher"></label>
                                                                        </span>
                                                                    </div>
                                                                    <div className="piece_30 piece_dimensions">
                                                                        <p>230</p>
                                                                        <span className="switch">
                                                                            <input type="checkbox" id="switcher" />
                                                                            <label htmlFor="switcher"></label>
                                                                        </span>
                                                                    </div>
                                                                    <div className="piece_30 piece_dimensions">
                                                                        <p className="font_12">Width</p>
                                                                        <span className="switch">
                                                                            <input type="checkbox" id="switcher" checked />
                                                                            <label htmlFor="switcher"></label>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    {/* <div className="piece_heading">
                                                                        <span>Table</span>
                                                                    </div> */}
                                                                    <div className="check_box_1">
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox"
                                                                                id="inlineCheckbox1" value="option1" />
                                                                            <label className="form-check-label"
                                                                                htmlFor="inlineCheckbox1">non-stackable</label>
                                                                        </div>
                                                                        <a href="/#">
                                                                            <i className="fa-solid fa-trash incoterm_icon"></i>
                                                                        </a>
                                                                    </div>
                                                                    <div className="check_box_1">
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox"
                                                                                id="inlineCheckbox1" value="option1" />
                                                                            <label className="form-check-label"
                                                                                htmlFor="inlineCheckbox1">non-stackable</label>
                                                                        </div>
                                                                        <a href="/#">
                                                                            <i className="fa-solid fa-trash incoterm_icon"></i>
                                                                        </a>
                                                                    </div>
                                                                    <div className="check_box_1">
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox"
                                                                                id="inlineCheckbox1" value="option1" />
                                                                            <label className="form-check-label"
                                                                                htmlFor="inlineCheckbox1">non-stackable</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="req_fad_btn">
                                                                <button form='cargo_details' type='submit' className="btn btn_req_add">Add <i className="fa-solid fa-plus"></i></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="request_hr"></div>
                                        <div className="row">
                                            <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <h5>Cargo type:</h5>
                                            </div>
                                            <div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12">
                                                <div className="row">
                                                    <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                        <div className="request_textarea">
                                                            <div className="input_box_contact form-floating-1 input_box_margin">
                                                                <textarea className="form-control" form='cargo_details' id="message_contact" {...register2("desc")}
                                                                    name="message_contact"></textarea>
                                                                <label htmlFor="exampleInputEmail1" className="form-label">Goods
                                                                    Description</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <div className="request_switch_1 form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" form='cargo_details' {...register2("is_insurance")} />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Insurance</label>
                                                        </div>
                                                        <div className="request_label mt-3">
                                                            <div className="input_box input_box_margin">
                                                                <input type="text" className="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp" required="required" />
                                                                <label htmlFor="exampleInputEmail1" className="form-label">Goods
                                                                    value</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <div className="request_switch form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" form='cargo_details' {...register2("is_dangerous")} />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Dangerous</label>
                                                        </div>
                                                        {/* <div className="quotes_drop_1">
                                                            <a className="dropdown-item btn btn_quotes" href="/#">Dangerous</a>
                                                        </div> */}
                                                        {/* <div className="radio_same">
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio"
                                                                    name="inlineRadioOptions" id="inlineRadio1" value="option1" />
                                                                <label className="form-check-label ms-1" htmlFor="inlineRadio1">Yes</label>
                                                            </div>
                                                            <div className="form-check form-check-inline">
                                                                <input className="form-check-input" type="radio"
                                                                    name="inlineRadioOptions" id="inlineRadio2" value="option2" />
                                                                <label className="form-check-label ms-1" htmlFor="inlineRadio2">No</label>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <div className="request_switch form-check form-switch">
                                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" form='cargo_details' {...register2("is_temperature_controlled")} />
                                                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Temperature
                                                                controlled</label>
                                                        </div>
                                                        {/* <div className="quotes_drop_1">
                                                            <a className="dropdown-item btn btn_quotes" href="/#">Temperature
                                                                controlled</a>
                                                        </div> */}
                                                        {/* <div className="request_label">
                                                            <div className="input_box input_box_margin">
                                                                <input type="text" className="form-control" id="exampleInputEmail1"
                                                                    aria-describedby="emailHelp" required="required" />
                                                                <label htmlFor="exampleInputEmail1" className="form-label">Temperature
                                                                    controlled</label>
                                                            </div>
                                                        </div> */}
                                                    </div>
                                                    <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4">
                                                        <div className="request_textarea">
                                                            <div className="input_box_contact form-floating-1 input_box_margin">
                                                                <textarea className="form-control" id="message_contact"
                                                                    name="message_contact" form='cargo_details' {...register2("remark")}></textarea>
                                                                <label htmlFor="exampleInputEmail1" className="form-label">Remarks
                                                                    (optional)</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                        <div className="request_files">
                                                            <div className="drop-zone">
                                                                <span className="drop-zone__prompt">Drop file here or click to
                                                                    upload</span>
                                                                <input type="file" name="myFile" className="drop-zone__input" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                                        <div className="signin_button signin_button_next">
                                                            <button form='basic_details' type='submit' className="btn btn_signin w-100">Ask for a quote</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                </div>
                            </section>
                        </section>
                    </div>
                </div>
            </main>

            <div className="modal" id="exampleModal" tabindex="-1">
                <div className="modal-dialog modal-dialog-centered request_modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <img src="assets/images/page-image/incoterms.png" className="w-100" alt="graph" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Helmet>
                <script>
                    {
                        `
                            // var btn = document.getElementsByClassName("btn_incoterm")
                            // btn.classList.add("btn_quotes_active");

                            $(document).ready(function(){
                                $('.2').on('click', function(){
                                    $('a.2').addClass('btn_quotes_incoterm_active');
                                    $('a.1').removeClass('btn_quotes_incoterm_active');
                                    $('a.3').removeClass('btn_quotes_incoterm_active');
                                    $('a.4').removeClass('btn_quotes_incoterm_active');
                                })
                                $('.1').on('click', function(){
                                    $('a.1').addClass('btn_quotes_incoterm_active');
                                    $('a.2').removeClass('btn_quotes_incoterm_active');
                                    $('a.3').removeClass('btn_quotes_incoterm_active');
                                    $('a.4').removeClass('btn_quotes_incoterm_active');
                                })
                                $('.3').on('click', function(){
                                    $('a.3').addClass('btn_quotes_incoterm_active');
                                    $('a.2').removeClass('btn_quotes_incoterm_active');
                                    $('a.1').removeClass('btn_quotes_incoterm_active');
                                    $('a.4').removeClass('btn_quotes_incoterm_active');
                                })
                                $('.4').on('click', function(){
                                    $('a.4').addClass('btn_quotes_incoterm_active');
                                    $('a.2').removeClass('btn_quotes_incoterm_active');
                                    $('a.3').removeClass('btn_quotes_incoterm_active');
                                    $('a.1').removeClass('btn_quotes_incoterm_active');
                                })
                            });
                        `
                    }
                </script>
            </Helmet>
        </>
    )
}

