import React from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import useStorage from "../../../hooks/useStorage.ts";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { sendOTP, verifyOTP } from '../../../api/apiHandler';
import ToastMessage from '../../Toast';
import Slider from '../Slider/Slider';

const OtpVerification = () => {
    const { setItem,getItem } = useStorage();
    const navigate = useNavigate();
    var a = getItem("userDetail");
    a = JSON.parse(a)
    
    // if(getItem("type") === "email"){
        var email = a.email;
        console.log(email)
    // }
    // else if(getItem("type") === "phone"){
    //     var phone = a.phone;
    // }

    const validationSchema = Yup.object().shape({
        email: Yup.string(),
        // phone: Yup.string(),
        otp: Yup.string()
            .required('OTP is required') 
            .min(5, 'OTP must be at least 5 characters')
            .matches('^[0-9]', 'Only Numbers are allowed htmlFor this field'),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = (data) => {
        verifyOTP(data).then((response) => {
            response = JSON.parse(response)
            if(response.code == 1)
            {
                ToastMessage({type:"success", message: response.message})

                localStorage.removeItem("email");
                localStorage.removeItem("phone");
                localStorage.removeItem("type");
                setItem("MAisLogin", true);
                setTimeout(() => {
                    navigate("/");
                    window.location.reload();
                }, 2000);
            }
            else
            {
                ToastMessage({type:"error", message: response.message})
            }
        });
    }

    const backVerification = () => {
        // setTimeout(() => {
        //     navigate("/verification");
        // }, 1000);

        sendOTP({email: email}).then((response) => {
            response = JSON.parse(response);

            if(response.code == 1){
                ToastMessage({type:"success", message: response.message})
            }
            else{
                ToastMessage({type:"error", message: response.message})
            }
        });
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>OTP Verification</title>
            </Helmet>
            <main className="main_cargo h-100">
                <div className="container-fluid h-100">
                    <div className="row h-100">
                        <ToastContainer />
                        <Slider />
                        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 h-100">
                            <form onSubmit={handleSubmit(onSubmit)} noValidate>
                                <div className="row justify-content-center align-items-center h-100">
                                    <div className="col-12 col-xl-6 col-lg-6 col-md-8 col-sm-9 text-center h-100">
                                        <div className="signin_between">
                                            <div className="signin_logo">
                                                <img src={process.env.PUBLIC_URL +"/assets/images/icon/logo.svg"} alt="logo" />
                                            </div>
                                            <div className="signin_content signin_content_otp">
                                                <div className="lock_image">
                                                    <img src={process.env.PUBLIC_URL +"/assets/images/icon/Phone.svg"} alt="lock" />
                                                    <p>Enter the verification code we sent to 
                                                        {localStorage.getItem('type') == 'phone'? <span> {localStorage.getItem('phone')}</span> : <span> {localStorage.getItem('email')}</span> }
                                                    </p>
                                                </div>
                                                <input type="hidden" value={email} {...register('email')} />
                                                {/* <input type="hidden" value={phone} {...register('phone')} /> */}
                                                
                                                <div className="input_box">
                                                    <input type="text" className="form-control" {...register('otp')} id="exampleInputEmail1" aria-describedby="emailHelp" required="required" />
                                                    <label htmlFor="exampleInputEmail1" className="form-label">Type Code</label>
                                                    <div className="invalid-feedback">{errors.otp?.message}</div>
                                                </div>
                                                <div className="signin_button">
                                                    <button className="btn btn_signin w-100">Submit</button>
                                                </div>
                                                <div className="resend_code">
                                                    <p>Didn’t gate the code? <a type='button' onClick={backVerification}>Resend</a> </p>
                                                    {/* <a href="#">Call me</a> */}
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </main>
            <Helmet>
                <script>
                    {
                        `
                        console.log('ver')
                        jQuery("#carousel").owlCarousel({
                            autoplay: true,
                            loop: true,
                            touchDrag: true,
                            mouseDrag: true,
                            items: 1,
                            responsiveClass: true,
                            autoplayTimeout: 7000,
                            smartSpeed: 800,
                            nav: false,
                            dots: true,
                            responsive: {
                                0: {
                                    items: 1
                                },
                        
                                468: {
                                    items: 1
                                },
                        
                                767: {
                                    items: 1
                                },
                        
                                991: {
                                    items: 1
                                },
                        
                                1200: {
                                    items: 1
                                },
                        
                                1400: {
                                    items: 1
                                }
                            }
                        });
                        `
                    }
                </script>
            </Helmet>
        </React.Fragment>      
    )
}
export default OtpVerification;