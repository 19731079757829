import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import Sidebar from '../../components/customerComponents/Sidebar/Sidebar';
import Topbar from '../../components/customerComponents/Topbar/Topbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { addQuotes } from '../../api/apiHandler';
import Switch from '@material-ui/core/Switch';
import { useTranslation } from "react-i18next";

export default function RequestQuotesSea() {
    const commonProps = {title: "quotes"}
    const { t, i18n } = useTranslation();

    const navigate = useNavigate();
    const [getIncoterm, setIncoterm] = useState("EXW");
    // const [formValues, setFormValues] = useState([{ container: "", qty: "", goods_value: "", is_insurance: false, is_dangerous: false, is_temperature_controlled: false, volume : "", desc : "", remark : "" }]);
    const [formValues, setFormValues] = useState([{ container: "", qty: "", goods_value: "", pieces: "", weight : "", weight_type : "", type : "", goods_value: "", is_insurance: false, is_dangerous: false, is_temperature_controlled: false, volume : "", desc : "", remark : "" }]);

    const [isInsurance, setIsInsurance] = useState(true);
    const [isDangerous, setIsDangerous] = useState(true);
    const [isTemperatureControlled, setTemperatureControlled] = useState(true);

    const [container, setContainer] = useState("");

    const toggleIsInsurance = () => {
        setIsInsurance(current => !current);
    };
    
    const toggleIsDangerous = () => {
        setIsDangerous(current => !current);
    };

    const toggleIsTemperatureControlled = () => {
        setTemperatureControlled(current => !current);
    };

    console.log("---------------->", formValues[0].container)

    useEffect(() => {
        console.log('isInsurance is: ', isInsurance);
        console.log('isDangerous is: ', isDangerous);
        console.log('isTemperatureControlled is: ', isTemperatureControlled);
    }, [isInsurance, isDangerous, isTemperatureControlled]);

    var keys = [];
    var values = [];
    var result = {};

    let addFormFields = () => {
        // for GET Keys from last inserted array 
        for (let key of Object.keys(formValues.slice(-1)[0])) {
            keys.push(key)
        }

        // for GET Values from last inserted array 
        for (let value of Object.values(formValues.slice(-1)[0])) {
            values.push(value)
        }

        keys.forEach((key, i) => result[key] = values[i]);
        if(result.container === "" || result.qty === "" ||result.desc === ""){
            if(result.container === "" || result.container == "--"){
                toast.error("Please Select value from Container", {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
            else if(result.qty == "" || result.qty == "--"){
                toast.error("Please Select Quntity for Container", {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
            else if(result.desc === ""){
                toast.error("Please add Good Decription about Goods", {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
            else{
                toast.error("Please Fill all Info", {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        }else{
            setFormValues([...formValues, { container: "", qty: "", goods_value: "", is_insurance: "", is_dangerous: "", is_temperature_controlled: "",  desc : "", remark : "" }])
        }
    }

    let removeFormFields = (i) => {
        let newFormValues = [...formValues];
        newFormValues.splice(i, 1);
        setFormValues(newFormValues)
    }

    let handleChange = (i, e) => {
        // console.log("--------------?", e.target.id)
        // console.log("--------------?", `container${i}`)
        if(e.target.id == `container${i}`){
            if(e.target.value == "LCL"){
                setContainer("LCL");
            }
            else{
                setContainer(e.target.value);
            }
        }
        let newFormValues = [...formValues];
        newFormValues[i][e.target.name] = e.target.value;
        setFormValues(newFormValues);
    }

    const onSubmit = (e) => {
        e.preventDefault();

        if(document.getElementById("pickup_address").value === "" || document.getElementById("delivery_address").value === "" || document.getElementById("airport_departure").value === "" || document.getElementById("airport_arrival").value === ""){
            if(document.getElementById("pickup_address").value === ""){
                toast.error("Please add Pickup address", {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
            else if(document.getElementById("delivery_address").value === ""){
                toast.error("Please add Delivery address", {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
            else if(document.getElementById("airport_departure").value === ""){
                toast.error("Please give us proper Airport Departure", {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
            else if(document.getElementById("airport_arrival").value === ""){
                toast.error("Please give us proper Airport Arrival", {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
            else{
                console.log("Not done")
            }
        }
        else{
            var params = {
                your_reference: document.getElementById("your_reference").value,
                transport_mode: "Sea",
                date: new Date().toJSON().slice(0, 10),
                incoterm: getIncoterm,
                pick_address: document.getElementById("pickup_address").value,
                delivery_address: document.getElementById("delivery_address").value,
                airport_of_departure: document.getElementById("airport_departure").value,
                airport_of_arrival: document.getElementById("airport_arrival").value,
                cargo: formValues
            }
        }
        
        console.log("---------------------------->", params)
        addQuotes(params).then((response) => {
            response = JSON.parse(response);
            if(response.code == 1){
                toast.success(response.message, {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                setTimeout(() => {
                    navigate("/quotes");
                }, 1000);
            }
            else{
                toast.error(response.message, {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        })
    }

    return (
        <>
            <Helmet>
                <title>{t('request_quotes')} {t('sea')} -- Development</title>
            </Helmet>
            <main className="main_isl">
                <div className="container-fluid">
                    <div id="wrapper">
                        <Sidebar commonProps={commonProps}/>
                        <section id="content-wrapper">
                            <Topbar />

                            <section className="dashboard_area">
                                <ToastContainer />
                                <form onSubmit={onSubmit} noValidate>
                                    <div className="main_quote_request">
                                        <div className="row">
                                            <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <h5>{t('basic_details')}:</h5>
                                            </div>
                                            <div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12">
                                                <div className="request_label">
                                                    <div className="input_box input_box_margin">
                                                        <input type="text" className="form-control" id="your_reference" name='your_reference'
                                                            aria-describedby="emailHelp" required="required" />
                                                        <label htmlFor="exampleInputEmail1" className="form-label">{t('your_reference')} ({t('optional')})</label>
                                                    </div>
                                                </div>

                                                <p className="font_16">{t('transport_mode')}</p>

                                                <div className="row">
                                                    <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                        <div className="quotes_drop_1">
                                                            <Link className="dropdown-item btn btn_quotes" to="/requestquotes4"><img
                                                                    src={process.env.PUBLIC_URL +"/assets/images/icon/air-blue.svg"} alt="plane" /> {t('air')} </Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                        <div className="quotes_drop_1">
                                                            <Link className="dropdown-item btn btn_quotes_active" to="/requestquotessea2"><img
                                                                    src={process.env.PUBLIC_URL +"/assets/images/icon/sea-white.svg"} alt="plane" /> {t('sea')} </Link>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <div className="quotes_drop_1">
                                                            <Link className="dropdown-item btn btn_quotes" to="/requestquotesothers2"><img
                                                                    src={process.env.PUBLIC_URL +"/assets/images/icon/truck-blue.svg"} alt="plane" /> {t('road')} </Link>
                                                        </div>
                                                    </div> */}
                                                </div>

                                                <p className="font_16">Incoterm <a href="/#" data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"><i
                                                            className="fa-solid fa-circle-info incoterm_icon"></i></a> </p>
                                                <div id='main_incoterm' className="main_incoterm">
                                                    <a className="btn btn_incoterm btn_quotes_incoterm_active 1" onClick={() => setIncoterm("EXW")}>EXW</a>
                                                    <a className="btn btn_incoterm 2" onClick={() => setIncoterm("FOB")}>FOB</a>
                                                    <a className="btn btn_incoterm 3" onClick={() => setIncoterm("CFR")}>CFR</a>
                                                    <a className="btn btn_incoterm 4" onClick={() => setIncoterm("DDP")}>DDP</a>
                                                    <div className="dropdown w-100">
                                                        <button className="btn btn_incoterm dropdown-toggle" type="button"
                                                            id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                            aria-expanded="false">
                                                            Others
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li><a className="dropdown-item" href="/#">Action</a></li>
                                                            <li><a className="dropdown-item" href="/#">Another action</a></li>
                                                            <li><a className="dropdown-item" href="/#">Something else here</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="request_hr"></div>
                                        <div className="row">
                                            <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <h5>Itinerary:</h5>
                                            </div>
                                            <div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12">
                                                <div className="row">
                                                    <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                        <div className="request_label">
                                                            <div className="input_box input_box_margin">
                                                                <input type="text" className="form-control" id="pickup_address" name='pick_address'
                                                                    aria-describedby="emailHelp" required="required" />
                                                                <label htmlFor="exampleInputEmail1" className="form-label">{t('pick_up_address')}</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                        <div className="request_label">
                                                            <div className="input_box input_box_margin">
                                                                <input type="text" className="form-control" id="delivery_address" name='delivery_address'
                                                                    aria-describedby="emailHelp" required="required" />
                                                                <label htmlFor="exampleInputEmail1"
                                                                    className="form-label">{t('delivery_address')}</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                        <div className="request_label">
                                                            <div className="input_box input_box_margin">
                                                                <input type="text" className="form-control" id="airport_departure" name='airport_departure'
                                                                    aria-describedby="emailHelp" required="required" />
                                                                <label htmlFor="exampleInputEmail1" className="form-label">{t('airport_of_departure')}</label>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                        <div className="request_label">
                                                            <div className="input_box input_box_margin">
                                                                <input type="text" className="form-control" id="airport_arrival" name='airport_arrival'
                                                                    aria-describedby="emailHelp" required="required" />
                                                                <label htmlFor="exampleInputEmail1"
                                                                    className="form-label">{t('airport_of_arrival')}</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="request_hr"></div>
                                        <div className="cargo_margin">
                                            <div className="row">
                                                <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                    <h5>Cargo:</h5>
                                                </div>
                                                <div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12">
                                                    <div className="req_sea_add">
                                                        {/* Multiple Div */}
                                                        {formValues.map((element, index) => (
                                                            <div key={index}>
                                                                <div className="row mt-5">
                                                                    <div className="request_hr"></div>
                                                                    <div className="col-9 col-xl-10 col-lg-10 col-md-10 col-sm-10">
                                                                        <label className="mb-2 con_lab">{t('container')}</label>
                                                                        <div className="d-flex align-items-center">
                                                                            <a href="/#" className="me-2">
                                                                                <i className="fa-solid fa-ruler-horizontal ftgeneral_icon"></i>
                                                                            </a>
                                                                            <select id={`container${index}`} name="container" value={element.container || ""} className="form-select" onChange={e => handleChange(index, e)}>
                                                                                <option value="--"> -- {t('select_container_type')} -- </option>
                                                                                <option value="20ft general">{t('20ft_general')}</option>
                                                                                <option value="20ft high cube">{t('20ft_high_cube')}</option>
                                                                                <option value="40ft general">{t('40ft_general')}</option>
                                                                                <option value="40ft high cube">{t('40ft_high_cube')}</option>
                                                                                <option value="LCL">LCL</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-3 col-xl-2 col-lg-2 col-md-2 col-sm-2">
                                                                        <label className="mb-2">{t('quantity')}</label>
                                                                        <div className="d-flex align-items-center">
                                                                            <select id={`qty${index}`} name="qty" value={element.qty || ""} className="form-select" onChange={e => handleChange(index, e)}>
                                                                                <option value="--">-- {t('select_qty')} --</option>
                                                                                <option value="1">1</option>
                                                                                <option value="2">2</option>
                                                                                <option value="3">3</option>
                                                                                <option value="4">4</option>
                                                                            </select>
                                                                            <a type='button' className="ms-2" onClick={() => removeFormFields(index)}>
                                                                                <i className="fa-solid fa-xmark ftgeneral_icon remove"></i>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                {document.getElementById(`container${index}`) != null ?
                                                                    <>
                                                                        {document.getElementById(`container${index}`).value == "LCL" ? 
                                                                            <>
                                                                                <div className="tab-pane fade show active req_first_tab" id="home" role="tabpanel"
                                                                                    aria-labelledby="home-tab">
                                                                                    <div className="fadtab_header">
                                                                                        <div className="input-group mb-3">
                                                                                            <span className="input-group-text"> <i className="fa-solid fa-cube req_icon_size"></i>  </span>
                                                                                            <input type="text" id={`pieces${index}`} className="form-control" name="pieces" value={element.pieces || ""} placeholder="5" onChange={e => handleChange(index, e)} />
                                                                                            <span className="input-group-text">pc(s)</span>
                                                                                            <label>{t('pieces')}</label>
                                                                                        </div>
                                                                                        <div className="input-group mb-3">
                                                                                            <span className="input-group-text"><i className="fa-solid fa-weight-hanging req_icon_size"></i></span>
                                                                                            <input type="text" className="form-control" name="weight" value={element.weight || ""} placeholder="1200" onChange={e => handleChange(index, e)} />
                                                                                            <span className="input-group-text">
                                                                                            <div className="dropdown">
                                                                                                <select id={`weight_type${index}`} name="weight_type" value={element.weight_type || ""} className='btn dropdown-toggle grey_span_5' onChange={e => handleChange(index, e)}>
                                                                                                    <option className="dropdown-item" value="--">--</option>
                                                                                                    <option className="dropdown-item" value="kg">kg</option>
                                                                                                    <option className="dropdown-item" value="gm">gm</option>
                                                                                                    <option className="dropdown-item" value="tn">tn</option>
                                                                                                </select>
                                                                                            </div>
                                                                                            </span>
                                                                                            <label>{t('weight')}</label>
                                                                                        </div>
                                                                                        <div className="input-group mb-3">
                                                                                            <span className="input-group-text"><i className="fa-solid fa-text-height req_icon_size"></i> </span>
                                                                                            <input type="text" id={`volume${index}`} className="form-control" name="volume" value={element.volume || ""} placeholder="5" onChange={e => handleChange(index, e)} />
                                                                                            <span className="input-group-text">m<sup>3</sup> </span>
                                                                                            <label>Volume</label>
                                                                                        </div>
                                                                                        <div className="input-group mb-3">
                                                                                            <span className="input-group-text">1200</span>
                                                                                            <input type="text" className="form-control" disabled />
                                                                                            <span className="input-group-text">kg</span>
                                                                                            <label>{t('chargable_weight')}</label>
                                                                                        </div>
                                                                                        <div className="input-group mb-3">
                                                                                            <span className="input-group-text">
                                                                                                <div className="dropdown">
                                                                                                    <select name="type" id={`type${index}`} value={element.type || ""} className='btn dropdown-toggle grey_span_5' onChange={e => handleChange(index, e)}>
                                                                                                        <option className="dropdown-item" value="--">--</option>
                                                                                                        <option className="dropdown-item" value="pallet">{t('pallet')}</option>
                                                                                                        <option className="dropdown-item" value="carton">Carton</option>
                                                                                                        <option className="dropdown-item" value="crate">{t('crate')}</option>
                                                                                                    </select>
                                                                                                </div>
                                                                                            </span>
                                                                                            <input type="text" className="form-control" disabled />
                                                                                            <label>{t('type')}</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </> 
                                                                        : 
                                                                            <></>
                                                                        }
                                                                    </>
                                                                :
                                                                    <></>
                                                                }
                                                                
                                                                <div>
                                                                    <div className="row mt-4">
                                                                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                        <h5 className='mb-4'>{t('cargo_type')}:</h5>
                                                                            <div className="request_textarea">
                                                                                <div className="input_box_contact form-floating-1 input_box_margin">
                                                                                    <textarea className="form-control" id={`desc${index}`} name="desc" value={element.desc || ""} onChange={e => handleChange(index, e)}></textarea>
                                                                                    <label htmlFor={`desc${index}`} name="desc" className="form-label">{t('good_description')}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                            <div className="request_switch_1 form-check form-switch" onChange={toggleIsInsurance}>
                                                                                <Switch
                                                                                    name='is_insurance'
                                                                                    id={`is_insurance${index}`}
                                                                                    // onValueChange={toggleSwitch}
                                                                                    value={isInsurance}
                                                                                    onChange={e => handleChange(index, e)}
                                                                                />
                                                                                {/* <input className="form-check-input" type="checkbox" id={`is_insurance${index}`} name="is_insurance" value={true || false} onChange={e => handleChange(index, e)} /> */}
                                                                                <label className="form-check-label" htmlFor={`is_insurance${index}`}>{t('insurance')}</label>
                                                                            </div>
                                                                            <div className="request_label mt-3">
                                                                                <div className="input_box input_box_margin">
                                                                                    <input type="text" className="form-control" id={`goods_value${index}`} name="goods_value" value={element.goods_value || ""} onChange={e => handleChange(index, e)}
                                                                                        aria-describedby="emailHelp" required="required" />
                                                                                    <label htmlFor={`goods_value${index}`} className="form-label">{t('goods_value')}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                            <div className="request_switch form-check form-switch" onClick={toggleIsDangerous}>
                                                                                <Switch
                                                                                    name='is_dangerous'
                                                                                    id={`is_dangerous${index}`}
                                                                                    value={isDangerous}
                                                                                    onChange={e => handleChange(index, e)}
                                                                                />
                                                                                {/* <input className="form-check-input" type="checkbox" id={`is_dangerous${index}`} name="is_dangerous" value={true || false} onChange={e => handleChange(index, e)} /> */}
                                                                                <label className="form-check-label" htmlFor={`is_dangerous${index}`}>{t('dangerous')}</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                            <div className="request_switch form-check form-switch" onClick={toggleIsTemperatureControlled}>
                                                                                <Switch
                                                                                    name='is_temperature_controlled'
                                                                                    id={`is_temperature_controlled${index}`}
                                                                                    value={isTemperatureControlled}
                                                                                    onChange={e => handleChange(index, e)}
                                                                                />
                                                                                {/* <input className="form-check-input" type="checkbox" id={`is_temperature_controlled${index}`} name="is_temperature_controlled" value={true || false} onChange={e => handleChange(index, e)} /> */}
                                                                                <label className="form-check-label" htmlFor={`is_temperature_controlled${index}`}>{t('temperature_controlled')}</label>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4">
                                                                            <div className="request_textarea">
                                                                                <div className="input_box_contact form-floating-1 input_box_margin">
                                                                                    <textarea className="form-control" id={`remark${index}`} name="remark" value={element.remark || ""} onChange={e => handleChange(index, e)}
                                                                                        ></textarea>
                                                                                    <label htmlFor={`remark${index}`} name="remark" className="form-label">{t('remarks')} ({t('optional')})</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                            <div className="request_files">
                                                                                <div className="drop-zone">
                                                                                    <span className="drop-zone__prompt">Drop file here or click to
                                                                                        upload</span>
                                                                                    <input type="file" name="myFile" className="drop-zone__input" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        <div className="req_fad_btn">
                                                            <a type='button' className="btn btn_req_add" onClick={() => addFormFields()}>{t('add')} <i className="fa-solid fa-plus"></i></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="request_hr"></div>
                                        <div className="row">
                                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                                        <div className="signin_button signin_button_next">
                                                            <button type='submit' className="btn btn_signin w-100">{t('ask_for_a_quote')}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </section>
                        </section>
                    </div>
                </div>
            </main>

            <div className="modal" id="exampleModal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered request_modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <img src={process.env.PUBLIC_URL +"/assets/images/page-image/incoterms.png"} className="w-100" alt="graph" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Helmet>
                <script>
                    {
                        `
                        $(document).ready(function(){
                            $('.2').on('click', function(){
                                $('a.2').addClass('btn_quotes_incoterm_active');
                                $('a.1').removeClass('btn_quotes_incoterm_active');
                                $('a.3').removeClass('btn_quotes_incoterm_active');
                                $('a.4').removeClass('btn_quotes_incoterm_active');
                            })
                            $('.1').on('click', function(){
                                $('a.1').addClass('btn_quotes_incoterm_active');
                                $('a.2').removeClass('btn_quotes_incoterm_active');
                                $('a.3').removeClass('btn_quotes_incoterm_active');
                                $('a.4').removeClass('btn_quotes_incoterm_active');
                            })
                            $('.3').on('click', function(){
                                $('a.3').addClass('btn_quotes_incoterm_active');
                                $('a.2').removeClass('btn_quotes_incoterm_active');
                                $('a.1').removeClass('btn_quotes_incoterm_active');
                                $('a.4').removeClass('btn_quotes_incoterm_active');
                            })
                            $('.4').on('click', function(){
                                $('a.4').addClass('btn_quotes_incoterm_active');
                                $('a.2').removeClass('btn_quotes_incoterm_active');
                                $('a.3').removeClass('btn_quotes_incoterm_active');
                                $('a.1').removeClass('btn_quotes_incoterm_active');
                            })
                        });
                        `
                    }
                </script>
            </Helmet>
        </>
    )
}
