import React from 'react';
import Sidebar from '../../components/customerComponents/Sidebar/Sidebar';
import Topbar from '../../components/customerComponents/Topbar/Topbar';

export default function Air() {
    const commonProps = {title: "air"}

    return (
        <>
            <div className='dash_b'>
                <main className="main_isl">
                    <div className="container-fluid">
                        <div id="wrapper">
                            <Sidebar commonProps={commonProps}/>

                            <section id="content-wrapper">
                                <Topbar />

                                <section className="dashboard_area">
                                    <div className="row quo_area">
                                        <div className="col-12 col-xl-7 col-lg-7 col-md-7 col-sm-12 d-flex">
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle text_35" type="button" id="dropdownMenuButton1"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                    Filter <img src="assets/images/icon/down-arrow.svg" alt="down-arrow" />
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li>
                                                        <a className="dropdown-item" href="/#">
                                                            <span>Select date</span>
                                                            <div className="quote_dropdown">
                                                                <p className="mb-0">From:</p>
                                                                <input type="date" id="birthday" name="birthday"
                                                                    className="date_input" />
                                                            </div>
                                                            <div className="quote_dropdown">
                                                                <p className="mb-0">To:</p>
                                                                <input type="date" id="birthday" name="birthday"
                                                                    className="date_input" />
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li className="mt-2">
                                                        <a className="dropdown-item quote_origin" href="/#">
                                                            <div className="input_box">
                                                                <input type="text" className="form-control" placeholder="Origin" id="exampleInputEmail1" aria-describedby="emailHelp" required="required" />
                                                                <label htmlFor="exampleInputEmail1" className="form-label"></label>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li className="mt-2">
                                                        <a className="dropdown-item quote_origin" href="/#">
                                                            <div className="input_box">
                                                                <input type="text" className="form-control" placeholder="Destination" id="exampleInputEmail1" aria-describedby="emailHelp" required="required" />
                                                                <label htmlFor="exampleInputEmail1" className="form-label"></label>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li className="quote_apply mt-2">
                                                        <a className="dropdown-item btn btn_quote_apply" href="/#">Apply</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="q_drop">
                                                <ul className="quotes_drop quotes_drop_f6">
                                                    <li className="d-flex">
                                                        <a className="dropdown-item btn btn_quotes" href="/#"><img
                                                                src="assets/images/icon/air-blue.svg" alt="plane" /> Air <i
                                                                className="fa-solid fa-circle-check"></i> </a>
                                                        <a className="dropdown-item btn btn_quotes" href="/#"><img
                                                                src="assets/images/icon/sea-blue.svg" alt="boat" />Sea <i
                                                                className="fa-solid fa-circle-check"></i> </a>
                                                        <a className="dropdown-item btn btn_quotes" href="/#"><img
                                                                src="assets/images/icon/truck-blue.svg" alt="truck" />Other <i
                                                                className="fa-solid fa-circle-check"></i> </a>
                                                    </li>
                                                    <li className="d-flex">
                                                        <a className="dropdown-item btn btn_quotes" href="/#"><img
                                                                src="assets/images/icon/responded-blue.svg" alt="respond" />Responded
                                                            <i className="fa-solid fa-circle-check"></i> </a>
                                                        <a className="dropdown-item btn btn_quotes" href="/#"><img
                                                                src="assets/images/icon/pending-blue.svg" alt="pending" />Pending <i
                                                                className="fa-solid fa-circle-check"></i> </a>
                                                        <a className="dropdown-item btn btn_quotes" href="/#"><img
                                                                src="assets/images/icon/expired-blue.svg" alt="expire" />Expired <i
                                                                className="fa-solid fa-circle-check"></i> </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-2 col-lg-2 col-md-2 col-sm-12 ms-auto">
                                            <div className="header_search">
                                                <div className="input-group">
                                                    <span className="input-group-text">
                                                        <img src="assets/images/icon/search.svg" alt="search" />
                                                    </span>
                                                    <input type="text" className="form-control" placeholder="Search"
                                                        aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="quote_table table-responsive">
                                        <table className="table table-striped mb-0">
                                            <thead>
                                                <tr className="table_header_bg">
                                                    <th scope="col">Our reference <img src="assets/images/icon/table-filtershort.svg" alt="filter" /></th>
                                                    <th scope="col">Your reference <img src="assets/images/icon/table-filtershort.svg" alt="filter" /></th>
                                                    <th scope="col">Date <img src="assets/images/icon/table-filtershort.svg" alt="filter" /></th>
                                                    <th scope="col">Origin <img src="assets/images/icon/table-filtershort.svg" alt="filter" /></th>
                                                    <th scope="col">Destination <img src="assets/images/icon/table-filtershort.svg" alt="filter" /></th>
                                                    <th scope="col">Cargo type <img src="assets/images/icon/table-filtershort.svg" alt="filter" /></th>
                                                    <th scope="col">AWB <img src="assets/images/icon/table-filtershort.svg" alt="filter" /></th>
                                                    <th scope="col">Flight number <img src="assets/images/icon/table-filtershort.svg" alt="filter" /></th>
                                                    <th scope="col">ETD <img src="assets/images/icon/table-filtershort.svg" alt="filter" /></th>
                                                    <th scope="col">ETA <img src="assets/images/icon/table-filtershort.svg" alt="filter" /></th>
                                                    <th scope="col">Status</th>
                                                    <th scope="col">POD</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr className="table-row"data-href="shipment-tab.html">
                                                    <td>Lorem</td>
                                                    <td>Ispum</td>
                                                    <td>14/04/2022</td>
                                                    <td>South Letitiaberg</td>
                                                    <td>New Kelvinshire</td>
                                                    <td>Air</td>
                                                    <td>Lorem</td>
                                                    <td>AA111</td>
                                                    <td><span>14/04/2022</span><br /><span>14:00</span></td>
                                                    <td><span>16/04/2022</span><br /><span>17:00</span></td>
                                                    <td> <span className="table_respond">Orderd</span> </td>
                                                    <td><a href="/#" className="btn_view"> <i className="fa-solid fa-download me-1"></i>Download</a></td>
                                                    <td><a href="track-parcel.html" className="btn_view">Track parcel</a> </td>
                                                </tr>
                                                <tr className="table-row"data-href="shipment-tab.html">
                                                    <td>Lorem</td>
                                                    <td>Ispum</td>
                                                    <td>14/04/2022</td>
                                                    <td>South Letitiaberg</td>
                                                    <td>New Kelvinshire</td>
                                                    <td>Air</td>
                                                    <td>Lorem</td>
                                                    <td>AA111</td>
                                                    <td><span>14/04/2022</span><br /><span>14:00</span></td>
                                                    <td><span>16/04/2022</span><br /><span>17:00</span></td>
                                                    <td> <span className="table_pending">Shipped</span> </td>
                                                    <td><a href="/#" className="btn_view"> <i className="fa-solid fa-download me-1"></i>Download</a></td>
                                                    <td><a href="track-parcel.html" className="btn_view">Track parcel</a> </td>
                                                </tr>
                                                <tr className="table-row"data-href="shipment-tab.html">
                                                    <td>Lorem</td>
                                                    <td>Ispum</td>
                                                    <td>14/04/2022</td>
                                                    <td>South Letitiaberg</td>
                                                    <td>New Kelvinshire</td>
                                                    <td>Air</td>
                                                    <td>Lorem</td>
                                                    <td>AA111</td>
                                                    <td><span>14/04/2022</span><br /><span>14:00</span></td>
                                                    <td><span>16/04/2022</span><br /><span>17:00</span></td>
                                                    <td> <span className="table_expire">Out of delivery</span> </td>
                                                    <td><a href="/#" className="btn_view"> <i className="fa-solid fa-download me-1"></i>Download</a></td>
                                                    <td><a href="track-parcel.html" className="btn_view">Track parcel</a> </td>
                                                </tr>
                                                <tr className="table-row"data-href="shipment-tab.html">
                                                    <td>Lorem</td>
                                                    <td>Ispum</td>
                                                    <td>14/04/2022</td>
                                                    <td>South Letitiaberg</td>
                                                    <td>New Kelvinshire</td>
                                                    <td>Air</td>
                                                    <td>Lorem</td>
                                                    <td>AA111</td>
                                                    <td><span>14/04/2022</span><br /><span>14:00</span></td>
                                                    <td><span>16/04/2022</span><br /><span>17:00</span></td>
                                                    <td> <span className="table_arrived">Arrived</span> </td>
                                                    <td><a href="/#" className="btn_view"> <i className="fa-solid fa-download me-1"></i>Download</a></td>
                                                    <td><a href="track-parcel.html" className="btn_view">Track parcel</a> </td>
                                                </tr>
                                                <tr className="table-row"data-href="shipment-tab.html">
                                                    <td>Lorem</td>
                                                    <td>Ispum</td>
                                                    <td>14/04/2022</td>
                                                    <td>South Letitiaberg</td>
                                                    <td>New Kelvinshire</td>
                                                    <td>Air</td>
                                                    <td>Lorem</td>
                                                    <td>AA111</td>
                                                    <td><span>14/04/2022</span><br /><span>14:00</span></td>
                                                    <td><span>16/04/2022</span><br /><span>17:00</span></td>
                                                    <td> <span className="table_respond">Orderd</span> </td>
                                                    <td><a href="/#" className="btn_view"> <i className="fa-solid fa-download me-1"></i>Download</a></td>
                                                    <td><a href="track-parcel.html" className="btn_view">Track parcel</a> </td>
                                                </tr>
                                                <tr className="table-row"data-href="shipment-tab.html">
                                                    <td>Lorem</td>
                                                    <td>Ispum</td>
                                                    <td>14/04/2022</td>
                                                    <td>South Letitiaberg</td>
                                                    <td>New Kelvinshire</td>
                                                    <td>Air</td>
                                                    <td>Lorem</td>
                                                    <td>AA111</td>
                                                    <td><span>14/04/2022</span><br /><span>14:00</span></td>
                                                    <td><span>16/04/2022</span><br /><span>17:00</span></td>
                                                    <td> <span className="table_pending">Shipped</span> </td>
                                                    <td><a href="/#" className="btn_view"> <i className="fa-solid fa-download me-1"></i>Download</a></td>
                                                    <td><a href="track-parcel.html" className="btn_view">Track parcel</a> </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="quote_pagination">
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination justify-content-end">
                                                <li className="page-item">
                                                    <a className="page-link quote_pre" href="/#">Previous</a>
                                                </li>
                                                <li className="page-item"><a className="page-link active" href="/#">1</a></li>
                                                <li className="page-item"><a className="page-link" href="/#">2</a></li>
                                                <li className="page-item"><a className="page-link" href="/#">3</a></li>
                                                <li className="page-item">
                                                    <a className="page-link quote_pre" href="/#">Next</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </section>
                            </section>

                        </div>
                    </div>
                </main>
            </div>
        </>
    )
}
