import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { addQuotes } from '../../api/apiHandler';
import Sidebar from '../../components/customerComponents/Sidebar/Sidebar';
import Topbar from '../../components/customerComponents/Topbar/Topbar';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function RequestQuotes3() {
    const commonProps = {title: "quotes"}
    const [getIncoterm, setIncoterm] = useState("EXW");
    var [count, setCount] = useState(0);
    console.log("---------------------------->>>>", count)

    const plusOne = () => {
        setCount (count + 1)
    }

    const minusOne = () => {
        setCount (count - 1)
    }

    const onSubmit = (e) => {
        e.preventDefault();
        console.log(document.getElementById("is_insurance").value)
        console.log(document.getElementById("is_dangerous").value)
        console.log(document.getElementById("is_temperature_controlled").value)
        console.log(count)

        if(document.getElementById("pickup_address").value == "" || document.getElementById("delivery_address").value == "" || document.getElementById("airport_departure").value == "" || document.getElementById("airport_arrival").value == ""){
            // Pick Up Address
            if(document.getElementById("pickup_address").value == ""){
                document.getElementById("pickup_address_error").classList.remove("hide");
                document.getElementById("pickup_address_error").classList.add("invalid-feedback");
            }
            else{
                document.getElementById("pickup_address_error").classList.add("hide");
            }

            // Delivery Address
            if(document.getElementById("delivery_address").value == ""){
                document.getElementById("delivery_address_error").classList.remove("hide");
                document.getElementById("delivery_address_error").classList.add("invalid-feedback");
            }
            else{
                document.getElementById("delivery_address_error").classList.add("hide");
            }

            // Airport Departure
            if(document.getElementById("airport_departure").value == ""){
                document.getElementById("airport_departure_error").classList.remove("hide");
                document.getElementById("airport_departure_error").classList.add("invalid-feedback");
            }
            else{
                document.getElementById("airport_departure_error").classList.add("hide");
            }

            // Airport Arrival
            if(document.getElementById("airport_arrival").value == ""){
                document.getElementById("airport_arrival_error").classList.remove("hide");
                document.getElementById("airport_arrival_error").classList.add("invalid-feedback");
            }
            else{
                document.getElementById("airport_arrival_error").classList.add("hide");
            }

            // Pieces
            if(document.getElementById("pieces").value == ""){
                document.getElementById("pieces_error").classList.remove("hide");
                document.getElementById("pieces_error").classList.add("invalid-feedback");
            }
            else{
                document.getElementById("pieces_error").classList.add("hide");
            }

            // Pieces [Array]
            if(document.getElementById(`pieces${count}`).value == ""){
                document.getElementById(`pieces_error${count}`).classList.remove("hide");
                document.getElementById(`pieces_error${count}`).classList.add("invalid-feedback");
            }
            else{
                document.getElementById(`pieces_error${count}`).classList.add("hide");
            }

            // Weight
            if(document.getElementById("weight").value == ""){
                document.getElementById("weight_error").classList.remove("hide");
                document.getElementById("weight_error").classList.add("invalid-feedback");
            }
            else{
                document.getElementById("weight_error").classList.add("hide");
            }

            // Weight [Array]
            if(document.getElementById(`weight${count}`).value == ""){
                document.getElementById(`weight_error${count}`).classList.remove("hide");
                document.getElementById(`weight_error${count}`).classList.add("invalid-feedback");
            }
            else{
                document.getElementById(`weight_error${count}`).classList.add("hide");
            }

            // Volume
            if(document.getElementById("volume").value == ""){
                document.getElementById("volume_error").classList.remove("hide");
                document.getElementById("volume_error").classList.add("invalid-feedback");
            }
            else{
                document.getElementById("volume_error").classList.add("hide");
            }

            // Volume [Array]
            if(document.getElementById(`volume${count}`).value == ""){
                document.getElementById(`volume_error${count}`).classList.remove("hide");
                document.getElementById(`volume_error${count}`).classList.add("invalid-feedback");
            }
            else{
                document.getElementById(`volume_error${count}`).classList.add("hide");
            }

            // Desc
            if(document.getElementById("message_contact").value == ""){
                document.getElementById("message_contact_error").classList.remove("hide");
                document.getElementById("message_contact_error").classList.add("invalid-feedback");
            }
            else{
                document.getElementById("message_contact_error").classList.add("hide");
            }

            // Desc [Array]
            if(document.getElementById(`message_contact${count}`).value == ""){
                document.getElementById(`message_contact_error${count}`).classList.remove("hide");
                document.getElementById(`message_contact_error${count}`).classList.add("invalid-feedback");
            }
            else{
                document.getElementById(`message_contact_error${count}`).classList.add("hide");
            }

            // Remark
            if(document.getElementById("remark").value.length > 10){
                document.getElementById("remark_error").classList.remove("hide");
                document.getElementById("remark_error").classList.add("invalid-feedback");
            }
            else{
                document.getElementById("remark_error").classList.add("hide");
            }
        }
        else{
            document.getElementById("pickup_address_error").classList.add("hide");
            document.getElementById("delivery_address_error").classList.add("hide");
            document.getElementById("airport_departure_error").classList.add("hide");
            document.getElementById("airport_arrival_error").classList.add("hide");
            document.getElementById("pieces_error").classList.add("hide");
            document.getElementById("weight_error").classList.add("hide");
            document.getElementById("volume_error").classList.add("hide");
            document.getElementById("message_contact_error").classList.add("hide");
            document.getElementById("remark_error").classList.add("hide");

            // ----------------------------------------- Params Part ------------------------------------------------------
            if(count == 0){
                var params = {
                    your_reference: document.getElementById("your_reference").value,
                    transport_mode: "Air",
                    incoterm: getIncoterm,
                    pick_address: document.getElementById("pickup_address").value,
                    delivery_address: document.getElementById("delivery_address").value,
                    airport_departure: document.getElementById("airport_departure").value,
                    airport_arrival: document.getElementById("airport_arrival").value,
                    cargo: [{
                        pieces: document.getElementById("pieces").value,
                        weight: document.getElementById("weight").value,
                        weight_type: document.getElementById("weight_type").value,
                        volume: document.getElementById("volume").value,
                        desc: document.getElementById("message_contact").value,
                        goods_value: document.getElementById("goods_value").value,
                        is_insurance: document.getElementById("is_insurance").value == "on" ? "true" : "false",
                        is_dangerous: document.getElementById("is_dangerous").value == "on" ? "true" : "false",
                        is_temperature_controlled: document.getElementById("is_temperature_controlled").value == "on" ? "true" : "false",
                        remark: document.getElementById("remark").value
                    }]
                }
            }
            else{
                var cargoparams= {
                    pieces: document.getElementById("pieces").value,
                    weight: document.getElementById("weight").value,
                    weight_type: document.getElementById("weight_type").value,
                    volume: document.getElementById("volume").value,
                    desc: document.getElementById("message_contact").value,
                    goods_value: document.getElementById("goods_value").value,
                    is_insurance: document.getElementById("is_insurance").value == "on" ? "true" : "false",
                    is_dangerous: document.getElementById("is_dangerous").value == "on" ? "true" : "false",
                    is_temperature_controlled: document.getElementById("is_temperature_controlled").value == "on" ? "true" : "false",
                    remark: document.getElementById("remark").value
                }
                var arr = [];
                arr.push(cargoparams);

                for (let i = 0; i < count; i++) {
                    // console.log("==>",i)
                    // console.log("abc"+`${i}`)
                    var cargoparams= {
                        pieces: document.getElementById("pieces"+`${i}`).value,
                        weight: document.getElementById("weight"+`${i}`).value,
                        weight_type: document.getElementById("weight_type"+`${i}`).value,
                        volume: document.getElementById("volume"+`${i}`).value,
                        desc: document.getElementById("message_contact"+`${i}`).value,
                        goods_value: document.getElementById("goods_value"+`${i}`).value,
                        is_insurance: document.getElementById("is_insurance"+`${i}`).value == "on" ? "true" : "false",
                        is_dangerous: document.getElementById("is_dangerous"+`${i}`).value == "on" ? "true" : "false",
                        is_temperature_controlled: document.getElementById("is_temperature_controlled"+`${i}`).value == "on" ? "true" : "false",
                        remark: document.getElementById("remark"+`${i}`).value
                    }
                    arr.push(cargoparams);
                }

                var params = {
                    your_reference: document.getElementById("your_reference").value,
                    transport_mode: "Air",
                    incoterm: getIncoterm,
                    pick_address: document.getElementById("pickup_address").value,
                    delivery_address: document.getElementById("delivery_address").value,
                    airport_departure: document.getElementById("airport_departure").value,
                    airport_arrival: document.getElementById("airport_arrival").value,
                    cargo: arr
                }
            }
        }

        
        console.log("=========>>>", params)
        // addQuotes(params).then((response) => {
        //     response = JSON.parse(response);
        //     if(response.code == 1){
        //         toast.success(response.message, {
        //             position: "top-right",
        //             autoClose: 5000,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //         });
        //     }
        //     else{
        //         toast.error(response.message, {
        //             position: "top-right",
        //             autoClose: 5000,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //         });
        //     }
        // })
    }

    return (
        <>
            <Helmet>
                <title>Request Quotes Air - 3</title>
            </Helmet>
            <main className="main_isl">
                <div className="container-fluid">
                    <div id="wrapper">
                        <Sidebar commonProps={commonProps}/>

                        <section id="content-wrapper">
                            <Topbar />

                            <section className="dashboard_area">
                                <ToastContainer />
                                <form onSubmit={onSubmit} noValidate>
                                    <div className="main_quote_request">
                                        <div className="row">
                                            <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <h5>Basic Details:</h5>
                                            </div>
                                            <div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12">
                                                <div className="request_label">
                                                    <div className="input_box input_box_margin">
                                                        <input type="text" className="form-control" id="your_reference" name='your_reference'
                                                            aria-describedby="emailHelp" required="required" />
                                                        <label htmlFor="your_reference" className="form-label">Your reference
                                                            (optional)</label>
                                                    </div>
                                                </div>

                                                <p className="font_16">Transport mode</p>
                                                <div className="row">
                                                    <div className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <div className="quotes_drop_1">
                                                            <Link className="dropdown-item btn btn_quotes_active" to="/requestquotes3"><img
                                                                    src="assets/images/icon/air-white.svg" alt="plane" /> Air </Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <div className="quotes_drop_1">
                                                            <Link className="dropdown-item btn btn_quotes" to="/requestquotessea"><img
                                                                    src="assets/images/icon/sea-blue.svg" alt="ship" /> Sea </Link>
                                                        </div>
                                                    </div>
                                                    <div className="col-4 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                        <div className="quotes_drop_1">
                                                            <Link className="dropdown-item btn btn_quotes" to="/requestquotesothers"><img
                                                                    src="assets/images/icon/truck-blue.svg" alt="truck" /> Road </Link>
                                                        </div>
                                                    </div>
                                                </div>

                                                <p className="font_16">Incoterm <a href="#" data-bs-toggle="modal"
                                                        data-bs-target="#exampleModal"><i
                                                            className="fa-solid fa-circle-info incoterm_icon"></i></a> </p>
                                                <div id='main_incoterm' className="main_incoterm">
                                                    <a className="btn btn_incoterm btn_quotes_incoterm_active 1" onClick={() => setIncoterm("EXW")}>EXW</a>
                                                    <a className="btn btn_incoterm 2" onClick={() => setIncoterm("FOB")}>FOB</a>
                                                    <a className="btn btn_incoterm 3" onClick={() => setIncoterm("CFR")}>CFR</a>
                                                    <a className="btn btn_incoterm 4" onClick={() => setIncoterm("DDP")}>DDP</a>
                                                    <div className="dropdown w-100">
                                                        <button className="btn btn_incoterm dropdown-toggle" type="button"
                                                            id="dropdownMenuButton1" data-bs-toggle="dropdown"
                                                            aria-expanded="false">
                                                            Others
                                                        </button>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li><a className="dropdown-item" href="/#">Action</a></li>
                                                            <li><a className="dropdown-item" href="/#">Another action</a></li>
                                                            <li><a className="dropdown-item" href="/#">Something else here</a></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="request_hr"></div>
                                        <div className="row">
                                            <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                <h5>Itinerary:</h5>
                                            </div>
                                            <div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12">
                                                <div className="row">
                                                    <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                        <div className="request_label">
                                                            <div className="input_box input_box_margin">
                                                                <input type="text" className="form-control" id="pickup_address" name='pick_address'
                                                                    aria-describedby="emailHelp" required="required" />
                                                                <label htmlFor="pickup_address" className="form-label">Pick up
                                                                    address</label>
                                                                <div id='pickup_address_error' className="hide">Invalid Field please add clear Info.</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                        <div className="request_label">
                                                            <div className="input_box input_box_margin">
                                                                <input type="text" className="form-control" id="delivery_address" name='delivery_address'
                                                                    aria-describedby="emailHelp" required="required" />
                                                                <label htmlFor="delivery_address" className="form-label">Delivery
                                                                    address</label>
                                                                <div id='delivery_address_error' className="hide">Invalid Field please add clear Info.</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                        <div className="request_label">
                                                            <div className="input_box input_box_margin">
                                                                <input type="text" className="form-control" id="airport_departure" name='airport_departure'
                                                                    aria-describedby="emailHelp" required="required" />
                                                                <label htmlFor="airport_departure" className="form-label">Airport of
                                                                    departure</label>
                                                                <div id='airport_departure_error' className="hide">Invalid Field please add clear Info.</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                        <div className="request_label">
                                                            <div className="input_box input_box_margin">
                                                                <input type="text" className="form-control" id="airport_arrival" name='airport_arrival'
                                                                    aria-describedby="emailHelp" required="required" />
                                                                <label htmlFor="airport_arrival" className="form-label">Airport of
                                                                    arrival</label>
                                                                <div id='airport_arrival_error' className="hide">Invalid Field please add clear Info.</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="request_hr"></div>
                                        <div className="cargo_margin">
                                            <div className="row">
                                                <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                                    <h5>Cargo:</h5>
                                                </div>
                                                <div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12">
                                                    <ul className="nav nav-tabs quotes_tabs ship_all_details_tab" id="myTab"
                                                        role="tablist">
                                                        <li className="nav-item ms-auto me-2" role="presentation">
                                                            <button className="nav-link" id="home-tab" data-bs-toggle="tab"
                                                                data-bs-target="#home" type="button" role="tab" aria-controls="home"
                                                                aria-selected="true"><i className="fa-solid fa-cube"></i></button>
                                                            <p className="quotes_total_blue">Total</p>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button className="nav-link" id="profile-tab" data-bs-toggle="tab"
                                                                data-bs-target="#profile" type="button" role="tab"
                                                                aria-controls="profile" aria-selected="false"><i
                                                                    className="fa-solid fa-coins"></i></button>
                                                            <p className="quotes_total_blue">Dimensions</p>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content" id="myTabContent">
                                                        <div className="tab-pane fade req_first_tab" id="home" role="tabpanel"
                                                            aria-labelledby="home-tab">
                                                            <div className='after-add-more'>
                                                                <div className="fadtab_header">
                                                                    <div className="input-group mb-3">
                                                                        <span className="input-group-text"> <i className="fa-solid fa-cube req_icon_size"></i>  </span>
                                                                        <input type="text" id='pieces' className="form-control" placeholder="5" />
                                                                        <span className="input-group-text">pc(s)</span>
                                                                        <label>Pieces</label>
                                                                        <div id='pieces_error' className="hide">Invalid Field</div>
                                                                    </div>
                                                                    <div className="input-group mb-3">
                                                                        <span className="input-group-text"><i className="fa-solid fa-weight-hanging req_icon_size"></i></span>
                                                                        <input type="text" id='weight' className="form-control" placeholder="1200" />
                                                                        <span className="input-group-text">
                                                                            <div className="dropdown">
                                                                                <select id='weight_type' className='btn dropdown-toggle grey_span_5'>
                                                                                    <option className="dropdown-item" value="kg">kg</option>
                                                                                    <option className="dropdown-item" value="gm">gm</option>
                                                                                    <option className="dropdown-item" value="tn">tn</option>
                                                                                </select>
                                                                            </div>
                                                                        </span>
                                                                        <label>Weight</label>
                                                                        <div id='weight_error' className="hide">Invalid Field</div>
                                                                    </div>
                                                                    <div className="input-group mb-3">
                                                                        <span className="input-group-text"><i className="fa-solid fa-text-height req_icon_size"></i> </span>
                                                                        <input type="text" id='volume' className="form-control" placeholder="5" />
                                                                        <span className="input-group-text">m<sup>3</sup> </span>
                                                                        <label>Volume</label>
                                                                        <div id='volume_error' className="hide">Invalid Field</div>
                                                                    </div>
                                                                    <div className="input-group mb-3">
                                                                        <span className="input-group-text">1200</span>
                                                                        <input type="text" className="form-control" value="1200" disabled />
                                                                        <span className="input-group-text">kg</span>
                                                                        <label>Chargable weight</label>
                                                                    </div>
                                                                    <div className="input-group mb-3">
                                                                        <span className="input-group-text">
                                                                            <div className="dropdown">
                                                                                <button className="btn dropdown-toggle grey_span_5" type="button" 
                                                                                    id="dropdownMenuButton1"
                                                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                                                    kg <img src="/assets/images/icon/down-arrow-grey.svg"
                                                                                        alt="down-arrow" />
                                                                                </button>
                                                                                <ul className="dropdown-menu"
                                                                                    aria-labelledby="dropdownMenuButton1">
                                                                                    <li><a className="dropdown-item" href="/#">gm</a>
                                                                                    </li>
                                                                                    <li><a className="dropdown-item" href="/#">tn</a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </span>
                                                                        <input type="text" className="form-control" disabled />
                                                                        <span className="input-group-text">
                                                                            <img src="assets/images/icon/down-arrow.svg" alt="down-arrow" />
                                                                        </span>
                                                                        <label>Type</label>
                                                                    </div>
                                                                </div>
                                                                <div className="request_hr"></div>
                                                                <div className="row">
                                                                    <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                        <h5 className='mb-4'>Cargo type:</h5>
                                                                        <div className="row">
                                                                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                                <div className="request_textarea">
                                                                                    <div className="input_box_contact form-floating-1 input_box_margin">
                                                                                        <textarea className="form-control" id="message_contact"></textarea>
                                                                                        <label htmlFor="message_contact" className="form-label">Goods
                                                                                            Description</label>
                                                                                        <div id='message_contact_error' className="hide">Invalid Field</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                                <div className="request_switch_1 form-check form-switch">
                                                                                    <input className="form-check-input" type="checkbox" id="is_insurance" />
                                                                                    <label className="form-check-label" htmlFor="is_insurance">Insurance</label>
                                                                                </div>
                                                                                <div className="request_label mt-3">
                                                                                    <div className="input_box input_box_margin">
                                                                                        <input type="text" className="form-control" id="goods_value"
                                                                                            aria-describedby="emailHelp" required="required" />
                                                                                        <label htmlFor="goods_value" className="form-label">Goods value</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                                <div className="request_switch form-check form-switch">
                                                                                    <input className="form-check-input" type="checkbox" id="is_dangerous" />
                                                                                    <label className="form-check-label" htmlFor="is_dangerous">Dangerous</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                                <div className="request_switch form-check form-switch">
                                                                                    <input className="form-check-input" type="checkbox" id="is_temperature_controlled" />
                                                                                    <label className="form-check-label" htmlFor="is_temperature_controlled">Temperature
                                                                                        controlled</label>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4">
                                                                                <div className="request_textarea">
                                                                                    <div className="input_box_contact form-floating-1 input_box_margin">
                                                                                        <textarea className="form-control" id="remark"
                                                                                    ></textarea>
                                                                                        <label htmlFor="remark" className="form-label">Remarks
                                                                                            (optional)</label>
                                                                                    </div>
                                                                                    <div id='remark_error' className="hide">Invalid Field</div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                                <div className="request_files">
                                                                                    <div className="drop-zone">
                                                                                        <span className="drop-zone__prompt">Drop file here or click to
                                                                                            upload</span>
                                                                                        <input type="file" name="myFile" className="drop-zone__input" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="row justify-content-center">
                                                                            <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                                                                <div className="signin_button signin_button_next mt-4">
                                                                                    {/* <button form='basic_details' type='submit' className="btn btn_signin w-100">Ask htmlFor a quote</button> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* Copy Div */}
                                                            <div className="copy hide" style={{ display: "none" }}>
                                                                <div id={`cargo${count}`} className='remove-add-more'>
                                                                    <div style={{ float: "right", margin: "7px" }}>
                                                                        <button className='btn btn-danger remove' onClick={minusOne}> X </button>
                                                                    </div>
                                                                    <div className="fadtab_header">
                                                                        <div className="input-group mb-3">
                                                                            <span className="input-group-text"> <i className="fa-solid fa-cube req_icon_size"></i>  </span>
                                                                            <input type="text" className="form-control" id={`pieces${count}`} placeholder="5" />
                                                                            <span className="input-group-text">pc(s)</span>
                                                                            <label>Pieces</label>
                                                                            <div id={`pieces_error${count}`} className="hide">Invalid Field</div>
                                                                        </div>
                                                                        <div className="input-group mb-3">
                                                                            <span className="input-group-text"><i className="fa-solid fa-weight-hanging req_icon_size"></i></span>
                                                                            <input type="text" className="form-control" id={`weight${count}`} placeholder="1200" />
                                                                            <span className="input-group-text">
                                                                                <div className="dropdown">
                                                                                    <select id={`weight_type${count}`} className='btn dropdown-toggle grey_span_5'>
                                                                                        <option className="dropdown-item" value="kg">kg</option>
                                                                                        <option className="dropdown-item" value="gm">gm</option>
                                                                                        <option className="dropdown-item" value="tn">tn</option>
                                                                                    </select>
                                                                                </div>
                                                                            </span>
                                                                            <label>Weight</label>
                                                                            <div id={`weight_error${count}`} className="hide">Invalid Field</div>
                                                                        </div>
                                                                        <div className="input-group mb-3">
                                                                            <span className="input-group-text"><i className="fa-solid fa-text-height req_icon_size"></i> </span>
                                                                            <input type="text" className="form-control" id={`volume${count}`} placeholder="5" />
                                                                            <span className="input-group-text">m<sup>3</sup> </span>
                                                                            <label>Volume</label>
                                                                            <div id={`volume_error${count}`} className="hide">Invalid Field</div>
                                                                        </div>
                                                                        <div className="input-group mb-3">
                                                                            <span className="input-group-text">1200</span>
                                                                            <input type="text" className="form-control" value="1200" disabled />
                                                                            <span className="input-group-text">kg</span>
                                                                            <label>Chargable weight</label>
                                                                        </div>
                                                                        <div className="input-group mb-3">
                                                                            <span className="input-group-text">
                                                                                <div className="dropdown">
                                                                                    <button className="btn dropdown-toggle grey_span_5" type="button" 
                                                                                        id="dropdownMenuButton1"
                                                                                        data-bs-toggle="dropdown" aria-expanded="false">
                                                                                        kg <img src="assets/images/icon/down-arrow-grey.svg"
                                                                                            alt="down-arrow" />
                                                                                    </button>
                                                                                    <ul className="dropdown-menu"
                                                                                        aria-labelledby="dropdownMenuButton1">
                                                                                        <li><a className="dropdown-item" href="/#">gm</a>
                                                                                        </li>
                                                                                        <li><a className="dropdown-item" href="/#">tn</a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </span>
                                                                            <input type="text" className="form-control" disabled />
                                                                            <span className="input-group-text">
                                                                                <img src="assets/images/icon/down-arrow.svg" alt="down-arrow" />
                                                                            </span>
                                                                            <label>Type</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className="request_hr"></div>
                                                                    <div className="row">
                                                                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                            <h5 className='mb-4'>Cargo type:</h5>
                                                                            <div className="row">
                                                                                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                                    <div className="request_textarea">
                                                                                        <div className="input_box_contact form-floating-1 input_box_margin">
                                                                                            <textarea className="form-control" id={`message_contact${count}`}></textarea>
                                                                                            <label htmlFor={`message_contact${count}`} name="desc" className="form-label">Goods
                                                                                                Description</label>
                                                                                            <div id={`message_contact_error${count}`} className="hide">Invalid Field</div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                                    <div className="request_switch_1 form-check form-switch">
                                                                                        <input className="form-check-input" type="checkbox" id={`is_insurance${count}`} />
                                                                                        <label className="form-check-label" htmlFor="is_insurance">Insurance</label>
                                                                                    </div>
                                                                                    <div className="request_label mt-3">
                                                                                        <div className="input_box input_box_margin">
                                                                                            <input type="text" className="form-control" id={`goods_value${count}`}
                                                                                                aria-describedby="emailHelp" required="required" />
                                                                                            <label htmlFor={`goods_value${count}`} className="form-label">Goods value</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                                    <div className="request_switch form-check form-switch">
                                                                                        <input className="form-check-input" type="checkbox" id={`is_dangerous${count}`} />
                                                                                        <label className="form-check-label" htmlFor="is_dangerous">Dangerous</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-4">
                                                                                    <div className="request_switch form-check form-switch">
                                                                                        <input className="form-check-input" type="checkbox" id={`is_temperature_controlled${count}`} />
                                                                                        <label className="form-check-label" htmlFor="is_temperature_controlled">Temperature
                                                                                            controlled</label>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-4">
                                                                                    <div className="request_textarea">
                                                                                        <div className="input_box_contact form-floating-1 input_box_margin">
                                                                                            <textarea className="form-control" id={`remark${count}`}
                                                                                        ></textarea>
                                                                                            <label htmlFor="exampleInputEmail1" name="remark" className="form-label">Remarks
                                                                                                (optional)</label>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                                                    <div className="request_files">
                                                                                        <div className="drop-zone">
                                                                                            <span className="drop-zone__prompt">Drop file here or click to
                                                                                                upload</span>
                                                                                            <input type="file" name="myFile" className="drop-zone__input" />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="row justify-content-center">
                                                                                <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                                                                    <div className="signin_button signin_button_next mt-4">
                                                                                        {/* <button form='basic_details' type='submit' className="btn btn_signin w-100">Ask htmlFor a quote</button> */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="req_fad_btn">
                                                                <a className="btn btn_req_add add-more" onClick={plusOne}>Add <i
                                                                        className="fa-solid fa-plus"></i></a>
                                                            </div>
                                                        </div>
                                                        <div className="tab-pane fade main_piece_bg" id="profile" role="tabpanel"
                                                            aria-labelledby="profile-tab">

                                                            <div className="fadtab_header">
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text"><i
                                                                            className="fa-solid fa-cube req_icon_size"></i>4</span>
                                                                    <input type="text" className="form-control" disabled />
                                                                    <span className="input-group-text">pc(s)</span>
                                                                    <label>Pieces</label>
                                                                </div>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text"><i
                                                                            className="fa-solid fa-weight-hanging req_icon_size"></i>380</span>
                                                                    <input type="text" className="form-control" disabled />
                                                                    <span className="input-group-text">kg</span>
                                                                    <label>Weight</label>
                                                                </div>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text"><i
                                                                            className="fa-solid fa-text-height req_icon_size"></i>3.84</span>
                                                                    <input type="text" className="form-control" disabled />
                                                                    <span className="input-group-text">m<sup>3</sup> </span>
                                                                    <label>Volume</label>
                                                                </div>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text">640</span>
                                                                    <input type="text" className="form-control" disabled />
                                                                    <span className="input-group-text">kg</span>
                                                                    <label>Chargable weight</label>
                                                                </div>
                                                                <div className="input-group mb-3">
                                                                    <span className="input-group-text">
                                                                        <div className="dropdown">
                                                                            <button
                                                                                className="btn dropdown-toggle w-100 d-flex justify-content-between align-items-center"
                                                                                type="button" id="dropdownMenuButton1"
                                                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                                                <p className="mb-0">pallet</p>

                                                                            </button>
                                                                            <ul className="dropdown-menu w-100 list_contactinput_ul"
                                                                                aria-labelledby="dropdownMenuButton1">
                                                                                <li><a className="dropdown-item" href="#">carton</a>
                                                                                </li>
                                                                                <li><a className="dropdown-item" href="#">crate</a></li>
                                                                                <li className="list_contactinput">
                                                                                    <div className="input_box">
                                                                                        <input type="text" className="form-control"
                                                                                            id="exampleInputEmail1" name='others'
                                                                                            placeholder="other"
                                                                                            aria-describedby="emailHelp"
                                                                                            required="required" />
                                                                                        <label htmlFor="exampleInputEmail1"
                                                                                            className="form-label"></label>
                                                                                    </div>
                                                                                </li>
                                                                            </ul>
                                                                        </div>
                                                                    </span>
                                                                    <input type="text" className="form-control" disabled />
                                                                    <span className="input-group-text">
                                                                        <img src="/assets/images/icon/down-arrow.svg"
                                                                            alt="down-arrow" />
                                                                    </span>
                                                                    <label>Type</label>
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-md-2">
                                                                    <div className="piece_heading">
                                                                        <span>Pieces</span>
                                                                    </div>
                                                                    <ul className="piece_li">
                                                                        <li>
                                                                            <p>1</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>3</p>
                                                                        </li>
                                                                        <li>
                                                                            <p>1</p>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="piece_heading">
                                                                        <div className="dimen_fle">
                                                                            <span>Dimensions</span>
                                                                            <div className="dropdown">
                                                                                <button className="btn dropdown-toggle" type="button"
                                                                                    id="dropdownMenuButton1"
                                                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                                                    cm <img
                                                                                        src="assets/images/icon/down-arrow-white.svg"
                                                                                        alt="down-arrow" />
                                                                                </button>
                                                                                <ul className="dropdown-menu"
                                                                                    aria-labelledby="dropdownMenuButton1">
                                                                                    <li><a className="dropdown-item" href="#">m</a></li>
                                                                                    <li><a className="dropdown-item" href="#">km</a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="piece_dimensions">
                                                                        <p>120</p>
                                                                        <p>80</p>
                                                                        <p>100</p>
                                                                    </div>
                                                                    <div className="piece_dimensions">
                                                                        <p>80</p>
                                                                        <p>120</p>
                                                                        <p>100</p>
                                                                    </div>
                                                                    <div className="piece_dimensions">
                                                                        <p className="font_12">Length</p>
                                                                        <p className="font_12">Width</p>
                                                                        <p className="font_12">Height</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="piece_heading">
                                                                        <div className="dimen_fle">
                                                                            <span>Weight</span>
                                                                            <div className="dropdown">
                                                                                <button className="btn dropdown-toggle" type="button"
                                                                                    id="dropdownMenuButton1"
                                                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                                                    kg <img
                                                                                        src="assets/images/icon/down-arrow-white.svg"
                                                                                        alt="down-arrow" />
                                                                                </button>
                                                                                <ul className="dropdown-menu"
                                                                                    aria-labelledby="dropdownMenuButton1">
                                                                                    <li><a className="dropdown-item" href="#">gm</a>
                                                                                    </li>
                                                                                    <li><a className="dropdown-item" href="#">tn</a>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="piece_30 piece_dimensions">
                                                                        <p>150</p>
                                                                        <span className="switch">
                                                                            <input type="checkbox" id="switcher" checked />
                                                                            <label htmlFor="switcher"></label>
                                                                        </span>
                                                                    </div>
                                                                    <div className="piece_30 piece_dimensions">
                                                                        <p>230</p>
                                                                        <span className="switch">
                                                                            <input type="checkbox" id="switcher" />
                                                                            <label htmlFor="switcher"></label>
                                                                        </span>
                                                                    </div>
                                                                    <div className="piece_30 piece_dimensions">
                                                                        <p className="font_12">Width</p>
                                                                        <span className="switch">
                                                                            <input type="checkbox" id="switcher" checked />
                                                                            <label htmlFor="switcher"></label>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-4">
                                                                    <div className="check_box_1">
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox"
                                                                                id="inlineCheckbox1" value="option1" />
                                                                            <label className="form-check-label"
                                                                                htmlFor="inlineCheckbox1">non-stackable</label>
                                                                        </div>
                                                                        <a href="#">
                                                                            <i className="fa-solid fa-trash incoterm_icon"></i>
                                                                        </a>
                                                                    </div>
                                                                    <div className="check_box_1">
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox"
                                                                                id="inlineCheckbox1" value="option1" />
                                                                            <label className="form-check-label"
                                                                                htmlFor="inlineCheckbox1">non-stackable</label>
                                                                        </div>
                                                                        <a href="#">
                                                                            <i className="fa-solid fa-trash incoterm_icon"></i>
                                                                        </a>
                                                                    </div>
                                                                    <div className="check_box_1">
                                                                        <div className="form-check form-check-inline">
                                                                            <input className="form-check-input" type="checkbox"
                                                                                id="inlineCheckbox1" value="option1" />
                                                                            <label className="form-check-label"
                                                                                htmlFor="inlineCheckbox1">non-stackable</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="req_fad_btn">
                                                                <a href="#" className="btn btn_req_add">Add <i
                                                                        className="fa-solid fa-plus"></i></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="request_hr"></div>
                                        <div className="row">
                                            <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                                <div className="row justify-content-center">
                                                    <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-6">
                                                        <div className="signin_button signin_button_next">
                                                            <button type='submit' className="btn btn_signin w-100">Ask htmlFor a quote</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </section>
                        </section>
                    </div>
                </div>
            </main>

            <div className="modal" id="exampleModal" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered request_modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <img src="/assets/images/page-image/incoterms.png" className="w-100" alt="graph" />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <Helmet>
                <script>
                    {
                        `
                        $(".add-more").click(function(){ 
                            var html = $(".copy").html();
                            $(".after-add-more").after(html);
                            
                        });

                        $("body").on("click",".remove",function(){ 
                            $(this).parents(".remove-add-more").remove();
                        });

                        $(document).ready(function(){
                            $('.2').on('click', function(){
                                $('a.2').addClass('btn_quotes_incoterm_active');
                                $('a.1').removeClass('btn_quotes_incoterm_active');
                                $('a.3').removeClass('btn_quotes_incoterm_active');
                                $('a.4').removeClass('btn_quotes_incoterm_active');
                            })
                            $('.1').on('click', function(){
                                $('a.1').addClass('btn_quotes_incoterm_active');
                                $('a.2').removeClass('btn_quotes_incoterm_active');
                                $('a.3').removeClass('btn_quotes_incoterm_active');
                                $('a.4').removeClass('btn_quotes_incoterm_active');
                            })
                            $('.3').on('click', function(){
                                $('a.3').addClass('btn_quotes_incoterm_active');
                                $('a.2').removeClass('btn_quotes_incoterm_active');
                                $('a.1').removeClass('btn_quotes_incoterm_active');
                                $('a.4').removeClass('btn_quotes_incoterm_active');
                            })
                            $('.4').on('click', function(){
                                $('a.4').addClass('btn_quotes_incoterm_active');
                                $('a.2').removeClass('btn_quotes_incoterm_active');
                                $('a.3').removeClass('btn_quotes_incoterm_active');
                                $('a.1').removeClass('btn_quotes_incoterm_active');
                            })
                        });
                        `
                    }
                </script>
            </Helmet>
        </>
    )
}
