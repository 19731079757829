import moment from 'moment';
import React, {useState, useEffect} from 'react';
import { Link, useParams } from 'react-router-dom';
import { getShipmentDetail } from '../../api/apiHandler';
import Sidebar from '../../components/customerComponents/Sidebar/Sidebar';
import Topbar from '../../components/customerComponents/Topbar/Topbar';
import { useTranslation } from "react-i18next";
import { Helmet } from 'react-helmet';

export default function TrackParcel() {
    const commonProps = {title: "shipment"}

    var [data, setData] = useState([]);

    let { id } = useParams();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        getShipmentDetail({"shipment_id": id}).then((response)=>{
            response = JSON.parse(response);
            console.log("======>>", response.data[0]);
            if(response.code == 1){
                setData(response.data[0]);
            }else{
                console.log("null");
            }
        });
    }, []);

    var date = moment(data.date).format('Do MMMM, YYYY');
    var ETD = moment(data.ETD).format('Do MMMM, YYYY');
    var ETA = moment(data.ETA).format('Do MMMM, YYYY');


    return (
        <>
            <Helmet>
                <title>{t('shipment_tracking')}</title>
            </Helmet>
            <main className="dash_b">
                <div className="container-fluid">
                    <div id="wrapper">
                        <Sidebar commonProps={commonProps} />

                        <section id="content-wrapper">
                            <Topbar />

                            <section className="dashboard_area">
                                <div className="row justify-content-center align-items-center">
                                    <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                        <div className="root_body">
                                            <div className="root">
                                                <div className="back_shipment">
                                                    <Link to="/shipment">
                                                        <i className="fa-solid fa-chevron-left"></i>
                                                    </Link>
                                                    <h4>{t('shipment_tracking')}</h4>
                                                </div>
                                                <div className="order-track">
                                                    <div className="order-track-step">
                                                        <div className="order-track-status">
                                                            <span
                                                                className="order-track-status-dot-green order-track-status-dot"></span>
                                                            <span className="order-track-status-line"></span>
                                                        </div>
                                                        <div className="order-track-text">
                                                            <p className="order-track-text-stat">{t('order_received')}</p>
                                                            <span className="order-track-text-sub">{date}</span>
                                                        </div>
                                                    </div>
                                                    <div className="order-track-step">
                                                        <div className="order-track-status">
                                                            <span
                                                                className="order-track-status-dot-green order-track-status-dot"></span>
                                                            <span className="order-track-status-line"></span>
                                                        </div>
                                                        <div className="order-track-text">
                                                            <p className="order-track-text-stat">{t('order_shipped')}</p>
                                                            <span className="order-track-text-sub">{ETD}</span>
                                                        </div>
                                                    </div>
                                                    <div className="order-track-step">
                                                        <div className="order-track-status">
                                                            <span
                                                                className="order-track-status-dot-green order-track-status-dot"></span>
                                                            <span className="order-track-status-line"></span>
                                                        </div>
                                                        <div className="order-track-text">
                                                            <p className="order-track-text-stat">{t('order_out_of_delivery')}</p>
                                                            <span className="order-track-text-sub">{ETA}</span>
                                                        </div>
                                                    </div>
                                                    <div className="order-track-step">
                                                        <div className="order-track-status">
                                                            <span
                                                                className="order-track-status-dot-green order-track-status-dot"></span>
                                                            <span className="order-track-status-line"></span>
                                                        </div>
                                                        <div className="order-track-text">
                                                            <p className="order-track-text-stat">{t('order_arrived')}</p>
                                                            <span className="order-track-text-sub">21st August, 2019</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </section>
                    </div>
                </div>
            </main>
        </>
    )
}
