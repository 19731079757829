import React, {useState} from 'react';
import { Link } from 'react-router-dom';
// import { showProfile } from '../../../api/apiHandler';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from "react-i18next";
import { mainSearch, mainFilter } from '../../../api/apiHandler';

export default function Topbar() {
    var [modelData, setModelData] = useState(null);
    var [modelFilterData, setModelFilterData] = useState(null);
    var [isdata, setIsData] = useState(null);
    var [isFilterData, setIsFilterData] = useState(null);
    const { t, i18n } = useTranslation();
    var [language, setLanguage] = useState("en");

    // useEffect(() => {
    //     showProfile().then((response)=>{
    //         response = JSON.parse(response);
    //         if(response.code == 1){
    //             setData(response.data)
    //         }else{
    //             console.log("null");
    //         }
    //     });
    // }, []);
    var data = JSON.parse(localStorage.getItem("userDetail"))

    const searchBar = (data) => {
        setModelData(data)
        var params = {
            search: data
        }
        mainSearch(params).then((response) => {
            response = JSON.parse(response);
            if(response.code == 1){
                setIsData(response.data);
            }
            else{
                console.log("Null")
            }
        })
    }

    const changeLanguageHandler = (e) => {
        const languageValue = e.target.value
        i18n.changeLanguage(languageValue);
        setLanguage(languageValue)
    }
    
    const validationSchema = Yup.object().shape({
        our_reference: Yup.string(),
        your_reference: Yup.string(),
        // cargo_type: Yup.object({}),
        // air: Yup.boolean(),
        // sea: Yup.boolean(),
        types: Yup.array().of(Yup.string()),
        date: Yup.string().notRequired(),
        origin: Yup.string(),
        // responded: Yup.boolean(),
        // pending: Yup.boolean(),
        // expired: Yup.boolean(),
        status: Yup.array().of(Yup.string()),
        validity_date: Yup.string().notRequired(),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };
    
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    const onSubmit = (data) => {
        // console.log("------------->", data)
        setModelFilterData(data)
        mainFilter(data).then((response) => {
            response = JSON.parse(response);
            if(response.code == 1){
                setIsFilterData(response.data);
                console.log("---------", response)
            }
            else{
                console.log("Null")
            }
        })
    }

    const myFun = () => {
        var wrapper = document.getElementById("wrapper");
        wrapper.classList.toggle('toggled');
    }

    return (
        <>
            <header className="main_header">
                <div className="row align-items-center">
                    <div className="col-1 col-xl-1 col-lg-1 col-md-1 col-sm-1 w-sm-auto">
                        <span type="button" id="sidebar-toggle" onClick={() => myFun()}>
                            <img src={process.env.PUBLIC_URL + '/assets/images/icon/nav.svg'} className="ms-sm-2" alt="nav" />
                        </span>
                    </div>
                    <div className="col-4 col-xl-6 col-lg-4 col-md-4 col-sm-4">
                        <div className="header_search">
                            <div className="input-group header_search_hide">
                                <span type="button" className="input-group-text">
                                    <img src={process.env.PUBLIC_URL + '/assets/images/icon/search.svg'}  alt="search" />
                                </span>
                                <input type="text" id="search" className="form-control" autoComplete='off' placeholder={t('search')} onChange={() => searchBar(document.getElementById("search").value)}
                                    aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                <span className="input-group-text" id="basic-addon2">
                                    <div className="dropdown quote_dropdown dropstart">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/filter_list.svg"}
                                            className="me-1 dropdown-toggle" alt="search" href="/#" type="button"
                                            id="dropdownMenuLink" data-bs-toggle="dropdown"
                                            aria-expanded="false" data-bs-offset="10,20"
                                            data-bs-auto-close="outside" />
                                        <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                            <form className="px-4 py-3" onSubmit={handleSubmit(onSubmit)} noValidate>
                                                <div className="dropdown quote_button mb-3">
                                                    <select className="btn btn-outline-secondary dropdown-toggle" {...register('our_reference')} aria-label="Default select example">
                                                        <option value="">{t('our_reference')}<img
                                                            src={process.env.PUBLIC_URL + "/assets/images/icon/down-arrow.svg"}
                                                            alt="down-arrow" /></option>
                                                        <option>Mukesh Ambani</option>
                                                        <option>Elon Musk</option>
                                                        <option>Gautam Adani</option>
                                                    </select>
                                                    <div className="invalid-feedback">{errors.our_reference?.message}</div>
                                                </div>
                                                <div className="mb-3 mt-4 q_drop_in">
                                                    <div className="input_box">
                                                        <input type="text" className="form-control"
                                                            id="exampleInputEmail1" aria-describedby="emailHelp"
                                                            required="required" {...register('your_reference')} />
                                                        <label htmlFor="exampleInputEmail1" className="form-label">{t('your_reference')}</label>
                                                        <div className="invalid-feedback">{errors.your_reference?.message}</div>
                                                    </div>
                                                </div>
                                                <p className="mb-1">{t('type')}</p>
                                                {/* <div {...register('cargo_type')}> */}
                                                    <div className="form-check form-check-inline mb-3">
                                                        <input className="form-check-input" type="checkbox" {...register('types')}
                                                            id="inlineCheckbox1" defaultValue="Air" />
                                                        <label className="form-check-label"
                                                            htmlFor="inlineCheckbox1">{t('air')}</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="checkbox" {...register('types')}
                                                            id="inlineCheckbox2" defaultValue="Sea" />
                                                        <label className="form-check-label"
                                                            htmlFor="inlineCheckbox2">{t('sea')}</label>
                                                    </div>
                                                    {/* <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="checkbox" {...register('road')}
                                                            id="inlineCheckbox3" value="true" />
                                                        <label className="form-check-label"
                                                            htmlFor="inlineCheckbox3">{t('other')}</label>
                                                    </div> */}
                                                {/* </div> */}
                                                <div className="mb-3">
                                                    <label htmlFor="birthday" className="me-2">{t('date')}</label>
                                                    <input type="date" id="birthday" name="birthday" {...register('date')}
                                                        className="date_input" />
                                                </div>
                                                <select className="form-select mb-3" {...register('origin')} aria-label="Default select example">
                                                    <option value="">{t('origin')}</option>
                                                    <option>Mumbai</option>
                                                    <option>Delhi</option>
                                                    <option>Chennai</option>
                                                </select>
                                                <p className="mb-1">{t('status')}</p>
                                                {/* <div {...register('status')}> */}
                                                    <div className="form-check form-check-inline mb-3">
                                                        <input className="form-check-input" type="checkbox" {...register('status')}
                                                            id="inlineCheckbox1" defaultValue="Responded" />
                                                        <label className="form-check-label"
                                                            htmlFor="inlineCheckbox1">{t('responded')}</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="checkbox" {...register('status')}
                                                            id="inlineCheckbox2" defaultValue="Pending" />
                                                        <label className="form-check-label"
                                                            htmlFor="inlineCheckbox2">{t('pending')}</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <input className="form-check-input" type="checkbox" {...register('status')}
                                                            id="inlineCheckbox3" defaultValue="Expired" />
                                                        <label className="form-check-label"
                                                            htmlFor="inlineCheckbox3">{t('expired')}</label>
                                                    </div>
                                                {/* </div> */}
                                                <div className="mb-3">
                                                    <label htmlFor="birthday" className="me-2">{t('validity_date')}</label>
                                                    <input type="date" id="birthday" name="birthday" {...register('validity_date')}
                                                        className="date_input" />
                                                </div>
                                                <div className="quote_apply mt-2">
                                                    <button type='submit' className="dropdown-item btn btn_quote_apply">{t('apply')}</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className="col-7 col-xl-5 col-lg-7 col-md-7 col-sm-7">
                        <div className="main_language">
                            <select className="form-select w-auto ms-auto" value={language} aria-label="Default select example" onChange={changeLanguageHandler}>
                                <option value="en">English</option>
                                <option value="fr">Français</option>
                            </select>
                            <div className="notification d-flex align-items-center">
                                <div className="notification_icon">
                                    <a href="/#">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icon/bell-notification.svg"} alt="notification" />
                                    </a>
                                </div>
                                <Link to="/account" className="user_image d-flex align-items-center">
                                    {/* <img src={process.env.PUBLIC_URL + "/assets/images/user/user-1.svg"} alt="user" /> */}
                                    <p className="mb-0">{t('welcome')} <br />
                                        <span>{data.first_name} {data.last_name}</span>
                                    </p>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

            {modelData == "" || modelData == null || modelData == undefined ? 
                <></>
            :
                <>
                 {/* style={{position: "relative", height: "100%", width: "100%"}} */}
                    <div style={{position: "relative", display: 'block', top: '5px', left: "0px", width: '100%', height: "100%", zIndex:"9999999" + "!important", padding: "10px", overflow: "hidden", visibility: "visible"}}>
                        <div style={{marginBottom: "30px"}}>
                            <h3>Quotes</h3>
                            <table id='mangeUser' className="table table-hover mb-0">
                                <thead key="thead">
                                    <tr className="table_header_bg">
                                        <th scope="col">{t('your_reference')} <img
                                                src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /> </th>
                                        <th scope="col">{t('our_reference')} <img
                                                src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /> </th>
                                        <th scope="col">{t('date')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                alt="filter" /> </th>
                                        <th scope="col">{t('origin')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                alt="filter" /> </th>
                                        <th scope="col">{t('destination')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                alt="filter" /> </th>
                                        <th scope="col">{t('status')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                alt="filter" /> </th>
                                    </tr>
                                </thead>
                                {isdata != undefined ?
                                    <tbody key="tbody">
                                        {isdata[0] && isdata[0].map((quotedata, key) => {
                                            return(
                                                <tr className="table-row" key={key} onClick={e => {
                                                        if (e.target.id === 'action' || e.target.id === 'editquote' || e.target.id === 'contract') {
                                                            return;
                                                        }
                                                        else{
                                                            // routeChange(quotedata._id)
                                                        }
                                                    }}>
                                                    <td>
                                                        {quotedata.transport_mode == "Air" ?
                                                            <img src={process.env.PUBLIC_URL +"/assets/images/icon/air-blue.svg"} className="me-1" alt="air" />
                                                        : quotedata.transport_mode == "Sea" ?
                                                            <img src={process.env.PUBLIC_URL +"/assets/images/icon/sea-blue.svg"} className="me-2" alt="sea" />
                                                        :
                                                            <img src={process.env.PUBLIC_URL +"/assets/images/icon/truck-blue.svg"} className="me-2" alt="truck" />
                                                        }
                                                        {quotedata.your_reference}
                                                    </td>
                                                    <td>{quotedata.our_reference}</td>
                                                    <td>
                                                        {quotedata.date}
                                                    </td>
                                                    <td>{quotedata.airport_of_departure}</td>
                                                    <td>{quotedata.airport_of_arrival}</td>
                                                    <td> 
                                                        {quotedata.quote_status == "Expired" ? 
                                                            <span className="table_expire inhe_table">{t('expired')}</span> 
                                                        : quotedata.quote_status == "Pending" ? 
                                                            <span className="table_pending inhe_table">{t('pending')}</span> 
                                                        : 
                                                            <span className="table_respond inhe_table">{t('responded')}</span>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                :
                                    <tbody>
                                        <tr>
                                            <td colSpan="7">
                                                <div className="folder_image m-auto text-center">
                                                    <img src={process.env.PUBLIC_URL +"/assets/images/icon/folder.svg"} alt="folder" />
                                                </div>
                                                <div className="folder_image m-auto text-center">
                                                    <p className="folder_grey">{t('no_data')}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                }
                            </table>
                        </div>
                        <div style={{marginBottom: "30px"}}>
                            <h3>Shipments</h3>
                            <table className="table table-hover mb-0">
                                <thead>
                                    <tr className="table_header_bg">
                                        <th scope="col">{t('our_reference')} <img
                                                src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                        <th scope="col">{t('your_reference')} <img
                                                src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                        <th scope="col">{t('date')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                alt="filter" /></th>
                                        <th scope="col">{t('origin')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                alt="filter" /></th>
                                        <th scope="col">{t('destination')} <img
                                                src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                        <th scope="col">{t('cargo_type')} <img
                                                src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                        <th scope="col">{t('awb')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                alt="filter" /></th>
                                        <th scope="col">{t('flight_no')} <img
                                                src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                        <th scope="col">ETD <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                alt="filter" /></th>
                                        <th scope="col">ETA <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                alt="filter" /></th>
                                        <th scope="col">{t('status')}</th>
                                        {/* <th scope="col">{t('pod')}</th> */}
                                        {/* <th scope="col">Action</th> */}
                                    </tr>
                                </thead>
                                {isdata != undefined ?
                                    <tbody>
                                        {isdata[1] && isdata[1].map((shipmentdata, key) => {
                                            return(
                                                <>
                                                    <tr className="table-row" key={key} onClick={e => {
                                                        if (e.target.id === 'track-parcel' || e.target.id === 'contract') {
                                                            return;
                                                        }
                                                        else{
                                                            // routeChange(shipmentdata._id)
                                                        }
                                                        }}>
                                                        <td>
                                                            {shipmentdata.cargo_type == "Air" ? 
                                                                <img src={process.env.PUBLIC_URL +"/assets/images/icon/air-blue.svg"} className="me-1" alt="plane" />
                                                            : shipmentdata.cargo_type == "Sea" ?
                                                                <img src={process.env.PUBLIC_URL +"/assets/images/icon/sea-blue.svg"} className="me-1" alt="ship" />
                                                            :
                                                                <img src={process.env.PUBLIC_URL +"/assets/images/icon/truck-blue.svg"} className="me-1" alt="truck" />
                                                            }
                                                            {shipmentdata.our_reference}
                                                        </td>
                                                        <td>{shipmentdata.your_reference}</td>
                                                        <td>{shipmentdata.date}</td>
                                                        <td>{shipmentdata.origin}</td>
                                                        <td>{shipmentdata.destination}</td>
                                                        <td>{shipmentdata.cargo_type}</td>
                                                        <td>{shipmentdata.AWB}</td>
                                                        <td>{shipmentdata.flight_no}</td>
                                                        <td><span>{shipmentdata.ETD}</span></td>
                                                        <td><span>{shipmentdata.ETA}</span></td>
                                                        <td>
                                                            {shipmentdata.status == "Orderd" ? 
                                                                <span className="table_respond inhe_table">{t('ordered')}</span>
                                                            : shipmentdata.status == "Shipped" ?
                                                                <span className="table_pending inhe_table">{t('shipped')}</span>
                                                            : shipmentdata.status == "Out of delivery" ?
                                                                <span className="table_expire inhe_table">{t('out_of_delivery')}</span>
                                                            :
                                                                <span className="table_arrived inhe_table">{t('arrived')}</span>
                                                            }
                                                        </td>
                                                        {/* <td><a id={"contract"} onClick={() => contractdetails(key)} className="btn_view"> <i
                                                                    className="fa-solid fa-download me-1"></i>{t('download')}</a></td>
                                                        <td><Link id={"track-parcel"} to={`/track-parcel/${shipmentdata._id}`} className="btn_view">Track parcel</Link> </td> */}
                                                    </tr>
                                                </>
                                            )
                                        })}
                                    </tbody>
                                :
                                    <tbody>
                                        <tr>
                                            <td colSpan="13">
                                                <div className="folder_image m-auto text-center">
                                                    <img src={process.env.PUBLIC_URL +"/assets/images/icon/folder.svg"} alt="folder" />
                                                </div>
                                                <div className="folder_image m-auto text-center">
                                                    <p className="folder_grey">{t('no_data')}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                }
                            </table>
                        </div>
                        <div style={{marginBottom: "30px"}}>
                            <h3>Alerts</h3>
                            <table className="table table-hover table-condensed mb-0">
                                <thead>
                                    <tr className="table_header_bg">
                                        <th scope="col">{t('your_reference')} <img
                                                src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /> </th>
                                        <th scope="col">{t('our_reference')} <img
                                                src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /> </th>
                                        <th scope="col">{t('date')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                alt="filter" /> </th>
                                        <th scope="col">{t('origin')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                alt="filter" /> </th>
                                        <th scope="col">{t('destination')} <img
                                                src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /> </th>
                                        <th scope="col">{t('status')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                alt="filter" /> </th>
                                        {/* <th scope="col" className="action_w_0">Actions</th> */}
                                    </tr>
                                </thead>
                                {isdata != undefined ?
                                    <tbody>
                                        {isdata[2] && isdata[2].map((alertdata) => {
                                            return(
                                                <>
                                                    <tr className="sub-container">
                                                        <td> 
                                                            {alertdata.cargo_type == "Air" ?
                                                                <img src={process.env.PUBLIC_URL +"/assets/images/icon/air-blue.svg"} className="me-1" alt="air" />
                                                            : alertdata.cargo_type == "Sea" ?
                                                                <img src={process.env.PUBLIC_URL +"/assets/images/icon/sea-blue.svg"} className="me-2" alt="sea" />
                                                            :
                                                                <img src={process.env.PUBLIC_URL +"/assets/images/icon/truck-blue.svg"} className="me-2" alt="truck" />
                                                            }
                                                            <img src={process.env.PUBLIC_URL +"/assets/images/icon/right-arrow.svg"} className="exploder" id="arrow"
                                                                alt="arrow" />{alertdata.our_reference}
                                                        </td>
                                                        <td>{alertdata.your_reference}</td>
                                                        <td>
                                                            {alertdata.date}
                                                        </td>
                                                        <td>{alertdata.origin}</td>
                                                        <td>{alertdata.destination}</td>
                                                        <td>
                                                            {alertdata.status == "Review Invoice" ?
                                                                <span className="table_respond inhe_table">{alertdata.status}</span>
                                                            : alertdata.status == "Missing Document" ?
                                                                <span className="table_pending inhe_table">{alertdata.status}</span>
                                                            :
                                                                <span className="table_expire inhe_table">{alertdata.status}</span>
                                                            }
                                                        </td>
                                                        {/* <td className="d-flex align-items-center">
                                                            <p className="mb-0 me-2 text-start">
                                                                <a href="/#" className="btn_view w-auto"> <img
                                                                        src={process.env.PUBLIC_URL +"/assets/images/icon/edit-white.svg"} alt="Edit" /> {t('edit')}</a>
                                                            </p>
                                                        </td> */}
                                                    </tr>
                                                </>
                                            )
                                        })}
                                    </tbody>
                                :
                                    <tbody>
                                        <tr>
                                            <td colSpan="7">
                                                <div className="folder_image m-auto text-center">
                                                    <img src={process.env.PUBLIC_URL +"/assets/images/icon/folder.svg"} alt="folder" />
                                                </div>
                                                <div className="folder_image m-auto text-center">
                                                    <p className="folder_grey">{t('no_data')}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                }
                            </table>
                        </div>
                    </div>
                </>
            }

            {modelFilterData == "" || modelFilterData == null || modelFilterData == undefined ?
                <></>
            :
                <>
                    <div style={{position: "relative", display: 'block', top: '5px', left: "0px", width: '100%', height: "100%", zIndex:"9999999" + "!important", padding: "10px", overflow: "hidden", visibility: "visible"}}>
                        <div style={{marginBottom: "30px"}}>
                            <h3>Quotes</h3>
                            <table id='mangeUser' className="table table-hover mb-0">
                                <thead key="thead">
                                    <tr className="table_header_bg">
                                        <th scope="col">{t('your_reference')} <img
                                                src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /> </th>
                                        <th scope="col">{t('our_reference')} <img
                                                src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /> </th>
                                        <th scope="col">{t('date')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                alt="filter" /> </th>
                                        <th scope="col">{t('origin')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                alt="filter" /> </th>
                                        <th scope="col">{t('destination')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                alt="filter" /> </th>
                                        <th scope="col">{t('status')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                alt="filter" /> </th>
                                    </tr>
                                </thead>
                                {isFilterData != undefined ?
                                    <tbody key="tbody">
                                        {isFilterData[0] && isFilterData[0].map((quotedata, key) => {
                                            return(
                                                <tr className="table-row" key={key} onClick={e => {
                                                        if (e.target.id === 'action' || e.target.id === 'editquote' || e.target.id === 'contract') {
                                                            return;
                                                        }
                                                        else{
                                                            // routeChange(quotedata._id)
                                                        }
                                                    }}>
                                                    <td>
                                                        {quotedata.transport_mode == "Air" ?
                                                            <img src={process.env.PUBLIC_URL +"/assets/images/icon/air-blue.svg"} className="me-1" alt="air" />
                                                        : quotedata.transport_mode == "Sea" ?
                                                            <img src={process.env.PUBLIC_URL +"/assets/images/icon/sea-blue.svg"} className="me-2" alt="sea" />
                                                        :
                                                            <img src={process.env.PUBLIC_URL +"/assets/images/icon/truck-blue.svg"} className="me-2" alt="truck" />
                                                        }
                                                        {quotedata.your_reference}
                                                    </td>
                                                    <td>{quotedata.our_reference}</td>
                                                    <td>
                                                        {quotedata.date}
                                                    </td>
                                                    <td>{quotedata.airport_of_departure}</td>
                                                    <td>{quotedata.airport_of_arrival}</td>
                                                    <td> 
                                                        {quotedata.quote_status == "Expired" ? 
                                                            <span className="table_expire inhe_table">{t('expired')}</span> 
                                                        : quotedata.quote_status == "Pending" ? 
                                                            <span className="table_pending inhe_table">{t('pending')}</span> 
                                                        : 
                                                            <span className="table_respond inhe_table">{t('responded')}</span>
                                                        }
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                :
                                    <tbody>
                                        <tr>
                                            <td colSpan="7">
                                                <div className="folder_image m-auto text-center">
                                                    <img src={process.env.PUBLIC_URL +"/assets/images/icon/folder.svg"} alt="folder" />
                                                </div>
                                                <div className="folder_image m-auto text-center">
                                                    <p className="folder_grey">{t('no_data')}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                }
                            </table>
                        </div>
                        <div style={{marginBottom: "30px"}}>
                            <h3>Shipments</h3>
                            <table className="table table-hover mb-0">
                                <thead>
                                    <tr className="table_header_bg">
                                        <th scope="col">{t('our_reference')} <img
                                                src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                        <th scope="col">{t('your_reference')} <img
                                                src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                        <th scope="col">{t('date')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                alt="filter" /></th>
                                        <th scope="col">{t('origin')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                alt="filter" /></th>
                                        <th scope="col">{t('destination')} <img
                                                src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                        <th scope="col">{t('cargo_type')} <img
                                                src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                        <th scope="col">{t('awb')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                alt="filter" /></th>
                                        <th scope="col">{t('flight_no')} <img
                                                src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                        <th scope="col">ETD <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                alt="filter" /></th>
                                        <th scope="col">ETA <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                alt="filter" /></th>
                                        <th scope="col">{t('status')}</th>
                                        {/* <th scope="col">{t('pod')}</th> */}
                                        {/* <th scope="col">Action</th> */}
                                    </tr>
                                </thead>
                                {isFilterData != undefined ?
                                    <tbody>
                                        {isFilterData[2] && isFilterData[2].map((shipmentdata, key) => {
                                            return(
                                                <>
                                                    <tr className="table-row" key={key} onClick={e => {
                                                        if (e.target.id === 'track-parcel' || e.target.id === 'contract') {
                                                            return;
                                                        }
                                                        else{
                                                            // routeChange(shipmentdata._id)
                                                        }
                                                        }}>
                                                        <td>
                                                            {shipmentdata.cargo_type == "Air" ? 
                                                                <img src={process.env.PUBLIC_URL +"/assets/images/icon/air-blue.svg"} className="me-1" alt="plane" />
                                                            : shipmentdata.cargo_type == "Sea" ?
                                                                <img src={process.env.PUBLIC_URL +"/assets/images/icon/sea-blue.svg"} className="me-1" alt="ship" />
                                                            :
                                                                <img src={process.env.PUBLIC_URL +"/assets/images/icon/truck-blue.svg"} className="me-1" alt="truck" />
                                                            }
                                                            {shipmentdata.our_reference}
                                                        </td>
                                                        <td>{shipmentdata.your_reference}</td>
                                                        <td>{shipmentdata.date}</td>
                                                        <td>{shipmentdata.origin}</td>
                                                        <td>{shipmentdata.destination}</td>
                                                        <td>{shipmentdata.cargo_type}</td>
                                                        <td>{shipmentdata.AWB}</td>
                                                        <td>{shipmentdata.flight_no}</td>
                                                        <td><span>{shipmentdata.ETD}</span></td>
                                                        <td><span>{shipmentdata.ETA}</span></td>
                                                        <td>
                                                            {shipmentdata.status == "Orderd" ? 
                                                                <span className="table_respond inhe_table">{t('ordered')}</span>
                                                            : shipmentdata.status == "Shipped" ?
                                                                <span className="table_pending inhe_table">{t('shipped')}</span>
                                                            : shipmentdata.status == "Out of delivery" ?
                                                                <span className="table_expire inhe_table">{t('out_of_delivery')}</span>
                                                            :
                                                                <span className="table_arrived inhe_table">{t('arrived')}</span>
                                                            }
                                                        </td>
                                                        {/* <td><a id={"contract"} onClick={() => contractdetails(key)} className="btn_view"> <i
                                                                    className="fa-solid fa-download me-1"></i>{t('download')}</a></td>
                                                        <td><Link id={"track-parcel"} to={`/track-parcel/${shipmentdata._id}`} className="btn_view">Track parcel</Link> </td> */}
                                                    </tr>
                                                </>
                                            )
                                        })}
                                    </tbody>
                                :
                                    <tbody>
                                        <tr>
                                            <td colSpan="13">
                                                <div className="folder_image m-auto text-center">
                                                    <img src={process.env.PUBLIC_URL +"/assets/images/icon/folder.svg"} alt="folder" />
                                                </div>
                                                <div className="folder_image m-auto text-center">
                                                    <p className="folder_grey">{t('no_data')}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                }
                            </table>
                        </div>
                        <div style={{marginBottom: "30px"}}>
                            <h3>Alerts</h3>
                            <table className="table table-hover table-condensed mb-0">
                                <thead>
                                    <tr className="table_header_bg">
                                        <th scope="col">{t('your_reference')} <img
                                                src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /> </th>
                                        <th scope="col">{t('our_reference')} <img
                                                src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /> </th>
                                        <th scope="col">{t('date')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                alt="filter" /> </th>
                                        <th scope="col">{t('origin')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                alt="filter" /> </th>
                                        <th scope="col">{t('destination')} <img
                                                src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /> </th>
                                        <th scope="col">{t('status')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"}
                                                alt="filter" /> </th>
                                        {/* <th scope="col" className="action_w_0">Actions</th> */}
                                    </tr>
                                </thead>
                                {isFilterData != undefined ?
                                    <tbody>
                                        {isFilterData[1] && isFilterData[1].map((alertdata) => {
                                            return(
                                                <>
                                                    <tr className="sub-container">
                                                        <td> 
                                                            {alertdata.cargo_type == "Air" ?
                                                                <img src={process.env.PUBLIC_URL +"/assets/images/icon/air-blue.svg"} className="me-1" alt="air" />
                                                            : alertdata.cargo_type == "Sea" ?
                                                                <img src={process.env.PUBLIC_URL +"/assets/images/icon/sea-blue.svg"} className="me-2" alt="sea" />
                                                            :
                                                                <img src={process.env.PUBLIC_URL +"/assets/images/icon/truck-blue.svg"} className="me-2" alt="truck" />
                                                            }
                                                            <img src={process.env.PUBLIC_URL +"/assets/images/icon/right-arrow.svg"} className="exploder" id="arrow"
                                                                alt="arrow" />{alertdata.our_reference}
                                                        </td>
                                                        <td>{alertdata.your_reference}</td>
                                                        <td>
                                                            {alertdata.date}
                                                        </td>
                                                        <td>{alertdata.origin}</td>
                                                        <td>{alertdata.destination}</td>
                                                        <td>
                                                            {alertdata.status == "Review Invoice" ?
                                                                <span className="table_respond inhe_table">{alertdata.status}</span>
                                                            : alertdata.status == "Missing Document" ?
                                                                <span className="table_pending inhe_table">{alertdata.status}</span>
                                                            :
                                                                <span className="table_expire inhe_table">{alertdata.status}</span>
                                                            }
                                                        </td>
                                                        {/* <td className="d-flex align-items-center">
                                                            <p className="mb-0 me-2 text-start">
                                                                <a href="/#" className="btn_view w-auto"> <img
                                                                        src={process.env.PUBLIC_URL +"/assets/images/icon/edit-white.svg"} alt="Edit" /> {t('edit')}</a>
                                                            </p>
                                                        </td> */}
                                                    </tr>
                                                </>
                                            )
                                        })}
                                    </tbody>
                                :
                                    <tbody>
                                        <tr>
                                            <td colSpan="7">
                                                <div className="folder_image m-auto text-center">
                                                    <img src={process.env.PUBLIC_URL +"/assets/images/icon/folder.svg"} alt="folder" />
                                                </div>
                                                <div className="folder_image m-auto text-center">
                                                    <p className="folder_grey">{t('no_data')}</p>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                }
                            </table>
                        </div>
                    </div>
                </>
            }
        </>
    )
}