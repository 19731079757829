import React, {useState, useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import useStorage from "../../../hooks/useStorage.ts";
import { getNetwork } from '../../../api/apiHandler';
import { useTranslation } from "react-i18next";

const Network = () => {
    const navigate = useNavigate();
    const { setItem,getItem } = useStorage();

    const [data, setData] = useState([]);
    const { t, i18n } = useTranslation();
    var [language, setLanguage] = useState(localStorage.getItem("language") === "fr" ? "fr" : "en");

    const validationSchema = Yup.object().shape({
        networks: Yup.array().min(1).of(Yup.string().required()).required().typeError('Please select at least one Network'),
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;

    useEffect(() => {
        getNetwork().then((response)=>{
            response = JSON.parse(response);
            if(response.code == 1){
                setData(response.data);
            }else{
                console.log("null");
            }
        });
    }, []);

    const onSubmit = (data) => {
        console.log("----------->>>", data)
        setItem("networks", data.networks.toString())
        navigate("/complete-profile");
    }

    const changeLanguageHandler = (e) => {
        const languageValue = e.target.value;
        i18n.changeLanguage(languageValue);
        localStorage.setItem("language", languageValue);
        setLanguage(languageValue);
    }

    return (
        <React.Fragment>
            <Helmet>
                <title>{t('select_network')}</title>
            </Helmet>

            <main className="main_cargo h-100">
                <div className="container-fluid h-100">
                    <div className="row h-100">
                        <ToastContainer />
                        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 h-100">
                            <div className="owl-slider h-100">
                                <div id="carousel1" className="owl-carousel product-slider slider_bg h-100">
                                    <div className="item h-100">
                                        <div className="user-slider-green user-slider-green-position">
                                            <img src={process.env.PUBLIC_URL +"/assets/images/page-image/slider-1.png"}  alt="slider" />
                                            <div className="carousel-caption">
                                                <h4 className="carousel_heading">Lorem ipsum dolor</h4>
                                                <p className="carousel_text">Lorem ipsum dolor</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item h-100">
                                        <div className="user-slider-green user-slider-green-position">
                                            <img src={process.env.PUBLIC_URL +"/assets/images/page-image/truck.gif"} className="blender_gif" alt="slider" />
                                            <div className="carousel-caption">
                                                <h4 className="carousel_heading">Lorem ipsum dolor</h4>
                                                <p className="carousel_text">Lorem ipsum dolor</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="item h-100">
                                        <div className="user-slider-green user-slider-green-position">
                                            <img src={process.env.PUBLIC_URL +"/assets/images/page-image/slider-3.png"} alt="slider" />
                                            <div className="carousel-caption">
                                                <h4 className="carousel_heading">Lorem ipsum dolor</h4>
                                                <p className="carousel_text">Lorem ipsum dolor</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 h-100">
                            <div className="row justify-content-center align-items-center h-100">
                                <div className="col-12 col-xl-6 col-lg-6 col-md-8 col-sm-9 text-center h-100">
                                    <div className="signin_between h-100">
                                            <div className='main_language'>
                                                <select className="form-select w-auto ms-auto mt-2" value={language} onChange={changeLanguageHandler} aria-label="Default select example">
                                                    <option value="en">English</option>
                                                    <option value="fr">Français</option>
                                                </select>
                                            </div>
                                        <div className="signin_logo">
                                            <img src="./assets/images/icon/logo.svg" alt="logo" />
                                        </div>
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="signin_content">
                                                <div>
                                                    <h1 className="other_h1">{t('network_text1')} <br/>{t('network_text2')}</h1>
                                                </div>
                                                <div className="container" style={{width: "350px", height: "38px", margin: "50px auto"}}>
                                                    <div className='row'>
                                                        {data && data.map((networkData, key) => {
                                                            console.log("{{{{{{{{{{{{{{{{{{{>>", networkData);
                                                            return(
                                                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12' key={key}>
                                                                    <div className="cat action">
                                                                        <label>
                                                                        {/* <img className='container-fluid' src={networkData.image_url} /> */}
                                                                            <input {...register('networks')} type="checkbox" value={networkData.network} /><span>{networkData.network}</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className="invalid-feedback">{errors.networks?.message}</div>
                                                    <div className='row'>
                                                        <div className='col-sm-6'>
                                                            <div className="signin_button signin_button_next">
                                                                <Link to="/select-account" className='btn btn_signin w-100'>{t('back')}</Link>
                                                            </div>
                                                        </div>
                                                        <div className='col-sm-6'>
                                                            <div className="signin_button signin_button_next">
                                                                <button type='submit' className='btn btn_signin w-100'>{t('next')}</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Helmet>
                <script>
                    {
                        `
                        jQuery("#carousel1").owlCarousel({
                            autoplay: true,
                            loop: true,
                            touchDrag: true,
                            mouseDrag: true,
                            items: 1,
                            responsiveClass: true,
                            autoplayTimeout: 7000,
                            smartSpeed: 800,
                            nav: false,
                            dots: true,
                            responsive: {
                                0: {
                                    items: 1
                                },
                        
                                468: {
                                    items: 1
                                },
                        
                                767: {
                                    items: 1
                                },
                        
                                991: {
                                    items: 1
                                },
                        
                                1200: {
                                    items: 1
                                },
                        
                                1400: {
                                    items: 1
                                }
                            }
                        });
                        `
                    }
                </script>
            </Helmet>
        </React.Fragment>
    )
}

export default Network;