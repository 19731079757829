import React from 'react';

export default function Slider() {
    return (
        <>
            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 h-100">
                <div className="owl-slider h-100">
                    <div id="carousel" className="owl-carousel product-slider slider_bg h-100">
                        <div className="item h-100">
                            <div className="user-slider-green user-slider-green-position">
                                <img src={process.env.PUBLIC_URL +"/assets/images/page-image/slider-1.png"}  alt="slider" />
                                <div className="carousel-caption">
                                    <h4 className="carousel_heading">Lorem ipsum dolor</h4>
                                    <p className="carousel_text">Lorem ipsum dolor</p>
                                </div>
                            </div>
                        </div>
                        <div className="item h-100">
                            <div className="user-slider-green user-slider-green-position">
                                <img src={process.env.PUBLIC_URL +"/assets/images/page-image/truck.gif"} className="blender_gif" alt="slider" />
                                <div className="carousel-caption">
                                    <h4 className="carousel_heading">Lorem ipsum dolor</h4>
                                    <p className="carousel_text">Lorem ipsum dolor</p>
                                </div>
                            </div>
                        </div>
                        <div className="item h-100">
                            <div className="user-slider-green user-slider-green-position">
                                <img src={process.env.PUBLIC_URL +"/assets/images/page-image/slider-3.png"} alt="slider" />
                                <div className="carousel-caption">
                                    <h4 className="carousel_heading">Lorem ipsum dolor</h4>
                                    <p className="carousel_text">Lorem ipsum dolor</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
