import React from 'react';
import { Helmet } from 'react-helmet';
import Sidebar from '../../components/customerComponents/Sidebar/Sidebar';
import Topbar from '../../components/customerComponents/Topbar/Topbar';
import { useTranslation } from "react-i18next";

export default function Invoice() {
    const commonProps = {title: "invoice"}
    const { t, i18n } = useTranslation();

    return (
        <>
            <Helmet>
                <title>{t('invoice')}</title>
            </Helmet>
            <main className="dash_b">
                <div className="container-fluid">
                    <div id="wrapper">
                        <Sidebar commonProps={commonProps}/>

                        <section id="content-wrapper">
                            <Topbar />

                            <section className="dashboard_area">
                                <div className="main_invoice">
                                    <div className="row">
                                        <div className="col-12 col-xl-9 col-lg-9 col-md-9 col-sm-12">
                                            <div className="row">
                                                <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                                    <div className="invoice_card of_white">
                                                        <img src={process.env.PUBLIC_URL +"/assets/images/icon/amount-due.png"} className="img-fluid" style={{width: "24px", height: "24px"}} alt="amount-due" />
                                                        <span className='green_5'>123 EUROS</span>
                                                        <p>{t('total_amount_due')}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                                    <div className="invoice_card of_white">
                                                        <img src={process.env.PUBLIC_URL +"/assets/images/icon/amount-due-2.png"} className="img-fluid" style={{width: "24px", height: "24px"}} alt="amount-due-2" />
                                                        <span className='green_5'>123 EUROS</span>
                                                        <p>{t('due_in_15_days')}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                                    <div className="invoice_card of_white">
                                                        <img src={process.env.PUBLIC_URL +"/assets/images/icon/amount-due-3.png"} className="img-fluid" style={{width: "24px", height: "24px"}} alt="amount-due-3" />
                                                        <span className='green_5'>123 EUROS</span>
                                                        <p>{t('due_in_5_days')}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                                    <div className="invoice_card of_white">
                                                        <img src={process.env.PUBLIC_URL +"/assets/images/icon/amount-due-4.png"} className="img-fluid" style={{width: "24px", height: "24px"}} alt="amount-due-4" />
                                                        <span className="euro_red">123 EUROS</span>
                                                        <p>{t('total_past_due_date')}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                                    <div className="invoice_card of_white">
                                                        <img src={process.env.PUBLIC_URL +"/assets/images/icon/amount-due-5.png"} className="img-fluid" style={{width: "24px", height: "24px"}} alt="amount-due-5" />
                                                        <span className="euro_red">123 EUROS</span>
                                                        <p>{t('past_5_days')}</p>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-6">
                                                    <div className="invoice_card of_white">
                                                        <img src={process.env.PUBLIC_URL +"/assets/images/icon/amount-due-6.png"} className="img-fluid" style={{width: "24px", height: "24px"}} alt="amount-due-6" />
                                                        <span className="euro_red">123 EUROS</span>
                                                        <p>{t('past_15_days')}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                        <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12 d-flex flex-column justify-content-between of_white">
                                            <div className='row'>
                                                <div className='col-6 col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                                                    <p className="invoice_blue">{t('statement_of_account')}</p>
                                                </div>
                                                <div className='col-6 col-xl-6 col-lg-12 col-md-12 col-sm-12'>
                                                    <div className="text-center d-flex align-items-center justify-content-center">
                                                        <span className="switch_dimension">
                                                            <input type="checkbox" id="switcher" checked="" />
                                                            <label htmlFor="switcher"></label>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="statement_date">    
                                                <a href="/#" className="btn btn_statement_green">{t('all')}</a>
                                                <a href="/#" className="btn btn_statement_green">{t('current_year')}</a>
                                                <a href="/#" className="btn btn_statement_green">{t('latest')}</a>
                                            </div>
                                            <div className="row">
                                                <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <div className="quote_dropdown">
                                                        <p className="mb-0">{t('from')}:</p>
                                                        <input type="date" id="birthday" name="birthday" className="date_input w-100" />
                                                    </div>
                                                </div>
                                                <div className="col-6 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                                    <div className="quote_dropdown">
                                                        <p className="mb-0">{t('to')}:</p>
                                                        <input type="date" id="birthday" name="birthday" className="date_input w-100" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="signin_button signin_button_next">
                                                <a href="/#" className="btn btn_signin_invoice w-100">{t('download_statement')}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-center quo_area">
                                        <div className="col-xl-4 d-flex">
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle text_35" type="button" id="dropdownMenuButton1"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                    {t('filter')} <img src="assets/images/icon/down-arrow.svg" alt="down-arrow" />
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li>
                                                        <a className="dropdown-item" href="/#">
                                                            <span>{t('select_date')}</span>
                                                            <div className="quote_dropdown">
                                                                <p className="mb-0">{t('from')}:</p>
                                                                <input type="date" id="birthday" name="birthday"
                                                                    className="date_input" />
                                                            </div>
                                                            <div className="quote_dropdown">
                                                                <p className="mb-0">{t('to')}:</p>
                                                                <input type="date" id="birthday" name="birthday"
                                                                    className="date_input" />
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li className="mt-2">
                                                        <a className="dropdown-item quote_origin" href="/#">
                                                            <div className="input_box">
                                                                <input type="text" className="form-control" placeholder={t('origin')} id="exampleInputEmail1" aria-describedby="emailHelp" required="required" />
                                                                <label htmlFor="exampleInputEmail1" className="form-label"></label>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li className="mt-2">
                                                        <a className="dropdown-item quote_origin" href="/#">
                                                            <div className="input_box">
                                                                <input type="text" className="form-control" placeholder={t('destination')} id="exampleInputEmail1" aria-describedby="emailHelp" required="required" />
                                                                <label htmlFor="exampleInputEmail1" className="form-label"></label>
                                                            </div>
                                                        </a>
                                                    </li>
                                                    <li className="quote_apply mt-2">
                                                        <a className="dropdown-item btn btn_quote_apply" href="/#">{t('apply')}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                            <ul className="quotes_drop quotes_drop_f6 mb-0">
                                                <li className="d-flex align-items-center show_block">
                                                    <a className="dropdown-item btn btn_quotes" href="/#"><img src={process.env.PUBLIC_URL +"/assets/images/icon/air-blue.svg"} alt="plane" /> {t('air')} <i className="fa-solid fa-circle-check"></i> </a>
                                                    <a className="dropdown-item btn btn_quotes" href="/#"><img src={process.env.PUBLIC_URL +"/assets/images/icon/sea-blue.svg"} alt="boat" />{t('sea')} <i className="fa-solid fa-circle-check"></i> </a>
                                                    <a className="dropdown-item btn btn_quotes" href="/#"><img src={process.env.PUBLIC_URL +"/assets/images/icon/truck-blue.svg"} alt="truck" />{t('other')} <i className="fa-solid fa-circle-check"></i> </a>
                                                    <a className="dropdown-item btn btn_quotes" href="/#"><img src={process.env.PUBLIC_URL +"/assets/images/icon/responded-blue.svg"} alt="respond" />{t('responded')}
                                                        <i className="fa-solid fa-circle-check"></i> </a>
                                                    <a className="dropdown-item btn btn_quotes" href="/#"><img src={process.env.PUBLIC_URL +"/assets/images/icon/pending-blue.svg"} alt="pending" />{t('pending')} <i className="fa-solid fa-circle-check"></i> </a>
                                                    <a className="dropdown-item btn btn_quotes" href="/#"><img src={process.env.PUBLIC_URL +"/assets/images/icon/expired-blue.svg"} alt="expire" />{t('expired')} <i className="fa-solid fa-circle-check"></i> </a>
                                                </li>
                                            </ul>
                                            {/* <div className="dropdown">
                                                <button className="btn dropdown-toggle text_35" type="button" id="dropdownMenuButton1"
                                                    data-bs-toggle="dropdown" aria-expanded="false">
                                                    Show only
                                                </button>
                                                <ul className="dropdown-menu quotes_drop" aria-labelledby="dropdownMenuButton1">
                                                    <li className="d-flex paid_quote">
                                                        <a className="dropdown-item btn btn_quotes" href="/#">Paid
                                                            <i className="fa-solid fa-circle-check"></i> </a>
                                                        <a className="dropdown-item btn btn_quotes" href="/#">Pending<i className="fa-solid fa-circle-check"></i> </a>
                                                        <a className="dropdown-item btn btn_quotes" href="/#">Past due date <i
                                                                className="fa-solid fa-circle-check"></i> </a>
                                                    </li>
                                                </ul>
                                            </div> */}
                                        </div>
                                        <div className="col-xl-2 ms-auto">
                                            <div className="header_search">
                                                <div className="input-group">
                                                    <span className="input-group-text">
                                                        <img src={process.env.PUBLIC_URL +"/assets/images/icon/search.svg"} alt="search" />
                                                    </span>
                                                    <input type="text" className="form-control" placeholder={t('search')}
                                                        aria-label="Recipient's username" aria-describedby="basic-addon2" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="quote_table table-responsive">
                                        <table className="table table-hover mb-0">
                                            <thead>
                                                <tr className="table_header_bg">
                                                    <th scope="col">{t('our_reference')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                    <th scope="col">{t('your_reference')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                    <th scope="col">{t('awb')}/BL <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                    <th scope="col">{t('date')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                    <th scope="col">{t('invoice_number')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                    <th scope="col">{t('amount')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                    <th scope="col"> {t('due_date')} <img src={process.env.PUBLIC_URL +"/assets/images/icon/table-filtershort.svg"} alt="filter" /></th>
                                                    <th scope="col">{t('status')}</th>
                                                    <th scope="col" className='action_w_0'>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><input className="form-check-input me-1" type="checkbox" value="" id="flexCheckDefault" />Lorem</td>
                                                    <td>Ispum</td>
                                                    <td>Lorem</td>
                                                    <td>14/04/2022</td>
                                                    <td>345</td>
                                                    <td>255$</td>
                                                    <td><span />16/04/2022</td>
                                                    <td> <span className="table_respond">{t('paid')}</span> </td>
                                                    <td className="d-flex align-items-center">
                                                        <div className="me-1">
                                                            <a href="/#" className="btn_view"> <i className="fa-solid fa-eye"></i> {t('view_shipment')} </a>
                                                        </div>
                                                        <div className="me-1">
                                                            <a href="/#" className="btn_view"> <i className="fa-solid fa-download"></i> {t('download')} </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><input className="form-check-input me-1" type="checkbox" value="" id="flexCheckDefault" />Lorem</td>
                                                    <td>Ispum</td>
                                                    <td>Lorem</td>
                                                    <td>14/04/2022</td>
                                                    <td>345</td>
                                                    <td>255$</td>
                                                    <td><span />16/04/2022</td>
                                                    <td> <span className="table_pending">{t('pending')}</span> </td>
                                                    <td className="d-flex align-items-center">
                                                        <div className="me-1">
                                                            <a href="/#" className="btn_view"> <i className="fa-solid fa-eye"></i> {t('view_shipment')} </a>
                                                        </div>
                                                        <div className="me-1">
                                                            <a href="/#" className="btn_view"> <i className="fa-solid fa-download"></i> {t('download')} </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td><input className="form-check-input me-1" type="checkbox" value="" id="flexCheckDefault" />Lorem</td>
                                                    <td>Ispum</td>
                                                    <td>Lorem</td>
                                                    <td>14/04/2022</td>
                                                    <td>345</td>
                                                    <td>255$</td>
                                                    <td><span />16/04/2022</td>
                                                    <td> <span className="table_expire">{t('past_due_date')}</span> </td>
                                                    <td className="d-flex align-items-center">
                                                        <div className="me-1">
                                                            <a href="/#" className="btn_view"> <i className="fa-solid fa-eye"></i> {t('view_shipment')} </a>
                                                        </div>
                                                        <div className="me-1">
                                                            <a href="/#" className="btn_view"> <i className="fa-solid fa-download"></i> {t('download')} </a>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="quote_pagination d-flex justify-content-between align-items-center">
                                        <div className="ms-3">
                                            <a href="/#" className="btn_view"> <i className="fa-solid fa-download"></i> {t('download_statement')}</a>
                                            <a href="/#" className="btn_view"> <i className="fa-solid fa-download"></i> {t('download')} {t('all')}</a>
                                        </div>
                                        <nav aria-label="Page navigation example">
                                            <ul className="pagination justify-content-end">
                                                <li className="page-item">
                                                    <a className="page-link quote_pre" href="/#">Previous</a>
                                                </li>
                                                <li className="page-item"><a className="page-link active" href="/#">1</a></li>
                                                <li className="page-item"><a className="page-link" href="/#">2</a></li>
                                                <li className="page-item"><a className="page-link" href="/#">3</a></li>
                                                <li className="page-item">
                                                    <a className="page-link quote_pre" href="/#">Next</a>
                                                </li>
                                            </ul>
                                        </nav>
                                    </div>
                                </div>
                            </section>
                        </section>

                    </div>
                </div>
            </main>
        </>
    )
}
