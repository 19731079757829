import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { addQuotes, getQuoteDetail, getQuotesContact, quotesContact } from '../../api/apiHandler';
import Sidebar from '../../components/customerComponents/Sidebar/Sidebar';
import Topbar from '../../components/customerComponents/Topbar/Topbar';
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

export default function QuoteDetails() {
    const commonProps = {title: "quotes"}

    let { id } = useParams();
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    var fileDownload = require('js-file-download');
    
    var [data, setData] = useState([]);
    var [cargo, setCargo] = useState([]);
    var [quoteDetail, setQuoteDetail] = useState([]);

    var weight = 0;
    cargo.forEach(element => {
        if(data.transport_mode != "Sea"){
            if(element.weight_type == "kg"){
                weight = weight + element.weight;
            }
            else if(element.weight_type == "tn"){
                var tn = element.weight * 1000;
                weight = weight + tn;
            }
            else{
                var gm = element.weight/1000;
                weight = weight + gm;
            }
        }
        else{
            if(element.container == "20ft high cube"){
                weight = weight + 25000;
            }
            else if(element.container == "20ft general"){
                weight = weight + 25400;
            }
            else if(element.container == "40ft high cube"){
                weight = weight + 39800;
            }
            else if(element.container == "40ft general"){
                weight = weight + 30480;
            }
            else{
                weight = weight + 0;
            }
        }
    });

    var volume = 0;
    cargo.forEach(element => {
        if(data.transport_mode == "Sea"){
            if(element.container == "40ft high cube"){
                // Total volume: (length (in meters) * width (in meters) * height (in meters) in m3
                volume = volume + (12.19 * 2.44 * 2.99);
            }
            else if(element.container == "20ft general"){
                volume = volume + (2.5 * 2.9 * 2.44);
            }
            else if(element.container == "40ft general"){
                volume = volume + (12.19 * 2.43 * 2.59);
            }
            else if(element.container == "20ft high cube"){
                volume = volume + (6.1 * 2.44 * 2.89);
            }
            else{
                volume= volume + (12 * 3.6 * 89);
            }
        }
        else{
            if(element.type == "pallet"){
                volume = volume + (1.2 * 1.0 * 2.44);
            }
            else if(element.type == "carton"){
                volume = volume + (2.6 * 1.5 * 2.91);
            }
            else{
                volume = volume + (5.9 * 2.3 * 2.3);
            }
        }
    });

    var chargeable_weight = (weight + volume)/2;

    useEffect(() => {
        getQuoteDetail({"quote_id": id}).then((response)=>{
            response = JSON.parse(response);
            if(response.code == 1){
                setData(response.data[0]);
                setCargo(response.data[0].cargo);
            }else{
                console.log("null");
            }
        });
    }, []);

    useEffect(() => {
        getQuotesContact({"quote_id": id}).then((response)=>{
            response = JSON.parse(response);
            if(response.code == 1){
                setQuoteDetail(response.data)
            }else{
                console.log("null");
            }
        });
    }, []);

    const duplicateData = () => {
        addQuotes(data).then((response) => {
            response = JSON.parse(response);
            if(response.code == 1){
                toast.success(response.message, {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
                navigate('/quotes')
            }
            else{
                toast.error(response.message, {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        })
    }

    const contractdetails= () => {
        fileDownload(JSON.stringify(data), 'Quote.csv');
    };

    const contactValidationSchema = Yup.object().shape({
        quote_id: Yup.string(),
        contact_ques: Yup.string()
            .required('Question field is required.')
            .min(10, 'Question field must be at least 10 characters')
    });

    const formOptions = { resolver: yupResolver(contactValidationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;


    const onSubmit = (data) => {
        quotesContact(data).then((response) => {
            response = JSON.parse(response)
            if(response.code == 1)
            {
                toast.success(response.message, {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
            else
            {
                toast.error(response.message, {
                    position: "top-right",
                    autoClose: 5000,
                    closeOnClick: true,
                    pauseOnHover: true,
                });
            }
        });
        reset();
    }
    

    return (
        <>
            <Helmet>
                <title>{t('quotes_details')}</title>
            </Helmet>
            <main className="dash_b">
                <div className="container-fluid">
                    <div id="wrapper">
                        <Sidebar commonProps={commonProps}/>

                        <section id="content-wrapper">
                            <Topbar />

                            <section className="dashboard_area">
                                <div className="quote_detail_white">
                                    <div className="d-flex align-items-center mt-3">
                                        <h5>{t('quotes_details')}:</h5>
                                    </div>
                                    <div className='request_hr'></div>
                                    <div className="d-flex align-items-center">
                                        <div className="qd_ajel">
                                            <div className="d-flex align-items-center mt-3">
                                                <h6 className=" me-3 mb-0">{t('your_reference')}:</h6>
                                                <span>{data.your_reference}</span>
                                            </div>
                                        </div>
                                        <div className="ms-auto">
                                            <a type='button' onClick={() => duplicateData()} className="btn_view ajel_padding me-3"> <i
                                                    className="fa-regular fa-clone me-1"></i> {t('duplicate')} </a>
                                            <Link to={`/editquotes2/${data._id}`} className="btn_view ajel_padding"> <img
                                                src={process.env.PUBLIC_URL +"/assets/images/icon/edit-white.svg"} alt="Edit" /> {t('edit')}</Link>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                            <div className="d-flex align-items-center">
                                                <div className="qu_air_green_bigger qu_air_green mt-0">
                                                    {data.transport_mode == "Air" ?
                                                        <img src={process.env.PUBLIC_URL +"/assets/images/icon/air-white.svg"} alt="air" /> 
                                                    : 
                                                    // data.transport_mode == "Sea" ?
                                                        <img src={process.env.PUBLIC_URL +"/assets/images/icon/sea-white.svg"} alt="sea" /> 
                                                    // :
                                                    //     <img src={process.env.PUBLIC_URL +"/assets/images/icon/truck-white.svg"} alt="truck" />
                                                    }
                                                </div>
                                                <div className="box_shd total_cost_box">
                                                    <div className="gross_position mb-1">
                                                        {data.transport_mode == "Air" ?
                                                            <iconify-icon   iconify-icon icon="fa-solid:file-invoice" style={{fontSize: "25px", color: "#3792cb"}}></iconify-icon>
                                                        : 
                                                        // data.transport_mode == "Sea" ?
                                                            <iconify-icon   iconify-icon icon="fa-solid:file-invoice" style={{fontSize: "25px", color: "#5dbbb3"}}></iconify-icon>
                                                        // :
                                                        //     <iconify-icon   iconify-icon icon="fa-solid:file-invoice" style={{fontSize: "25px", color: "#fda172"}}></iconify-icon>
                                                        }
                                                    </div>
                                                    <span>5000 EUROS</span>
                                                    <p className="mb-0">{t('total_cost')}</p>
                                                </div>
                                                <div className="box_shd total_cost_box">
                                                    <div className="d-flex flex-column justify-content-center">
                                                        <div className="gross_position">
                                                            {data.transport_mode == "Air" ?
                                                                <iconify-icon   iconify-icon icon="fa-solid:coins" style={{fontSize: "25px", color: "#3792cb"}}></iconify-icon>
                                                            : 
                                                            // data.transport_mode == "Sea" ?
                                                                <iconify-icon   iconify-icon icon="fa-solid:coins" style={{fontSize: "25px", color: "#5dbbb3"}}></iconify-icon>
                                                            // :
                                                            //     <iconify-icon   iconify-icon icon="fa-solid:coins" style={{fontSize: "25px", color: "#fda172"}}></iconify-icon>
                                                            }
                                                        </div>
                                                        <span>5,45 EUROS</span>
                                                        <div className="align-items-center d-flex">
                                                            <p className="mb-1 me-2">{t('unit_cost')} </p>
                                                            <div> <span className="switch_detail_quotes">
                                                                    <input type="checkbox" id="switcher" checked />
                                                                    <label htmlFor="switcher"></label>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="box_shd total_cost_box">
                                                    <div className="gross_position mb-1">
                                                        {data.transport_mode == "Air" ?
                                                            <iconify-icon   iconify-icon icon="fa:calendar-check-o" style={{fontSize: "25px", color: "#3792cb"}}></iconify-icon>
                                                        : 
                                                        // data.transport_mode == "Sea" ?
                                                            <iconify-icon   iconify-icon icon="fa:calendar-check-o" style={{fontSize: "25px", color: "#5dbbb3"}}></iconify-icon>
                                                        // :
                                                        //     <iconify-icon   iconify-icon icon="fa:calendar-check-o" style={{fontSize: "25px", color: "#fda172"}}></iconify-icon>
                                                        }
                                                    </div>
                                                    <span>29 sep 2022</span>
                                                    <p className="mb-0">{t('valid_unit')}</p>
                                                </div>
                                                <div className="box_shd total_cost_box">
                                                    <div className="gross_position mb-1">
                                                        {data.transport_mode == "Air" ?
                                                            <iconify-icon   iconify-icon icon="ri:plane-fill" style={{fontSize: "25px", color: "#3792cb"}}></iconify-icon>
                                                        : 
                                                        // data.transport_mode == "Sea" ? 
                                                            <iconify-icon   iconify-icon icon="ion:logo-docker" style={{fontSize: "25px", color: "#5dbbb3"}}></iconify-icon>
                                                        // :
                                                        //     <iconify-icon   iconify-icon icon="mdi:truck" style={{fontSize: "25px", color: "#fda172"}}></iconify-icon>
                                                        }
                                                    </div>
                                                    <span>Air India</span>
                                                    <p className="mb-0">{t('airline')}</p>
                                                </div>
                                                <div className="box_shd total_cost_box">
                                                    <div className="gross_position mb-1">
                                                        {data.transport_mode == "Air" ?
                                                            <iconify-icon   iconify-icon icon="fa6-solid:plane-departure" style={{fontSize: "25px", color: "#3792cb"}}></iconify-icon>
                                                        : 
                                                        // data.transport_mode == "Sea" ? 
                                                            <iconify-icon   iconify-icon icon="ri:ship-fill" style={{fontSize: "25px", color: "#5dbbb3"}}></iconify-icon>
                                                        // :
                                                        //     <iconify-icon   iconify-icon icon="fa6-solid:truck-fast" style={{fontSize: "25px", color: "#fda172"}}></iconify-icon>
                                                        }
                                                    </div>
                                                    <span>29 sep 2022</span>
                                                    <p className="mb-0">{t('next_flight')}</p>
                                                </div>
                                                <div className="box_shd total_cost_box">
                                                    <div className="gross_position mb-1">
                                                        {data.transport_mode == "Air" ?
                                                            <iconify-icon   iconify-icon icon="fa-solid:calendar-alt" style={{fontSize: "25px", color: "#3792cb"}}></iconify-icon>
                                                        : 
                                                        // data.transport_mode == "Sea" ?
                                                            <iconify-icon   iconify-icon icon="fa-solid:calendar-alt" style={{fontSize: "25px", color: "#5dbbb3"}}></iconify-icon>
                                                        // :
                                                        //     <iconify-icon   iconify-icon icon="fa-solid:calendar-alt" style={{fontSize: "25px", color: "#fda172"}}></iconify-icon>
                                                        }
                                                    </div>
                                                    <span>Mon,Tues,Fri.</span>
                                                    <p className="mb-0">{t('flight_frequency')}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="d-flex">
                                        <div className="ms-auto">
                                            <a type='button' data-bs-toggle="modal"
                                            data-bs-target="#exampleModal4" className="btn hide_details">{t('show_details')}</a>
                                        </div>
                                    </div>
                                    <div className="quote_details_button">
                                        <a type='button' onClick={() => contractdetails()} className="btn_view"> <i className="fa-solid fa-download me-1"></i>{t('download_pdf')}</a>
                                        <a type='button' className="table_respond" data-bs-toggle="modal"
                                            data-bs-target="#exampleModal">{t('accept')}</a>
                                        <a type='button' className="table_pending" data-bs-toggle="modal"
                                            data-bs-target="#exampleNegotiate">{t('contact_us')}</a>
                                    </div>
                                </div>
                                <div className="quote_detail_white">
                                    <h5>{t('itinerary')}:</h5>
                                    <div className="request_hr"></div>

                                    <div className="order_shipped ship_grey">
                                        <div className="order-tracking completed">
                                            <span className="is-complete"><img src={process.env.PUBLIC_URL + "/assets/images/icon/building.svg"} alt="" /></span>
                                            <p className="order-track-text-stat">{t('shipper')}</p>
                                            <span className="order-track-text-sub">{data.pick_address}</span>
                                        </div>
                                        <div className="order-tracking completed">
                                            <span className="is-complete"><img src={process.env.PUBLIC_URL + "/assets/images/icon/truck-white.svg"}
                                                    alt="" /></span>
                                            <p className="order-track-text-stat">{t('airport_of_departure')}</p>
                                            <span className="order-track-text-sub">{data.airport_of_departure}</span>
                                        </div>
                                        <div className="order-tracking completed">
                                            <span className="is-complete"><img src={process.env.PUBLIC_URL + "/assets/images/icon/truck-white.svg"}
                                                    alt="" /></span>
                                            <p className="order-track-text-stat">{t('pick_up')}:</p>
                                            <span className="order-track-text-sub">{data.date}</span>
                                        </div>
                                        <div className="order-tracking completed">
                                            <span className="is-complete"><img src={process.env.PUBLIC_URL + "/assets/images/icon/building.svg"} alt="" /></span>
                                            <p className="order-track-text-stat">{t('order_out_of_delivery')}</p>
                                            <span className="order-track-text-sub">16th August, 2022</span>
                                        </div>
                                        <div className="order-tracking completed">
                                            <span className="is-complete"><img src={process.env.PUBLIC_URL + "/assets/images/icon/truck-white.svg"}
                                                    alt="" /></span>
                                            <p className="order-track-text-stat-2 order-track-text-stat">{t('vessel_arrival')}:</p>
                                            <span className="order-track-text-sub-2 order-track-text-sub">Date not
                                                defined</span>
                                        </div>
                                        <div className="order-tracking completed">
                                            <span className="is-complete"><img src={process.env.PUBLIC_URL + "/assets/images/icon/truck-white.svg"}
                                                    alt="" /></span>
                                            <p className="order-track-text-stat">{t('delivery_address')}</p>
                                            <span className="order-track-text-sub">{data.delivery_address}</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="quote_detail_white">
                                    <div className="widget_5">
                                        
                                        <div className="row">
                                            <div className="col-12 col-xl-4 col-lg-4 col-md-3 col-sm-3">
                                                <h5>{t('your_cargo')}:</h5>
                                                {/* <div className='request_hr'></div> */}
                                            </div>
                                            <div className="col-12 col-xl-8 col-lg-8 col-md-9 col-sm-9">
                                                <div className='row'>
                                                    <div className="col-12 col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                        <h5 className="me-2">{t('detailed_dimension')}</h5>
                                                    </div>
                                                    <div className="col-12 col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                        <div className="text-center d-flex align-items-center justify-content-center">
                                                            <span className="switch_dimension">
                                                                <input type="checkbox" id="switcher" checked="" />
                                                                <label htmlFor="switcher"></label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                                        <div className="text-center d-flex align-items-center justify-content-center">
                                                            <span className="switch_detail">
                                                                <input type="checkbox" id="switcher" checked="" />
                                                                <label htmlFor="switcher"></label>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='request_hr'></div>
                                        </div>

                                        <div className="row justify-content-center align-items-center text-center">
                                            <div className="col-12 col-xl-4 col-lg-4 col-md-3 col-sm-3">
                                                <div className="widget_image">
                                                    <img src={process.env.PUBLIC_URL + "/assets/images/page-image/Pallets.png"} alt="Pallets" />
                                                </div>
                                                <h5 className="mt-3">{t('pallets')}</h5>
                                            </div>
                                            <div className="col-12 col-xl-8 col-lg-8 col-md-9 col-sm-9">
                                                <div className="quote_table table-responsive">
                                                    <table className="table table-hover mb-0">
                                                        <thead>
                                                            <tr className="table_header_bg">
                                                                <th scope="col">{t('type')} <img
                                                                        src={process.env.PUBLIC_URL + "/assets/images/icon/table-filtershort.svg"}
                                                                        alt="filter" /></th>
                                                                <th scope="col" className="text-center">{t('number')} <img
                                                                        src={process.env.PUBLIC_URL + "/assets/images/icon/table-filtershort.svg"}
                                                                        alt="filter" /></th>
                                                                <th scope="col" className="text-center">{t('length')} <img
                                                                        src={process.env.PUBLIC_URL + "/assets/images/icon/table-filtershort.svg"}
                                                                        alt="filter" /></th>
                                                                <th scope="col" className="text-center">{t('width')} <img
                                                                        src={process.env.PUBLIC_URL + "/assets/images/icon/table-filtershort.svg"}
                                                                        alt="filter" /></th>
                                                                <th scope="col" className="text-center">{t('height')} <img
                                                                        src={process.env.PUBLIC_URL + "/assets/images/icon/table-filtershort.svg"}
                                                                        alt="filter" /></th>
                                                                <th scope="col" className="text-center">{t('weight')} <img
                                                                        src={process.env.PUBLIC_URL + "/assets/images/icon/table-filtershort.svg"}
                                                                        alt="filter" /></th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {cargo.map((cargodata, key) => {
                                                                if(data.transport_mode == "Sea"){
                                                                    return(
                                                                        <tr key={key}>
                                                                            <td>{cargodata.container}</td>
                                                                            <td className="text-center">{cargodata.qty}</td>
                                                                            <td className="text-center">{cargodata.container == "20ft high cube" ? 5.90 : cargodata.container == "20ft general" ? 5.98 : cargodata.container == "40ft high cube" ? 12.19 : cargodata.container == "40ft general" ? 12.025 : "--"}</td>
                                                                            <td className="text-center">{cargodata.container == "20ft high cube" ? 2.35 : cargodata.container == "20ft general" ? 2.35 : cargodata.container == "40ft high cube" ? 2.44 : cargodata.container == "40ft general" ? 2.35 : "--"}</td>
                                                                            <td className="text-center">{cargodata.container == "20ft high cube" ? 2.39 : cargodata.container == "20ft general" ? 2.39 : cargodata.container == "40ft high cube" ? 2.99 : cargodata.container == "40ft general" ? 2.39 : "--"}</td>
                                                                            <td className="text-center">{cargodata.container == "20ft high cube" ? 25000 : cargodata.container == "20ft general" ? 25400 : cargodata.container == "40ft high cube" ? 39800 : cargodata.container == "40ft general" ? 30480 : "--"}</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                                else{
                                                                    return(
                                                                        <tr key={key}>
                                                                            <td>{cargodata.type}</td>
                                                                            <td className="text-center">{cargodata.pieces}</td>
                                                                            <td className="text-center">{cargodata.type == "pallet" ? 1.2 : cargodata.type == "crate" ? 5.9 : 2.6}</td>
                                                                            <td className="text-center">{cargodata.type == "pallet" ? 1.0 : cargodata.type == "crate" ? 2.3 : 1.5}</td>
                                                                            <td className="text-center">{cargodata.type == "pallet" ? 2.44 : cargodata.type == "crate" ? 2.3 : 2.91}</td>
                                                                            <td className="text-center">{cargodata.weight}</td>
                                                                        </tr>
                                                                    )
                                                                }
                                                            })}
                                                        </tbody>
                                                    </table>
                                                    <div className="d-flex align-items-center">
                                                        <div className="box_shd total_cost_box">
                                                            <div className="gross_position mb-1">
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/kg.png"} className="img-fluid" style={{width: "35px", height: "35px"}}
                                                                    alt="weightmeter" />
                                                            </div>
                                                            <span> {weight} kg</span>
                                                            <p className="mb-0 text-start">{t('total_gross_weight')}</p>
                                                        </div>
                                                        <div className="box_shd total_cost_box">
                                                            <div className="gross_position mb-1">
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/charge_weight.png"} className="img-fluid" style={{width: "35px", height: "35px"}}
                                                                    alt="weightmeter" />
                                                            </div>
                                                            <span>{chargeable_weight.toString().substring(0,8)} kg</span>
                                                            <p className="mb-1 me-2">{t('total_chargeable_weight')}</p>
                                                        </div>
                                                        <div className="box_shd total_cost_box">
                                                            <div className="gross_position mb-1">
                                                                <img src={process.env.PUBLIC_URL + "/assets/images/icon/volume.png"} className="img-fluid" style={{width: "35px", height: "35px"}}
                                                                    alt="weightmeter" />
                                                            </div>
                                                            <span> {volume.toString().substring(0,7)} kg</span>
                                                            <p className="mb-0">{t('total_volume')}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="quote_detail_white">
                                    <div className="d-flex align-items-center mt-3">
                                        <h5>{t('good_description')}:</h5>
                                    </div>
                                    <div className='request_hr'></div>
                                    <div className="widget_goods d-flex">
                                        <div className="ms-3">
                                            {/* <p>{data.cargo[0].is_temperature_controlled == "No" ?  "No Batteries" : "Batteries" }</p>
                                            <p>{data.cargo[0].is_dangerous == "No" ?  "No Dangerous" : "Dangerous goods" }</p> */}
                                            <p>Batteries</p>
                                            <p>Dangerous goods</p>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </section>

                    </div>
                </div>
            </main>

            {/* ===============================  Model =============================== */}
            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered check_modal">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="animated-wrapper">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2">
                                    <circle className="path circle" fill="none" stroke="#73AF55" strokeWidth="6"
                                        strokeMiterlimit="10" cx="65.1" cy="65.1" r="62.1" />
                                    <polyline className="path check" fill="none" stroke="#73AF55" strokeWidth="6"
                                        strokeLinecap="round" strokeMiterlimit="10"
                                        points="100.2,40.2 51.5,88.8 29.8,67.5 " />
                                </svg>

                            </div>
                            <h5 className="text-center mt-3">We thank you for trusting us for your shipment, our team will get back
                                to
                                you shortly with
                                required information.</h5>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="exampleReject" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="dropdown input_box_margin contact_dropdown">
                                <h5>What's wrong?</h5>
                                <button
                                    className="btn btn-outline-secondary dropdown-toggle w-100 d-flex align-items-center justify-content-between"
                                    type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span>Select subject</span>
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icon/down-arrow.svg"} alt="down-arrow" />
                                </button>
                                <ul className="dropdown-menu w-100 list_contactinput_ul" aria-labelledby="dropdownMenuButton1">
                                    <li><a className="dropdown-item" href="/#">out of budget</a></li>
                                    <li><a className="dropdown-item" href="/#">late answer</a></li>
                                    <li><a className="dropdown-item" href="/#">out of shipment delay</a></li>
                                    <li className="list_contactinput">
                                        <div className="input_box">
                                            <input type="text" className="form-control" id="exampleInputEmail1"
                                                placeholder="Other subject type here" aria-describedby="emailHelp"
                                                required="required" />
                                            <label htmlFor="exampleInputEmail1" className="form-label"></label>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade" id="exampleNegotiate" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body">
                            <div>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <h5>Please leave your message and we will contact you as soon as possible</h5>
                                    <div className="input_box_margin_1">
                                        <input type="hidden" {...register('quote_id')} value={id}/>
                                        <input type="text" className="form-control ps-2" {...register('contact_ques')} autoComplete="off" aria-label="Text input with dropdown button" />
                                        <label className="invalid-feedback">{errors.contact_ques?.message}</label>
                                    </div>
                                    <button type='submit' className='btn btn_view'> Send Message </button>
                                </form>

                                <div className='container mt-4'>
                                    {quoteDetail && quoteDetail.map((feedbackData, key) => {
                                        return(
                                            <>
                                                <p className="about_text_7_help_p"><span>Q.{key+1}</span> {feedbackData.quote_ques}</p>
                                                <p><span>{t('ans')}.</span> {feedbackData.quote_ans}</p>
                                            </>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal" id="exampleModal3" tabIndex="-1">
                <div className="modal-dialog modal-dialog-centered request_modal">
                    <div className="modal-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/page-image/table.png"} className="w-100" alt="graph" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal fade bd-example-modal-lg" id='exampleModal4' tabIndex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h2 className="fst-italic mb-1">Price Details</h2>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className='model-body'>
                            <div className="list-group list-group-flush border-bottom scrollarea">
                                {/* {cargo.map((cargodata, key) => {
                                    console.log("---------------->>>", cargodata)
                                    if(data.transport_mode == "Sea"){
                                        return(
                                            <span className="list-group-item list-group-item-action py-3 lh-sm" aria-current="true">
                                                <div className="d-flex w-100 align-items-center justify-content-between">
                                                    <strong className="mb-1">{cargodata.container} X {cargodata.qty}</strong>
                                                    <small>{cargodata.qty * 100} USD</small>
                                                </div>
                                                <div className="col-10 mb-1 small">{cargodata.desc}</div>
                                            </span>
                                        );
                                    }
                                    else{
                                        return(
                                            <span className="list-group-item list-group-item-action py-3 lh-sm" aria-current="true">
                                                <div className="d-flex w-100 align-items-center justify-content-between">
                                                    <strong className="mb-1">{cargodata.type}</strong>
                                                    {cargodata.type == "pallet" ? <small>2500 USD</small> : cargodata.type == "cargo" ? <small>678 USD</small> : <small>1900 USD</small>}
                                                </div>
                                                <div className="col-10 mb-1 small">{cargodata.desc}</div>
                                            </span>
                                        );
                                    }
                                })} */}

                                <table className="table">
                                    <thead className='table-warning'>
                                        <tr>
                                        <th scope="col">Charge Type</th>
                                        <th scope="col">Charge Level</th>
                                        <th scope="col">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className='table-secondary'>
                                            <th colSpan="3">Freight charge</th>
                                        </tr>
                                        <tr>
                                            <td>Sea Freight (FRT)</td>
                                            <td>Per Equipment</td>
                                            <td><b>9,200.00 USD</b></td>
                                        </tr>
                                        <tr className='table-secondary'>
                                            <th colSpan="3">Freight surcharge</th>
                                        </tr>
                                        <tr>
                                            <td>Carrier security fee (CFE)</td>
                                            <td>Per Equipment</td>
                                            <td><b>11.00 USD</b></td>
                                        </tr>
                                        <tr>
                                            <td>Carrier security fee (CFE)</td>
                                            <td>Per Equipment</td>
                                            <td><b>11.00 USD</b></td>
                                        </tr><tr>
                                            <td>Carrier security fee (CFE)</td>
                                            <td>Per Equipment</td>
                                            <td><b>11.00 USD</b></td>
                                        </tr><tr>
                                            <td>Carrier security fee (CFE)</td>
                                            <td>Per Equipment</td>
                                            <td><b>11.00 USD</b></td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div className='container mt-3'>
                                    <div>
                                        <h3 className="pb-2 mb-2 blog-post-title border-bottom">Notes:</h3>
                                    </div>
                                    <dl>
                                        <dt>Quote Validity</dt>
                                        <dd>Lorem In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate.</dd>
                                        
                                        <dt>Payment Terms</dt>
                                        <dd>Lorem In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate.</dd>
                                        
                                        <dt>Quote Validity</dt>
                                        <dd>
                                            <ul>
                                                <li><strong>To bold text</strong>, use <code className="language-plaintext highlighter-rouge">&lt;strong&gt;</code>.</li>
                                                <li><em>To italicize text</em>, use <code className="language-plaintext highlighter-rouge">&lt;em&gt;</code>.</li>
                                                <li>Abbreviations, like <abbr title="HyperText Markup Language">HTML</abbr> should use <code className="language-plaintext highlighter-rouge">&lt;abbr&gt;</code>, with an optional <code className="language-plaintext highlighter-rouge">title</code> attribute for the full phrase.</li>
                                                <li>Citations, like <cite>— Mark Otto</cite>, should use <code className="language-plaintext highlighter-rouge">&lt;cite&gt;</code>.</li>
                                                <li><del>Deleted</del> text should use <code className="language-plaintext highlighter-rouge">&lt;del&gt;</code> and <ins>inserted</ins> text should use <code className="language-plaintext highlighter-rouge">&lt;ins&gt;</code>.</li>
                                                <li>Superscript <sup>text</sup> uses <code className="language-plaintext highlighter-rouge">&lt;sup&gt;</code> and subscript <sub>text</sub> uses <code className="language-plaintext highlighter-rouge">&lt;sub&gt;</code>.</li>
                                            </ul>
                                        </dd>

                                        <dt>Transit time</dt>
                                        <dd>Lorem In publishing and graphic design, Lorem ipsum is a placeholder text commonly used to demonstrate.</dd>
                                    </dl>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
