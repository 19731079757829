import React, {useState} from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import useStorage from "../../../hooks/useStorage.ts";
import Slider from '../Slider/Slider';
import { useTranslation } from "react-i18next";

const SelectAccount = () => {
    const { setItem,getItem } = useStorage();
    const navigate = useNavigate();

    const { t, i18n } = useTranslation();
    var [language, setLanguage] = useState(localStorage.getItem("language") === "fr" ? "fr" : "en");

    const next = () => {
        setItem("user_role", "Agent")
        navigate("/network")
        // window.location.reload()
    }

    const changeLanguageHandler = (e) => {
        const languageValue = e.target.value;
        i18n.changeLanguage(languageValue);
        localStorage.setItem("language", languageValue);
        setLanguage(languageValue);
    }


    return (
        <React.Fragment>
            <Helmet>
                <title>{t('select_account')}</title>
            </Helmet>

            <main className="main_cargo h-100">
                <div className="container-fluid h-100">
                    <div className="row h-100">
                        <Slider />
                        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 h-100">
                            <div className="row justify-content-center align-items-center h-100">
                                <div className="col-12 col-xl-6 col-lg-6 col-md-8 col-sm-9 text-center h-100">
                                    <div className="signin_between h-100">
                                        <div className='main_language'>
                                            <select className="form-select w-auto ms-auto mt-2" value={language} onChange={changeLanguageHandler} aria-label="Default select example">
                                                <option value="en">English</option>
                                                <option value="fr">Français</option>
                                            </select>
                                        </div>
                                        <div className="signin_logo">
                                            <img src="assets/images/icon/logo.svg" alt="logo" />
                                        </div>
                                        <form>
                                            <div className="signin_content">
                                                <div>
                                                    <h1 className="other_h1">{t('select_account')}</h1>
                                                    <p>{t('free_sign_up')}</p>
                                                </div>
                                                <div className="alert alert-secondary text-primary" role="alert">
                                                    {t('select_accout_text')}
                                                </div>
                                                <div className="input_box input_box_margin">
                                                    {/* <Link to="/select-network" className="btn btn_signin w-100" onClick={() => setItem("user_role", "Agent")}>Forwarder</Link> */}
                                                    {/* <span type="button" className="btn btn_signin w-100" onClick={() => next()}>Forwarder</span>
                                                    <Link to="/signup" className="btn btn_signin w-100" onClick={() => setItem("user_role", "Client")}>Importer/Exporter</Link> */}
                                                    <span type="button" className="custom-btn btn-1 mt-3" onClick={() => next()}>
                                                        <p></p>
                                                        <b>{t('forwarder_text')}</b>
                                                        <p></p>
                                                        {/* <p style={{fontSize: "15px", color: "#000000"}}>{t('forwarder')}</p> */}
                                                    </span>
                                                    <Link to="/complete-profile" className="custom-btn btn-1 mt-3" onClick={() => setItem("user_role", "Client")}>
                                                        <p></p>
                                                        <b>{t('importer/exporter_text')}</b>
                                                        <p></p>
                                                    </Link>
                                                    <p className='mt-4 fs-5'><b>{t('no_credit')}</b></p>
                                                </div>
                                            </div>
                                        </form>  
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Helmet>
                <script>
                    {
                        `
                        jQuery("#carousel").owlCarousel({
                            autoplay: true,
                            loop: true,
                            touchDrag: true,
                            mouseDrag: true,
                            items: 1,
                            responsiveClass: true,
                            autoplayTimeout: 7000,
                            smartSpeed: 800,
                            nav: false,
                            dots: true,
                            responsive: {
                                0: {
                                    items: 1
                                },
                        
                                468: {
                                    items: 1
                                },
                        
                                767: {
                                    items: 1
                                },
                        
                                991: {
                                    items: 1
                                },
                        
                                1200: {
                                    items: 1
                                },
                        
                                1400: {
                                    items: 1
                                }
                            }
                        });
                        `
                    }
                </script>
            </Helmet>
        </React.Fragment>
    )
}

export default SelectAccount