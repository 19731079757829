import React, { useState } from 'react'
import {Helmet} from "react-helmet";
import { Link, useNavigate  } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { completeProfile, sendOTP } from '../../../api/apiHandler';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import ToastMessage from '../../Toast';
import Slider from '../Slider/Slider';
import useStorage from "../../../hooks/useStorage.ts";
import { useTranslation } from "react-i18next";

const CompleteProfile = () => {
    
    const navigate = useNavigate();
    const [address, setAddress] = useState("");
    const [coordinates, setCoordinates] = useState({lat: null, lng: null});
    const [companyData, setData] = useState();
    const [postalCode, setPostalCode] = useState(null);
    const [city, setCity] = useState(null);
    const [countries, setCountries] = useState(null);
    const [state, setState] = useState(null);
    const [apiAddressData, setApiAddressData] = useState();
    const { t, i18n } = useTranslation();
    var [language, setLanguage] = useState(localStorage.getItem("language") === "fr" ? "fr" : "en");

    const a = localStorage.getItem("companyData") != undefined && localStorage.getItem("companyData") != "" ? JSON.parse(localStorage.getItem("companyData")) : "";

    const { setItem,getItem } = useStorage();

    const handleSelect = async value => {
        const results = await geocodeByAddress(value)
        const ll = await getLatLng(results[0]);

        setAddress(value)
        setCoordinates(ll)

        const addressDetails = results[0];
        addressDetails.address_components.forEach((component) => {
            if (component.types.includes('postal_code')) {
                setPostalCode(component.long_name)
            }
            else if (component.types.includes('locality')) {
                setCity(component.long_name)
            }
            else if (component.types.includes('administrative_area_level_1')) {
                setState(component.long_name)
            }
            else if (component.types.includes('country')) {
                setCountries(component.long_name)
            }
        });
    }
    // var CSdata = address.split(",");
    // var state = CSdata[CSdata.length - 2];
    // var country = CSdata[CSdata.length - 1] !== "" ? CSdata[CSdata.length - 1] : " France";

    const getData = (data) => {
        const bearerToken = "7413fa38-88c8-32bb-80f0-dc62082f28f9"
        // https://api.insee.fr/entreprises/sirene/V3/siret?q=siret%3A83515425300032&date=2023-02-16&masquerValeursNulles=true

        fetch(`https://api.insee.fr/entreprises/sirene/V3/siret?q=siret%3A${data}&date=${new Date().toJSON().slice(0, 10)}&masquerValeursNulles=true`, {method: "GET", headers: {Authorization: `Bearer ${bearerToken}`}}).then(response => {
            return response.json()
        }).then(data => {
            setData(data.etablissements[0])
            setApiAddressData(data.etablissements[0].adresseEtablissement)
        })
    }

    const validationSchema = Yup.object().shape({
        // email: Yup.string(),
        siret_no: localStorage.getItem("user_role") == "Agent" ? Yup.string() :
            Yup.string()
            .required('Siret no is required')
            .min(14, 'Siret Number must be at least 14 characters')
            .matches('[0-9]', 'Only numbers are allowed for this field'),
        company_name: localStorage.getItem("user_role") != "Agent" ? Yup.string() :
            Yup.string()
            .required('Company name is required')
            .min(2, 'Company name must be at least 2 characters')
            .matches('^[a-zA-Z]', 'Only alphabets are allowed for this field'),
        company_desc : Yup.string(),
        // role : Yup.string()
        //     .required('Role is required'),
        latitude: Yup.string(),
        longitude: Yup.string()
    });

    const formOptions = { resolver: yupResolver(validationSchema) };

    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;    

    const onSubmit = (data) => {
        // data.address = address !== "" ? JSON.stringify(address) : apiAddressData.numeroVoieEtablissement + " " + apiAddressData.typeVoieEtablissement + " " + apiAddressData.libelleVoieEtablissement + ", " + apiAddressData.codePostalEtablissement + " " + apiAddressData.libelleCommuneEtablissement.charAt(0).toUpperCase() + apiAddressData.libelleCommuneEtablissement.slice(1).toLowerCase();
        data.address = address
        data.state = state;
        data.country = countries;
        data.city = city
        data.postal_code = postalCode
        data.latitude = coordinates.lat;
        data.longitude = coordinates.lng;
        // data.state = state != undefined ? state : apiAddressData.libelleCommuneEtablissement.charAt(0).toUpperCase() + apiAddressData.libelleCommuneEtablissement.slice(1).toLowerCase();
        // data.country = country;
        data.company_name = data.company_name != undefined && data.company_name != "" ? data.company_name : companyData.uniteLegale.denominationUniteLegale;
        if(getItem("user_role") === "Agent"){
            data.company_type = "Agent"
            data.networks = getItem("networks")
        }
        else{
            data.company_type = "Client"
            localStorage.removeItem("networks")
        }

        completeProfile(data).then((response) => {
            response = JSON.parse(response)
            if(response.code == 1)
            {
                // sendOTP(data).then((response) => {
                //     response = JSON.parse(response);

                //     if(response.code == 1){
                        localStorage.setItem("companyData", JSON.stringify(response.data))
                        localStorage.setItem("company", response.data._id)
                        ToastMessage({type:"success", message: response.message});
                //         setTimeout(() => {
                //             navigate("/verifyotp");
                //         }, 1500);
                //     }
                //     else{
                //         ToastMessage({type:"error", message: response.message});
                //     }
                // });
                // setTimeout(() => {
                    navigate("/signup");
                // }, 2000);
            }
            else
            {
                ToastMessage({type:"error", message: response.message})
            }
        });
    }

    const changeLanguageHandler = (e) => {
        const languageValue = e.target.value;
        i18n.changeLanguage(languageValue);
        localStorage.setItem("language", languageValue);
        setLanguage(languageValue);
    }

  return (
    <React.Fragment>
        <Helmet>
            <style type="text/css">
                {
                    `
                    .product-slider .owl-stage-outer, .product-slider .owl-item , .product-slider  .owl-stage , .product-slider .owl-item {
                        height:100%;
                    }
                    `
                }
            </style>
            <title>{t('complete_profile')}</title>
        </Helmet>


        <main className="main_cargo h-100">
            <div className="container-fluid h-100">
                <div className="row h-100">
                    <ToastContainer />
                    <Slider />
                    <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 h-100"  style={{ minHeight: "750px", overflowY: "auto" }}>
                        <div className="row justify-content-center align-items-center h-100">
                            <div className="col-12 col-xl-6 col-lg-6 col-md-8 col-sm-9 text-center h-100">
                                <div className="signin_between h-100">
                                    <div className='main_language'>
                                        <select className="form-select w-auto ms-auto mt-2" value={language} onChange={changeLanguageHandler} aria-label="Default select example">
                                            <option value="en">English</option>
                                            <option value="fr">Français</option>
                                        </select>
                                    </div>
                                    <div className="signin_logo">
                                        <img src={process.env.PUBLIC_URL +"/assets/images/icon/logo.svg"} alt="logo" />
                                    </div>
                                    <form onSubmit={handleSubmit(onSubmit)}>
                                        <div className="signin_content">
                                            <h1 className="sign_h1">{t('complete_profile')}</h1>
                                            {/* <input type="hidden" value={localStorage.getItem("email")} {...register('email')} /> */}
                                            {/* <div className="input_box input_box_margin mt-3">
                                                <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" {...register('role')} autoComplete="off" />
                                                <label htmlFor="exampleInputEmail1" className="form-label">Job Title</label>
                                                <div className='invalid-feedback'>{errors.role?.message}</div>
                                            </div> */}
                                            {localStorage.getItem("user_role") == "Agent" ?
                                                // <div className="input_box input_box_margin">
                                                //     <input type="text" className="form-control" id="siret-no" aria-describedby="emailHelp" {...register('siret_no')} autoComplete="off" />
                                                //     <label htmlFor="siret-no" className="form-label">Siret Number</label>
                                                //     <div className='invalid-feedback'>{errors.siret_no?.message}</div>
                                                // </div>
                                                <></>
                                            :
                                                <div className="input_box input_box_margin">
                                                    <input type="text" className="form-control" id="siret-no" aria-describedby="emailHelp" defaultValue={a != "" ? a.siret_no : "" } {...register('siret_no')} onChange={(e) =>  getData(e.target.value)} autoComplete="off" />
                                                    <label htmlFor="siret-no" className="form-label">{t('siret')}</label>
                                                    <div className='invalid-feedback'>{errors.siret_no?.message}</div>
                                                </div>
                                            }
                                            <div className="input_box input_box_margin">
                                                {/* <p>lat: {coordinates.lat}</p>
                                                <p>long: {coordinates.lng}</p>
                                                <p>Address: {address}</p> */}
                                                {localStorage.getItem("user_role") == "Agent" ?
                                                    <input type="text" className="form-control" id="exampleInputEmail1" defaultValue={a != " " ? a.company_name :''} {...register('company_name')} aria-describedby="emailHelp" autoComplete="off" />
                                                :
                                                    <input type="text" className="form-control" id="exampleInputEmail1" {...register('company_name')} value={companyData != undefined ? companyData.uniteLegale.denominationUniteLegale : "" } aria-describedby="emailHelp" autoComplete="off" />
                                                }
                                                <label htmlFor="exampleInputEmail1" className="form-label">{t('company_name')}</label>
                                                <div className='invalid-feedback'>{errors.company_name?.message}</div>
                                            </div>
                                            <div className="input_box input_box_margin">
                                                {localStorage.getItem("user_role") == "Agent" ?
                                                <>
                                                    <PlacesAutocomplete value={a != "" ? a.address :address} onChange={setAddress} onSelect={handleSelect}>
                                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                                            <div>
                                                                <div className="input_box">
                                                                    <input className="form-control" id="address" defaultValue={address} {...getInputProps()} />
                                                                    <label htmlFor="exampleInputEmail1" className="form-label">{t('address')}</label>
                                                                </div>
                                                                <div>
                                                                    {loading ? <div className='text-secondary'>{t('loading')}...</div> : null}

                                                                    {suggestions.map((suggestion) => {
                                                                        const style = {
                                                                            backgroundColor: suggestion.active ? "#9DDCDE" : "#fff",
                                                                            textAlign: "left"
                                                                        }

                                                                        return (
                                                                            <div className='form-control' {...getSuggestionItemProps(suggestion, { style })}>
                                                                                {suggestion.description}
                                                                            </div>
                                                                        );
                                                                    })}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </PlacesAutocomplete>
                                                </>
                                                :
                                                    <div className="input_box form-floating">
                                                        <textarea className="form-control" id="floatingTextarea" value={apiAddressData != undefined && companyData != undefined ? apiAddressData.numeroVoieEtablissement + " " + apiAddressData.typeVoieEtablissement + " " + apiAddressData.libelleVoieEtablissement + ", " + apiAddressData.codePostalEtablissement + " " + apiAddressData.libelleCommuneEtablissement.charAt(0).toUpperCase() + apiAddressData.libelleCommuneEtablissement.slice(1).toLowerCase() + ", France" : ""}></textarea>
                                                        <label htmlFor="exampleInputEmail1" className="form-label">{t('address')}</label>
                                                    </div>
                                                }
                                                <div className='invalid-feedback'>{errors.client_address?.message}</div>
                                            </div>
                                            <div className="input_box form-floating">
                                                <textarea className="form-control" id="message_contact" name="message_contact" defaultValue={a != "" ? a.company_desc : "" } {...register('company_desc')}></textarea>
                                                <label htmlFor="exampleInputEmail1" className="form-label">{t('company_desc')} ({t('optional')})</label>
                                                <div className='invalid-feedback'>{errors.company_desc?.message}</div>
                                            </div>
                                            <div className="signin_button">
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <Link to = {localStorage.getItem("user_role") == "Agent" ? "/network" : "/select-account"} className="btn btn_signin w-100">{t('back')}</Link>
                                                    </div>
                                                    <div className='col-sm-6'>
                                                        <button className="btn btn_signin w-100" type='submit'>{t('next')}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>  
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        
        <Helmet>
            <script>
                {
                    `
                    jQuery("#carousel").owlCarousel({
                        autoplay: true,
                        loop: true,
                        touchDrag: true,
                        mouseDrag: true,
                        items: 1,
                        responsiveClass: true,
                        autoplayTimeout: 7000,
                        smartSpeed: 800,
                        nav: false,
                        dots: true,
                        responsive: {
                            0: {
                                items: 1
                            },
                    
                            468: {
                                items: 1
                            },
                    
                            767: {
                                items: 1
                            },
                    
                            991: {
                                items: 1
                            },
                    
                            1200: {
                                items: 1
                            },
                    
                            1400: {
                                items: 1
                            }
                        }
                    });
                    `
                }
            </script>
        </Helmet>
    </React.Fragment>
  )
}

export default CompleteProfile